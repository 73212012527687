import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DefaultLayoutComponent } from './default-layout.component';
import { ProgressBarModule } from "../../components/progress-bar/progress-bar.module";
import { HeaderAlertModule } from "../../components/header-alert/header-alert.module";
import { ToolbarModule } from '../../components/toolbar/toolbar.module';
import { SidenavModule } from "@lib/ui/components/sidenav/sidenav.module";
import { RouterModule } from "@angular/router";
// import { MainContentModule } from "@lib/ui/components/main-content/main-content.module";


@NgModule({
  declarations: [
    DefaultLayoutComponent
  ],
  imports: [
    CommonModule,
    ProgressBarModule,
    HeaderAlertModule,
    SidenavModule,
    ToolbarModule,
    RouterModule
    // MainContentModule
  ],
  exports: [
    DefaultLayoutComponent
  ]
})
export class DefaultLayoutModule { }
