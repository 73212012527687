import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PublicLayoutComponent } from './public-layout.component';
import { RouterModule } from "@angular/router";
import { ProgressBarModule } from "../../components/progress-bar/progress-bar.module";
import { HeaderAlertModule } from "@lib/ui/components/header-alert/header-alert.module";
import { ToolbarModule } from '@lib/ui/components/toolbar/toolbar.module';


@NgModule({
  declarations: [
    PublicLayoutComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    ProgressBarModule,
    HeaderAlertModule,
    ToolbarModule
  ],
  exports: [
    PublicLayoutComponent
  ]
})
export class PublicLayoutModule { }
