<div class="p-4 h-full w-full">
    <!-- region main heading -->
    <h1 class="new-head text-xl px-4 py-2 rounded flex justify-between items-center font-semibold">
        {{ title }}

        <!-- region main heading controls-->
        <div class="flex gap-x-3  items-center text-lg font-normal">
            <button mat-raised-button class="new-button" [disabled]="RemarksForm.invalid" (click)="submitform()">Send</button>
            <button mat-raised-button class="new-button" (click)="backClicked()" (click)="backClicked()">Cancel</button>
        </div>
        <!-- endregion main heading controls-->
    </h1>
    <!-- endregion main heading -->

    <!-- region form -->
    <div class="px-4 py-7 rounded bg-white">
        <form [formGroup]="RemarksForm" class="sm:w-120 text-md">
            <div class="grid gap-6">
                <!-- City Name -->
                <div class="relative">
                    <div class="icon">
                        <mat-icon>location_city</mat-icon>
                    </div>
                    <input type="text" id="Email" class="peer" formControlName="Email" placeholder=" " />
                    <label for="Email" class="mandatory">Email</label>
                </div>
            </div>
        </form>
    </div>
    <!-- endregion form -->
</div>
