import { Component, OnDestroy, OnInit } from '@angular/core';
import { sidenavClose, sidenavOpen } from "@lib/ui/store/uiState/ui.actions";
import { uiStateSelector } from "@lib/ui/store/uiState/uiState.selector";
import { IUserProfile } from '@models/dtos/auth';
import { Store, select } from "@ngrx/store";
import { AuthService } from '@services/auth/auth.service';
import { Subject, takeUntil } from "rxjs";

@Component({
  selector: 'gnx-toolbar',
  templateUrl: './toolbar.component.html',
  styleUrls: ['./toolbar.component.scss']
})
export class ToolbarComponent implements OnInit, OnDestroy {

  private uiState$ = this.store.pipe(select(uiStateSelector));
  private destroy$: Subject<any> = new Subject();
  user: IUserProfile;
  sidenavOpened: boolean;

  /**
   * Constructor
   */
  constructor(private store: Store,
    private _authservice: AuthService) {
      this.destroy$ = new Subject();
      this._authservice.userProfile$
      .pipe(takeUntil(this.destroy$))
      .subscribe((user: IUserProfile) => {
        if(user){
          this.user = user;
        }
      });
     }

  // -----------------------------------------------------------------------------------------------------
  // @ Lifecycle hooks
  // -----------------------------------------------------------------------------------------------------

  /**
   * On init
   */
  ngOnInit(): void {
    //Get the initial sidenav state
    this.uiState$.pipe(takeUntil(this.destroy$))
      .subscribe((uiState) => {
        this.sidenavOpened = uiState.sidenavState.opened;
      });
  }

  /**
   * On destroy
   */
  ngOnDestroy(): void {
    //Remove all subscriptions by setting destroy$ value to null
    this.destroy$.next(null);
    this.destroy$.complete();
  }


  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------
  toggleSidenav(): void {
    if (this.sidenavOpened) {
      this.store.dispatch(sidenavClose());
    } else {
      this.store.dispatch(sidenavOpen());
    }
  }

}
