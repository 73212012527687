import { Component, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'gnx-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class LayoutComponent {

  //Setting to hard-coded default layout
  //this will be later made configurable
  layout = 'default-layout';
}
