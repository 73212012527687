import { ChangeDetectionStrategy, ChangeDetectorRef, Component, HostBinding, Input, OnChanges, OnDestroy, OnInit, SimpleChanges, ViewEncapsulation } from '@angular/core';
import { IAppUIState } from "@lib/ui/store/models/appUiState.interface";
import { uiStateSelector } from "@lib/ui/store/uiState/uiState.selector";
import { GnxMenuItem } from "@models/navigation/gnxMenutem.interface";
import { select, Store } from "@ngrx/store";
import { BehaviorSubject, Observable, Subject, takeUntil, map } from "rxjs";
import { SidenavMode, SidenavPosition, SidenavSize } from "./sidenav.types";
import { AuthService } from '@services/auth/auth.service';

@Component({
  selector: 'gnx-sidenav',
  templateUrl: './sidenav.component.html',
  styleUrls: ['./sidenav.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,

  exportAs: 'gnxSidenav'
})
export class SidenavComponent implements OnInit, OnDestroy, OnChanges {

  menuItems$: Observable<GnxMenuItem[]>;
  autoCollapse: boolean;

  private menuItems: BehaviorSubject<GnxMenuItem[]>;
  private mode: SidenavMode = 'side';
  private position: SidenavPosition = 'left';
  private size: SidenavSize = 'normal';
  private opened: boolean;

  private destroy$: Subject<any>;
  private uiState$: Observable<IAppUIState>;

  @HostBinding('class') get classList(): any {
    return {
      'mode-over': this.mode === 'over',
      'mode-side': this.mode === 'side',
      'position-left': this.position === 'left',
      'position-right': this.position === 'right',
      'opened': this.opened,
      'closed': !this.opened,
      'compact': this.size === 'compact',
      'gnx-animations-enabled': true
    };
  }

  constructor(
    private _store: Store,
    private _changeDetectorRef: ChangeDetectorRef,
    private _authService: AuthService
  ) {
    this.menuItems = new BehaviorSubject<GnxMenuItem[]>([]);
    this.menuItems$ = this.menuItems.asObservable();
    this.autoCollapse = true;
    this.destroy$ = new Subject<any>();
    this.uiState$ = this._store.pipe(select(uiStateSelector));
  }

  ngOnInit(): void {
    this.uiState$
      .pipe(takeUntil(this.destroy$))
      .subscribe((state) => {
        this.mode = state.sidenavState.mode;
        this.opened = state.sidenavState.opened;
        this.position = state.sidenavState.position;
        this.size = state.sidenavState.size;
        let user = this._authService._userProfile.value;
        let leftmenu:any;
        leftmenu = state.sidenavState.menu.filter((m) => m.location === 'sidenav');
        if(user.Type != 'TeamLeader') {
          leftmenu.push(
            {
              id: 'insurance',
              title: 'Buy Insurance',
              type: 'collapsible',
              location: 'sidenav',
              icon: 'mat_outline:add_shopping_cart',
              children: [
                {title: "Import Policy",type:'basic',icon:'mat_outline:download', location:'sidenav', id:'importpolicy', path:'/app/insurance/importpolicy'},
                {title:"Export Policy", type:'basic',icon:'mat_outline:upload', id: "exportpolicy" , location: 'sidenav' , path:'/app/insurance/exportpolicy'},
                // {title:"3rd Country Policy",type:'basic',icon:'mat_outline:flag', location:'sidenav', id:"countrypolicy" , path:'/app/insurance/countrypolicy'},
                {title:"Inland Policy",type:'basic',icon:'mat_outline:donut_small', location:'sidenav', id:"inlandpolicy", path:'/app/insurance/inlandpolicy'},
                {title:"Container Policy",type:'basic',icon:'mat_outline:nfc', location:'sidenav', id:'containerpolicy', path:'/app/insurance/containerpolicy'}
              ],
            },
            
            {
              id:'claimprocess',
              title: 'Claim Process',
              type:'basic',
              location:'sidenav',
              icon:'mat_outline:library_books',
              path:'/app/claimprocess'
            },
          )
        }
        if(user.AgentId) {
        leftmenu.push({
          id: 'reports',
          title: 'Reports',
          type: 'collapsible',
          location: 'sidenav',
          icon: 'heroicons_outline:document-report',
          children:[
            {title: "Agent Ledger", type: 'basic', icon:'heroicons_outline:document', location:'sidenav', id:'reportagentledger',path:'/app/reports/agentledger'},
            {title: "Agent Payout", type: 'basic', icon:'heroicons_outline:credit-card', location:'sidenav', id:'reportagentpayout',path:'/app/reports/agentpayout'},
            {title: "Accounts GST", type: 'basic', icon:'heroicons_outline:clipboard-list', location:'sidenav', id:'reportcertificateregister',path:'/app/reports/certificateregister'},
            {title: "Claim Register", type: 'basic', icon:'mat_outline:library_books', location:'sidenav', id:'reportclaimregister',path:'/app/reports/claimregister'},
            {title: "Agent", type: 'basic', icon:'mat_outline:person', location:'sidenav', id:'reportagent',path:'/app/reports/agent'},
          ]
        });
      }

      else if(user.Type == 'SalesPerson'){
        leftmenu.push({
          id: 'reports',
          title: 'Reports',
          type: 'collapsible',
          location: 'sidenav',
          icon: 'heroicons_outline:document-report',
          children:[
            // {title: "Agent Ledger", type: 'basic', icon:'heroicons_outline:document', location:'sidenav', id:'reportagentledger',path:'/app/reports/agentledger'},
            {title: "Agent Payout", type: 'basic', icon:'heroicons_outline:credit-card', location:'sidenav', id:'reportagentpayout',path:'/app/reports/agentpayout'},
            {title: "TL Payout", type: 'basic', icon:'mat_outline:credit_card', location:'sidenav', id:'reporttlpayout',path:'/app/reports/tlpayout'},
            {title: "Accounts GST", type: 'basic', icon:'heroicons_outline:clipboard-list', location:'sidenav', id:'reportcertificateregister',path:'/app/reports/certificateregister'},
            {title: "Sales Executive", type: 'basic', icon:'mat_outline:person', location:'sidenav', id:'reportsalesperson',path:'/app/reports/salesperson'}
            // {title: "Claim Register", type: 'basic', icon:'mat_outline:library_books', location:'sidenav', id:'reportclaimregister',path:'/app/reports/claimregister'}
          ]
        });
      }

      else if(user.Type == 'TeamLeader'){
        leftmenu.push({
          id: 'reports',
          title: 'Reports',
          type: 'collapsible',
          location: 'sidenav',
          icon: 'heroicons_outline:document-report',
          children:[
            // {title: "Agent Ledger", type: 'basic', icon:'heroicons_outline:document', location:'sidenav', id:'reportagentledger',path:'/app/reports/agentledger'},
            {title: "Agent Payout", type: 'basic', icon:'heroicons_outline:credit-card', location:'sidenav', id:'reportagentpayout',path:'/app/reports/agentpayout'},
            {title: "TL Payout", type: 'basic', icon:'mat_outline:credit_card', location:'sidenav', id:'reporttlpayout',path:'/app/reports/tlpayout'},
            {title: "Accounts GST", type: 'basic', icon:'heroicons_outline:clipboard-list', location:'sidenav', id:'reportcertificateregister',path:'/app/reports/certificateregister'},
            {title: "Team Leader", type: 'basic', icon:'mat_outline:person', location:'sidenav', id:'reporttl',path:'/app/reports/teamleader'},
            // {title: "Claim Register", type: 'basic', icon:'mat_outline:library_books', location:'sidenav', id:'reportclaimregister',path:'/app/reports/claimregister'}
          ]
        });
      }
      
      else if(user.Type == 'FinmoreUser') {
        let obj = {
          id: 'reports',
          title: 'Reports',
          type: 'collapsible',
          location: 'sidenav',
          icon: 'heroicons_outline:document-report',
          children:[
            {title: "Payment Receipt", type: 'basic', icon:'mat_outline:money', location:'sidenav', id:'reportccavenue',path:'/app/reports/paymentreceipt'},
            {title: "Agent Ledger", type: 'basic', icon:'heroicons_outline:document', location:'sidenav', id:'reportagentledger',path:'/app/reports/agentledger'},
            {title: "Agent Payout", type: 'basic', icon:'heroicons_outline:credit-card', location:'sidenav', id:'reportagentpayout',path:'/app/reports/agentpayout'},
            {title: "TL Payout", type: 'basic', icon:'mat_outline:credit_card', location:'sidenav', id:'reporttlpayout',path:'/app/reports/tlpayout'},
            {title: "Accounts GST", type: 'basic', icon:'heroicons_outline:clipboard-list', location:'sidenav', id:'reportcertificateregister',path:'/app/reports/certificateregister'},
            {title: "Claim Register", type: 'basic', icon:'mat_outline:library_books', location:'sidenav', id:'reportclaimregister',path:'/app/reports/claimregister'},
            {title: "Branch", type: 'basic', icon:'mat_outline:notes', location:'sidenav', id:'reportbranch',path:'/app/reports/branch'},
            {title: "Sales Executive", type: 'basic', icon:'mat_outline:person', location:'sidenav', id:'reportsalesperson',path:'/app/reports/salesperson'},
            {title: "Bajaj", type: 'basic', icon:'mat_outline:business', location:'sidenav', id:'reportbajaj',path:'/app/reports/bajaj'},
            {title: "Agent", type: 'basic', icon:'mat_outline:person', location:'sidenav', id:'reportagent',path:'/app/reports/agent'},
            {title: "Team Leader", type: 'basic', icon:'mat_outline:person', location:'sidenav', id:'reporttl',path:'/app/reports/teamleader'},
            {title: "Support Executive", type: 'basic', icon:'mat_outline:person', location:'sidenav', id:'reportsupportexecutive',path:'/app/reports/supportexecutive'},
            {title: "Management", type: 'basic', icon:'mat_outline:person_pin', location:'sidenav', id:'reportmanagement',path:'/app/reports/management'},
          ]
        }
        if(user.IsAdmin) {
            obj.children.push({title: "Complete MIS", type: 'basic', icon:'heroicons_outline:clipboard-list', location:'sidenav', id:'reportcompletemis',path:'/app/reports/completemis'},)
        }
        leftmenu.push(obj);
      }

      else {
        leftmenu.push({
          id: 'reports',
          title: 'Reports',
          type: 'collapsible',
          location: 'sidenav',
          icon: 'heroicons_outline:document-report',
          children:[
            {title: "Accounts GST", type: 'basic', icon:'heroicons_outline:clipboard-list', location:'sidenav', id:'reportcertificateregister',path:'/app/reports/certificateregister'},
            {title: "Support Executive", type: 'basic', icon:'mat_outline:person', location:'sidenav', id:'reportsupportexecutive',path:'/app/reports/supportexecutive'},
            // {title: "Claim Register", type: 'basic', icon:'mat_outline:library_books', location:'sidenav', id:'reportclaimregister',path:'/app/reports/claimregister'}
          ]
        });
      }
      this.menuItems.next(leftmenu);
      });
  }

  ngOnChanges(changes: SimpleChanges): void {
    // Navigation
    if ('navigation' in changes) {
      // Mark for check
      this._changeDetectorRef.markForCheck();
    }
    this._changeDetectorRef.markForCheck();
  }

  ngOnDestroy(): void {
    this.destroy$.next(null);
    this.destroy$.complete();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  trackByFn(index: number, item: any): any {
    return item.id || index;
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Private methods
  // -----------------------------------------------------------------------------------------------------

}
