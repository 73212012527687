import { Component, Inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AlertsService } from '@lib/services/error-handling/alerts.service';
import { HelperService } from '@lib/services/helper.service';
import { CityDto } from '@models/dtos/core';
import { HttpService } from '@lib/services/http/http.service';
import { StatusOptions } from '@config/status.config';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DialogService } from '@lib/services/dialog.service';

@Component({
  selector: 'gnx-notificationremarks',
  templateUrl: './notificationremarks.component.html',
  styleUrls: ['./notificationremarks.component.scss']
})
export class NotificationRemarksComponent {
  // #region public variables

  // Strings
  title: string = '';
  Code: string;
  statusOption = StatusOptions

  // FormGroup
  NotificationRemarksForm: FormGroup;
  cityFrom: CityDto
  addCityForm: any


  editable: boolean;
  // Errors
  errors: unknown;

  stateList;

  // #endregion public variables

  /**
   * #region constructor
   * @param _location : used for back or prev page navigation
   * @param _fb : Formbuilder
   * @param _router: module for routing
   * @param _route: used to get current route
   */
  constructor(
    private _fb: FormBuilder,
    private _dataService:HttpService,
    private _router: Router,
    private _route: ActivatedRoute,
    private _alertservice: AlertsService,
    public _helperservice: HelperService,
    public dialogRef: MatDialogRef<NotificationRemarksComponent>,
    private _dialogService: DialogService,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      type: string;
      title: string;
      isMultiple: boolean;
      data?: any;
    }
  ) {
  }


  ngOnInit(): void {
    let data = this._route.snapshot.data;
    this.title = data['title'];
    this.editable = true;
    this._init();

  }
  // #endregion constructor

  // #region getters

  get f() {
    return this.NotificationRemarksForm.controls
  }

  // #endregion getters

  /**
   * #region public methods
   */

  // submit or save action
  submitform = () => {
    if((this.NotificationRemarksForm.get('PolicyProgress').value == 'Settled' || this.NotificationRemarksForm.get('PolicyProgress').value == 'Rejected') && (!this.NotificationRemarksForm.get('SettlementDate').value || this.NotificationRemarksForm.get('SettlementDate').value == '')) {
      this._alertservice.raiseErrorAlert('Settlement Date is required');
      return;
    }
    this._dialogService.confirmDialog({
      title: 'Are You Sure?',
      message: "You won't be able to revert this",
      confirmText: 'Yes',
      cancelText: "No"
    }).subscribe(res => {
      if (res) {
      this.dialogRef.close(this.NotificationRemarksForm.value);
    }
  });
  };

  // previous page navigation button
  public backClicked() {
    this.dialogRef.close();
  }

  // #endregion public methods
  /**
   * #region private methods
   */

  private _init(): FormGroup {
    this.NotificationRemarksForm = this._fb.group({
      Remarks: ['', [Validators.required]],
      PolicyProgress: ['', Validators.required],
      FinalBillAmount: [null],
      SettlementAmount: [null],
      SettlementDate: [null]
    });

    return this.NotificationRemarksForm;
  }

}
