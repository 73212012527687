import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse
} from '@angular/common/http';
import { Observable, catchError, throwError, retry, tap } from 'rxjs';
import { Alert } from '@models/common/alert.interface';
import { API_ENDPOINTS } from '../config/api-endpoints.config';
import { ApiError } from '@models/common/error.model';
import { AuthService } from '@services/auth/auth.service';
import { Store } from "@ngrx/store";
import { HelperService } from "@lib/services/helper.service";
import { AlertLevel, AlertType } from "@models/common/alert.types";
import { alertRaise } from "@lib/ui/store/uiState/ui.actions";

@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {
  private error: any;
  private _errMsg: string;
  private errCode: number;
  private _alert?: Alert;
  //  = {
  //   message: '',
  //   type: 'error',
  //   canDismiss: true,
  //   autoClose: false,
  //   level: 'page'
  // }

  constructor(
    private _authService: AuthService,
    private _store: Store,
    private _helper: HelperService
  ) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      retry({ count: 3, delay: 1000 }), //Retries the request two more times in case of error
      catchError((err: HttpErrorResponse) => {
        if (err.error instanceof ErrorEvent) {
          //This is a client side error or network error
          this._alert = this.createErrorAlert(err.error.message);
        } else {
          //This is a server side error
          switch (err.status) {
            case 0:
              this._errMsg = `Something went wrong. Unable to connect with Marine Insurance Server. Please try after sometime`;
              break;
            case 401:
              //Get refresh token -- TBD
                this._errMsg = "Session Expired";
                this._authService.signOut()
              break;
            case 404:
              this._errMsg = `Invalid API Call. Please contact system administrator to resolve this issue`;
              break;
            case 403:
              this._errMsg = "Access denied as you do not have required permission for this operation. Please contact your system administrator to resolve access issue";
              break;
            case 500:
              this._errMsg = "Internal server error";
              break;
            case 503:
              this._errMsg = "Service not available at the moment. Please try again later";
              break;
            default:
              this._errMsg = "Unknown Server Error";
              break;
          }
        }
        this._alert = this.createErrorAlert(this._errMsg);
        this._store.dispatch(alertRaise({ alerts: this._alert }));
        return throwError(() => 'Error'); // Probably not required as error service will handle all the redirects
      })
    );
  }

  private createErrorAlert(message: string, level?: AlertLevel, type?: AlertType): Alert {
    if (!level) {
      level = 'page';
    }

    if (!type) {
      type = 'error';
    }

    let id = this._helper.randomId();
    let alert: Alert = {
      Id: id,
      Message: message,
      Type: type,
      Level: level,
      AutoClose: false,
      CanDismiss: true
    }
    return alert;
  }
}
