import { Directive, Input, ElementRef, HostListener, OnChanges, SimpleChanges } from '@angular/core';

@Directive({
  selector: '[Onlynumber]'
})
export class OnlynumberDirective implements OnChanges {

  @Input() AllowDecimal: boolean = false;

  private regex: RegExp = new RegExp(/^[0-9]*$/g);;

  // Allow key codes for special events. Reflect :
  // Backspace, tab, end, home
  private specialKeys: Array<string> = [ 'Backspace', 'Tab', 'End', 'Home' ];

  constructor(private el: ElementRef) {
  }
  ngOnChanges(changes: SimpleChanges): void {
    if(this.AllowDecimal){
      // Allow decimal numbers. The \. is only allowed once to occur
      this.regex = new RegExp(/^[0-9]+(\.[0-9]*){0,1}$/g);
    } else {
      this.regex = new RegExp(/^[0-9]*$/g);
    }
  }

  @HostListener('keydown', [ '$event' ])
  onKeyDown(event: KeyboardEvent) {
      // Allow Backspace, tab, end, and home keys
      if (this.specialKeys.indexOf(event.key) !== -1) {
          return;
      }
      
      let current: string = this.el.nativeElement.value;
      // We need this because the current value on the DOM element
      // is not yet updated with the value from this event
      let next: string = current.concat(event.key);
      if (next && !String(next).match(this.regex)) {
          event.preventDefault();
      }
  }
}
