<div class="overflow-x-scroll flex flex-col h-full">
  <div class="flex-grow overflow-auto">
  <table aria-hidden="true" class="shadow-md relative w-full border">
    <!-- region table header -->
    <thead class="font-sans sticky top-0">
      <!-- region column title  -->
      <tr>

        <th *ngFor="let head of headArray" class="pt-2 pb-1 tracking-wide subpixel-antialiased font-normal ">
          <span *ngIf="head.fieldName" class="flex justify-between items-center hover:cursor-pointer"
            (click)="sortColumns(head.searchFieldName,head.sortFieldName,head.isAdditional, head)" [ngClass]="{'pointer-events-none': !head.isSearch || head.isSortDisable}">
            <span class="mr-2 truncate ">{{ head.head }}</span>
            <i *ngIf="head.isSearch && head.isSort" class="fa-solid fa-sort text-md"></i>
          </span>

          <!-- for action header only -->
          <span *ngIf="!head.fieldName" class="hover:cursor-pointer">
            <span class="mr-2">{{ head.head }}</span>
          </span>
        </th>

      </tr>
      <!-- endregion column title  -->

      <!-- region column search  -->
      <tr>
        <th *ngFor="let head of headArray" class="pt-0 pb-2">

          <input *ngIf="head.isSearch && head.searchType == 'text'" id="{{head.searchFieldName}}" type="text" #input
            class="padding h-7 min-w-2/3 bg-accent-50  shadow text-on-accent-50 font-medium text-sm placeholder:font-medium rounded-md"
            (input)="columnSearch(input.id, input.value,head.isAdditional)"
            [value]="pagefilters.columnSearchOptions.field==head.searchFieldName? pagefilters.columnSearchOptions.searchString: ''">

          <select *ngIf="head.isSearch && head.isSearch == 'true' && head.searchType == 'dropdown'"
            id="{{head.searchFieldName}}" (change)="onSearchChange($event,head.searchFieldName)"
            class="h-7 py-0 appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 px-2 pr-4 rounded leading-none focus:outline-none focus:bg-white focus:border-gray-500">
            <option *ngFor="let options of head.optionArray" [value]="options.Value">
              {{options.Text}}
            </option>
          </select>

        </th>
      </tr>
      <!-- endregion column search  -->
    </thead>
    <!-- endregion table header -->

    <!-- region table body -->
    <tbody *ngIf="pagedData && pagedData['Data']">
      <tr *ngIf="masterName == 'agentLedger'">
        <td class="h-min py-1" *ngFor="let head of headArray">
          <ng-container [ngSwitch]="head.head">
            <div *ngSwitchCase="'Particulars'">
              Opening Balance
            </div>
            <div *ngSwitchCase="'Addition'" style="float: right">
              {{pagedData['Data']['Items'][0]['OpeningBalance'] | indCurrency}}
            </div>
          </ng-container>
        </td>
      </tr>
      <tr *ngFor="let item of pagedData['Data']['Items'],let i=index">
        <td class="h-min py-1" *ngFor="let head of headArray">

          <ng-container [ngSwitch]="head.head">

            <div *ngSwitchCase="'Particulars'">
              <span *ngIf="item['PaymentTransactionId']" class="text-primary" (click)="downloadinvoice(item['PaymentTransactionId'])">
                {{item[head['fieldName']]}}
              </span>
              <span *ngIf="!item['PaymentTransactionId']">
                {{item[head['fieldName']]}}
              </span>
            </div>

            <!-- table cell for Actions -->
            <div *ngSwitchCase="'Actions'" class="flex gap-x-4 justify-start items-center w-full">
              <ng-container [ngSwitch]="masterName">

                <ng-container *ngSwitchCase="'Role'">
                  <a class="fa fa-cog hover:text-primary-900 hover:cursor-pointer" matTooltip="Role Permission" [routerLink]="['role-permission', item[rowKey]]"></a>
                  <a *ngIf="!isViewDisabled" class="fa fa-eye hover:text-primary-900 hover:cursor-pointer" matTooltip="View" [routerLink]="['view', item[rowKey]]"></a>
                  <a *ngIf="!isEditDisabled && !((item['Name'] == 'Agent Role') || (item['Name'] == 'Sales Person Role') || (item['Name'] == 'Team Leader Role') || (item['Name'] == 'Support Executive Role'))" matTooltip="Edit" class="fa fa-edit hover:text-accent-900 hover:cursor-pointer" [routerLink]="['edit', item[rowKey]]"></a>
                  <a *ngIf="!isDeleteDisabled && !((item['Name'] == 'Agent Role') || (item['Name'] == 'Sales Person Role') || (item['Name'] == 'Team Leader Role') || (item['Name'] == 'Support Executive Role'))" matTooltip="Delete" class="fa fa-trash hover:text-warn-500 hover:cursor-pointer" (click)="deleteButton(item[rowKey])"></a>
                </ng-container>
                <ng-container *ngSwitchCase="'Recharge'">
                  <a *ngIf="!(item['Status']==100)" class="fa fa-refresh hover:text-primary-900 hover:cursor-pointer" matTooltip="Check Transaction Status" (click)="RechargeCheck(item[rowKey])"></a>
                </ng-container>
                <ng-container *ngSwitchCase="'E-KYC Approval'">
                  <i class="fa fa-eye hover:text-primary-900 hover:cursor-pointer" matTooltip="View" [routerLink]="['view', item[rowKey]]"></i>
                </ng-container>
                <ng-container *ngSwitchCase="'Policy'">
                  <a class="fa fa-eye hover:text-primary-900 hover:cursor-pointer" matTooltip="View" [routerLink]="['view', item['TransitTypeName'], item[rowKey]]"></a>
                  <a class="fa fa-edit hover:text-accent-900 hover:cursor-pointer" matTooltip="Edit" *ngIf="item['Status']==90 || (this.user.IsAdmin && item['Status']==100)" [routerLink]="['edit', item['TransitTypeName'], item[rowKey]]"></a>
                  <a class="fa fa-file-invoice hover:text-primary-900 hover:cursor-pointer" matTooltip="Download Invoice" *ngIf="item['Status'] == 100" (click)="DownloadInvoice(item[rowKey])"></a>
                  <a class="fa fa-download hover:text-primary-900 hover:cursor-pointer" matTooltip="Download Policy" (click)="DownloadPolicy(item[rowKey])"></a>
                  <a *ngIf="item['Status']==99" class="fa fa-refresh hover:text-primary-900 hover:cursor-pointer" matTooltip="Check Transaction Status" (click)="openRecharge(item[rowKey])"></a>
                  <a class="fa fa-dollar hover:text-primary-900 hover:cursor-pointer" *ngIf="item['Status']==100 && this.user.IsAdmin" matTooltip="Invoice Update" (click)="opendialog('Invoice Update', 'Invoice Update', item[rowKey])"></a>
                  <a class="fa fa-recycle hover:text-primary-900 hover:cursor-pointer" *ngIf="item['Status']==100" matTooltip="Policy Update" (click)="opendialog('Policy Remarks', 'Policy Remarks', item[rowKey])"></a>
                  <a *ngIf="item['Status']==99" [cdkCopyToClipboard]="item['CustomerPaymentLink']" class="fa fa-copy hover:text-primary-900 hover:cursor-pointer" matTooltip="Copy Payment Link"></a>
                  <a *ngIf="this.user.IsAdmin && item['Status']==100" class="fa fa-info-circle hover:text-blue-500 hover:cursor-pointer" matTooltip="Info" (click)="infoHandler(item[rowKey])"></a>
                </ng-container>

                <ng-container *ngSwitchCase="'PendingPolicy'">
                  <a class="fa fa-eye hover:text-primary-900 hover:cursor-pointer" matTooltip="View" [routerLink]="['view', item['TransitTypeName'], item[rowKey]]"></a>
                </ng-container>

                <ng-container *ngSwitchCase="'Claim'">
                  <a class="fa fa-recycle hover:text-primary-900 hover:cursor-pointer" *ngIf="item['PolicyProgress'] == 'Under Review'" matTooltip="Status Update" (click)="openDialog('Claim Remarks', 'Claim Remarks', item[rowKey])"></a>
                  <a *ngIf="!isInfoDisabled" class="fa fa-info-circle hover:text-blue-500 hover:cursor-pointer" matTooltip="Info" (click)="infoHandler(item[rowKey])"></a>
                </ng-container>

                <ng-container *ngSwitchCase="'Agent'">
                  <a *ngIf="!isViewDisabled" class="fa fa-eye hover:text-primary-900 hover:cursor-pointer" matTooltip="View" [routerLink]="['view', item[rowKey]]"></a>
                  <a *ngIf="!isEditDisabled && (item['ApprovalRejectStatusName'] == 'Approved' || item['ApprovalRejectStatusName'] == 'System Approved')" class="fa fa-edit hover:text-accent-900 hover:cursor-pointer" matTooltip="Edit" [routerLink]="['edit', item[rowKey]]"></a>
                  <a *ngIf="item['Status'] == 1 && (item['ApprovalRejectStatusName'] == 'Approved' || item['ApprovalRejectStatusName'] == 'System Approved')" class="fa fa-ban hover:text-warn-500 hover:cursor-pointer" matTooltip="Deactivate" (click)="deactivate(item[rowKey])"></a>
                  <a *ngIf="item['Status'] == 0 && (item['ApprovalRejectStatusName'] == 'Approved' || item['ApprovalRejectStatusName'] == 'System Approved')" class="fa fa-check hover:text-warn-500 hover:cursor-pointer" matTooltip="Activate" (click)="activate(item[rowKey])"></a>
                </ng-container>

                <ng-container *ngSwitchCase="'User'">
                  <a *ngIf="!isViewDisabled" class="fa fa-eye hover:text-primary-900 hover:cursor-pointer" matTooltip="View"
                    [routerLink]="['view', item[rowKey]]"></a>
                  <a *ngIf="!isEditDisabled" class="fa fa-edit hover:text-accent-900 hover:cursor-pointer" matTooltip="Edit"
                    [routerLink]="['edit', item[rowKey]]"></a>
                  <a *ngIf="!isInfoDisabled" class="fa fa-info-circle hover:text-blue-500 hover:cursor-pointer" matTooltip="Info"
                    (click)="infoHandler(item[rowKey])"></a>
                  <a *ngIf="!isDeleteDisabled && !(item['IsAgent'])" class="fa fa-trash hover:text-warn-500 hover:cursor-pointer" matTooltip="Delete"
                    (click)="deleteButton(item[rowKey])"></a>
                </ng-container>

                <ng-container *ngSwitchCase="'SettlingAgent'">
                  <a *ngIf="!isViewDisabled" class="fa fa-eye hover:text-primary-900 hover:cursor-pointer" matTooltip="View"
                    [routerLink]="['view', item[rowKey]]"></a>
                  <a *ngIf="!isEditDisabled" class="fa fa-edit hover:text-accent-900 hover:cursor-pointer" matTooltip="Edit"
                    [routerLink]="['edit', item[rowKey]]"></a>
                  <a *ngIf="!isDeleteDisabled" class="fa fa-trash hover:text-warn-500 hover:cursor-pointer" matTooltip="Delete"
                    (click)="deleteButton(item['SettlingAgentDetailId'])"></a>
                </ng-container>

                <ng-container *ngSwitchDefault>
                  <a *ngIf="!isViewDisabled" class="fa fa-eye hover:text-primary-900 hover:cursor-pointer" matTooltip="View"
                    [routerLink]="['view', item[rowKey]]"></a>
                  <a *ngIf="!isEditDisabled" class="fa fa-edit hover:text-accent-900 hover:cursor-pointer" matTooltip="Edit"
                    [routerLink]="['edit', item[rowKey]]"></a>
                  <a *ngIf="!isInfoDisabled" class="fa fa-info-circle hover:text-blue-500 hover:cursor-pointer" matTooltip="Info"
                    (click)="infoHandler(item[rowKey])"></a>
                  <a *ngIf="!isDeleteDisabled" class="fa fa-trash hover:text-warn-500 hover:cursor-pointer" matTooltip="Delete"
                    (click)="deleteButton(item[rowKey])"></a>
                </ng-container>

              </ng-container>
            </div>

            <!-- table cell for Image -->
            <div *ngSwitchCase="'Image'">
              <img class="w-max max-h-7" [src]="item[head['fieldName']]" alt="">
            </div>
            <!-- table cell for Radio Button -->
            <div *ngSwitchCase="'Select'">
              <mat-radio-button [value]="i" (click)="valueChange(i)"> </mat-radio-button>
            </div>


            <!-- table cell checkbox -->
            <div *ngSwitchCase="'Select Multiple'">
              <mat-checkbox class="example-margin" (click)="multiSelect($event, i)"></mat-checkbox>
            </div>

            <!-- table cell for Image -->
            <div *ngSwitchCase="'Is Active?'">
              {{ item[head['fieldName']] | active }}
            </div>

            
            <div *ngSwitchCase="'Date'" class="w-30">
              {{ item[head['fieldName']]}}
            </div>

            <div *ngSwitchCase="'Invoice Date'" class="w-30">
            {{ item[head['fieldName']]}}
            </div>

            <div *ngSwitchCase="'Creation Date'" class="w-30">
              {{ item[head['fieldName']] + 'Z' | date:'medium':'IST' }}
            </div>

            <div *ngSwitchCase="'Claim Date'">
              {{ item[head['fieldName']] + 'Z' | date:'medium':'IST' }}
            </div>

            <div *ngSwitchCase="'Issued Date'" class="w-30">
              <div *ngIf="item[head['fieldName']]">
                {{ item[head['fieldName']] + 'Z' | date:'medium':'IST'}}
              </div>
            </div>

            <div *ngSwitchCase="'Transaction Date'">
              {{ item[head['fieldName']] + 'Z' | date:'medium':'IST' }}
            </div>

            <div *ngSwitchCase="'Amt. (INR)'" style="float: right">
              {{item[head['fieldName']] | indCurrency}}
            </div>

            <div *ngSwitchCase="'Gross Premium'" style="float: right">
              {{item[head['fieldName']] | indCurrency}}
            </div>

            <div *ngSwitchCase="'Customer Premium'" style="float: right">
              {{item[head['fieldName']] | indCurrency}}
            </div>

            <div *ngSwitchCase="'Sum Insured'" style="float: right;">
            {{item[head['fieldName']] | indCurrency }}
            </div>

            <div *ngSwitchCase="'Amount'" style="float: right">
              {{item[head['fieldName']] | indCurrency}}
            </div>

            <div *ngSwitchCase="'Addition'" style="float: right">
              {{item[head['fieldName']] | indCurrency}}
            </div>

            <div *ngSwitchCase="'Deduction'" style="float: right">
              {{item[head['fieldName']] | indCurrency}}
            </div>

            <div *ngSwitchCase="'Balance'" style="float: right">
              {{item[head['fieldName']] | indCurrency}}
            </div>

            <div *ngSwitchCase="'Base Rate'" style="float: right">
              {{item[head['fieldName']]}}
            </div>
            
            <div *ngSwitchCase="'Dealer Rate'" style="float: right">
              {{item[head['fieldName']]}}
            </div>

            <div *ngSwitchCase="'Base Rate (W2W)'" style="float: right">
              {{item[head['fieldName']]}}
            </div>

            <div *ngSwitchCase="'Dealer Rate (W2W)'" style="float: right">
              {{item[head['fieldName']]}}
            </div>

            <div *ngSwitchCase="'Min. Premium Amt (Ex. GST)'" style="float: right">
              {{item[head['fieldName']] | indCurrency}}
            </div>

            <div *ngSwitchCase="'HRA Rate'" style="float: right">
              {{item[head['fieldName']]}}
            </div>

            <div *ngSwitchCase="'Min. Sum Assured Amt'" style="float: right">
              {{item[head['fieldName']] | indCurrency}}
            </div>

            <!-- table cell for Image -->
            <div *ngSwitchCase="'Offline'">
              {{ item[head['fieldName']] | yesNo }}
            </div>

            <!-- table cell for Image -->
            <div *ngSwitchCase="'Online'">
              {{ item[head['fieldName']] | yesNo }}
            </div>

            <div *ngSwitchCase="'Is Agent Approver?'">
              {{ item[head['fieldName']] | trueyes }}
            </div>

            <div *ngSwitchCase="'Is Policy Approver?'">
              {{ item[head['fieldName']] | trueyes }}
            </div>

            <div *ngSwitchCase="'Is Admin?'">
              {{ item[head['fieldName']] | trueyes }}
            </div>

            <div *ngSwitchCase="'Is Agent?'">
              {{ item[head['fieldName']] | trueyes }}
            </div>

            <div *ngSwitchCase="'Policy Status'">
              {{ item[head['fieldName']] | policystatus }}
            </div>

            <div *ngSwitchCase="'Payment Link'">
              <div *ngIf="item['Status'] == 99">
                {{ item[head['fieldName']]}}
              </div>
            </div>
            
            <div *ngSwitchCase="'Preferred'">
              {{ item[head['fieldName']] | trueyes }}
            </div>

            <div *ngSwitchCase="'Is HRA?'">
              {{ item[head['fieldName']] | trueyes }}
            </div>

            
            <div *ngSwitchCase="'Non Preferred'">
              {{ item[head['fieldName']] | trueyes }}
            </div>

            
            <div *ngSwitchCase="'Sanctioned'">
              {{ item[head['fieldName']] | trueyes }}
            </div>

            <!-- <div *ngSwitchCase="'Decimal Places'" style="float: right">
              {{item[head['fieldName']]}}
            </div> -->

            <!-- table cell for default text fields -->
            <div *ngSwitchDefault>
              <ng-container [ngSwitch]="masterName">

                <!-- <ng-container *ngSwitchCase="'Country'">
                  <span [routerLink]="['view', item[rowKey]]" class="hover:cursor-pointer">{{ item[head['fieldName']]
                    }}</span>
                </ng-container>

                <ng-container *ngSwitchCase="'Currency'">
                  <span [routerLink]="['view', item.code]" class="hover:cursor-pointer">{{ item[head['fieldName']]
                    }}</span>
                </ng-container> -->

                <ng-container *ngSwitchCase="'Popup'">

                  <span >{{ item[head['fieldName']]
                    }}</span>
                </ng-container>

                <ng-container *ngSwitchCase="'Policy'">
                  <span matTooltip="View" [routerLink]="['view', item['TransitTypeName'], item[rowKey]]" class="hover:cursor-pointer hover:text-primary font-medium">{{ item[head['fieldName']]
                  }}</span>
                </ng-container>

                <ng-container *ngSwitchCase="'PendingPolicy'">
                  <span matTooltip="View" [routerLink]="['view', item['TransitTypeName'], item[rowKey]]" class="hover:cursor-pointer hover:text-primary font-medium">{{ item[head['fieldName']]
                  }}</span>
                </ng-container>
                
                
                <ng-container *ngSwitchCase="'Recharge'">
                  <span class="hover:cursor-pointer hover:text-primary font-medium">{{ item[head['fieldName']]
                    }}</span>
                </ng-container>

                <ng-container *ngSwitchDefault>
                  
                  <span *ngIf='!isViewDisabled' matTooltip="View" [routerLink]="['view', item[rowKey]]" class="hover:cursor-pointer hover:text-primary font-medium">{{ item[head['fieldName']]
                    }}</span>
                    <span *ngIf="isViewDisabled">{{ item[head['fieldName']]
                    }}</span>
                </ng-container>

              </ng-container>
            </div>

          </ng-container>

        </td>
      </tr>
      <tr *ngIf="masterName == 'agentLedger'">
        <td class="h-min py-1" *ngFor="let head of headArray">
          <ng-container [ngSwitch]="head.head">
            <div *ngSwitchCase="'Addition'" style="float: right">
              {{addsum | indCurrency}}
            </div>
            <div *ngSwitchCase="'Deduction'" style="float: right">
              {{dedsum | indCurrency}}
            </div>
          </ng-container>
        </td>
      </tr>
      <tr *ngIf="masterName == 'agentLedger'">
        <td class="h-min py-1" *ngFor="let head of headArray">
          <ng-container [ngSwitch]="head.head">
            <div *ngSwitchCase="'Particulars'">
              Closing Balance
            </div>
            <div *ngSwitchCase="'Deduction'" style="float: right">
              {{pagedData['Data']['Items'][pagedData['Data']['Items'].length-1]['ClosingBalance'] | indCurrency}}
            </div>
          </ng-container>
        </td>
      </tr>
      <tr *ngIf="masterName == 'ccAvenue'">
        <td class="h-min py-1" *ngFor="let head of headArray">
          <ng-container [ngSwitch]="head.head">
            <div *ngSwitchCase="'Amount'" style="float: right">
              {{addsum | indCurrency}}
            </div>
          </ng-container>
        </td>
      </tr>

      <tr *ngIf="!pagedData['Data']['TotalCount']">
        <td colspan="3" class="hover:text-accent-800">
          No record found
        </td>
      </tr>

    </tbody>

    <tbody *ngIf="pagedData && !pagedData['Data'] && pagedData['AgentLedgerDetails']">
      <tr *ngIf="masterName == 'agentLedger'">
        <td class="h-min py-1" *ngFor="let head of headArray">
          <ng-container [ngSwitch]="head.head">
            <div *ngSwitchCase="'Particulars'">
              Opening Balance
            </div>
            <div *ngSwitchCase="'Addition'" style="float: right">
              {{pagedData['OpeningBalance'] | indCurrency}}
            </div>
          </ng-container>
        </td>
      </tr>
      <tr *ngFor="let item of pagedData['AgentLedgerDetails'],let i=index">
        <td class="h-min py-1" *ngFor="let head of headArray">

          <ng-container [ngSwitch]="head.head">

            <div *ngSwitchCase="'Particulars'">
              <span *ngIf="item['PaymentTransactionId']" class="text-primary" (click)="downloadinvoice(item['PaymentTransactionId'])">
                {{item[head['fieldName']]}}
              </span>
              <span *ngIf="!item['PaymentTransactionId']">
                {{item[head['fieldName']]}}
              </span>
            </div>

            <!-- table cell for Actions -->
            <div *ngSwitchCase="'Actions'" class="flex gap-x-4 justify-start items-center w-full">
              <ng-container [ngSwitch]="masterName">

                <ng-container *ngSwitchCase="'Role'">
                  <a class="fa fa-cog hover:text-primary-900 hover:cursor-pointer" matTooltip="Role Permission" [routerLink]="['role-permission', item[rowKey]]"></a>
                  <a *ngIf="!isViewDisabled" class="fa fa-eye hover:text-primary-900 hover:cursor-pointer" matTooltip="View" [routerLink]="['view', item[rowKey]]"></a>
                  <a *ngIf="!isEditDisabled && !(item['Name'] == 'Agent Role')" matTooltip="Edit" class="fa fa-edit hover:text-accent-900 hover:cursor-pointer" [routerLink]="['edit', item[rowKey]]"></a>
                  <a *ngIf="!isDeleteDisabled && !(item['Name'] == 'Agent Role')" matTooltip="Delete" class="fa fa-trash hover:text-warn-500 hover:cursor-pointer" (click)="deleteButton(item[rowKey])"></a>
                </ng-container>
                <ng-container *ngSwitchCase="'Recharge'">
                   <a *ngIf="!(item['Status']==100)" class="fa fa-refresh hover:text-primary-900 hover:cursor-pointer" matTooltip="Check Transaction Status" (click)="RechargeCheck(item[rowKey])"></a>
                </ng-container>
                <ng-container *ngSwitchCase="'E-KYC Approval'">
                  <i class="fa fa-eye hover:text-primary-900 hover:cursor-pointer" matTooltip="View" [routerLink]="['view', item[rowKey]]"></i>
                </ng-container>
                <ng-container *ngSwitchCase="'Policy'">
                  <a class="fa fa-eye hover:text-primary-900 hover:cursor-pointer" matTooltip="View" [routerLink]="['view', item['TransitTypeName'], item[rowKey]]"></a>
                  <a class="fa fa-edit hover:text-accent-900 hover:cursor-pointer" matTooltip="Edit" *ngIf="item['Status'] == 90" [routerLink]="['edit', item['TransitTypeName'], item[rowKey]]"></a>
                  <a class="fa fa-file-invoice hover:text-primary-900 hover:cursor-pointer" matTooltip="Download Invoice" *ngIf="item['Status'] == 100" (click)="DownloadInvoice(item[rowKey])"></a>
                  <a class="fa fa-download hover:text-primary-900 hover:cursor-pointer" matTooltip="Download Policy" (click)="DownloadPolicy(item[rowKey])"></a>
                </ng-container>

                <ng-container *ngSwitchCase="'PendingPolicy'">
                  <a class="fa fa-eye hover:text-primary-900 hover:cursor-pointer" matTooltip="View" [routerLink]="['view', item['TransitTypeName'], item[rowKey]]"></a>
                </ng-container>

                <ng-container *ngSwitchCase="'Claim'">
                  <a class="fa fa-recycle hover:text-primary-900 hover:cursor-pointer" *ngIf="item['PolicyProgress'] == 'Under Review'" matTooltip="Status Update" (click)="openDialog('Claim Remarks', 'Claim Remarks', item[rowKey])"></a>
                  <a *ngIf="!isInfoDisabled" class="fa fa-info-circle hover:text-blue-500 hover:cursor-pointer" matTooltip="Info" (click)="infoHandler(item[rowKey])"></a>
                </ng-container>

                <ng-container *ngSwitchCase="'Agent'">
                  <a *ngIf="!isViewDisabled" class="fa fa-eye hover:text-primary-900 hover:cursor-pointer" matTooltip="View" [routerLink]="['view', item[rowKey]]"></a>
                  <a *ngIf="!isEditDisabled" class="fa fa-edit hover:text-accent-900 hover:cursor-pointer" matTooltip="Edit" [routerLink]="['edit', item[rowKey]]"></a>
                  <a *ngIf="item['Status'] == 1 && (item['ApprovalRejectStatusName'] == 'Approved' || item['ApprovalRejectStatusName'] == 'System Approved')" class="fa fa-ban hover:text-warn-500 hover:cursor-pointer" matTooltip="Deactive" (click)="deactivate(item[rowKey])"></a>
                  <a *ngIf="item['Status'] == 0 && (item['ApprovalRejectStatusName'] == 'Approved' || item['ApprovalRejectStatusName'] == 'System Approved')" class="fa fa-check hover:text-warn-500 hover:cursor-pointer" matTooltip="Active" (click)="activate(item[rowKey])"></a>
                </ng-container>

                <ng-container *ngSwitchCase="'SettlingAgent'">
                  <a *ngIf="!isViewDisabled" class="fa fa-eye hover:text-primary-900 hover:cursor-pointer" matTooltip="View"
                    [routerLink]="['view', item[rowKey]]"></a>
                  <a *ngIf="!isEditDisabled" class="fa fa-edit hover:text-accent-900 hover:cursor-pointer" matTooltip="Edit"
                    [routerLink]="['edit', item[rowKey]]"></a>
                  <a *ngIf="!isDeleteDisabled" class="fa fa-trash hover:text-warn-500 hover:cursor-pointer" matTooltip="Delete"
                    (click)="deleteButton(item['SettlingAgentDetailId'])"></a>
                </ng-container>

                <ng-container *ngSwitchDefault>
                  <a *ngIf="!isViewDisabled" class="fa fa-eye hover:text-primary-900 hover:cursor-pointer" matTooltip="View"
                    [routerLink]="['view', item[rowKey]]"></a>
                  <a *ngIf="!isEditDisabled" class="fa fa-edit hover:text-accent-900 hover:cursor-pointer" matTooltip="Edit"
                    [routerLink]="['edit', item[rowKey]]"></a>
                  <a *ngIf="!isInfoDisabled" class="fa fa-info-circle hover:text-blue-500 hover:cursor-pointer" matTooltip="Info"
                    (click)="infoHandler(item[rowKey])"></a>
                  <a *ngIf="!isDeleteDisabled" class="fa fa-trash hover:text-warn-500 hover:cursor-pointer" matTooltip="Delete"
                    (click)="deleteButton(item[rowKey])"></a>
                </ng-container>

              </ng-container>
            </div>

            <!-- table cell for Image -->
            <div *ngSwitchCase="'Image'">
              <img class="w-max max-h-7" [src]="item[head['fieldName']]" alt="">
            </div>
            <!-- table cell for Radio Button -->
            <div *ngSwitchCase="'Select'">
              <mat-radio-button [value]="i" (click)="valueChange(i)"> </mat-radio-button>
            </div>


            <!-- table cell checkbox -->
            <div *ngSwitchCase="'Select Multiple'">
              <mat-checkbox class="example-margin" (click)="multiSelect($event, i)"></mat-checkbox>
            </div>

            <!-- table cell for Image -->
            <div *ngSwitchCase="'Is Active?'">
              {{ item[head['fieldName']] | active }}
            </div>

            
            <div *ngSwitchCase="'Date'" class="w-30">
              {{ item[head['fieldName']]}}
            </div>

            <div *ngSwitchCase="'Invoice Date'" class="w-30">
            {{ item[head['fieldName']]}}
            </div>

            <div *ngSwitchCase="'Creation Date'">
              {{ item[head['fieldName']] + 'Z' | date:'medium':'IST' }}
            </div>

            <div *ngSwitchCase="'Claim Date'">
              {{ item[head['fieldName']] + 'Z' | date:'medium':'IST' }}
            </div>

            <div *ngSwitchCase="'Issued Date'">
              <div *ngIf="item[head['fieldName']]">
                {{ item[head['fieldName']] + 'Z' | date:'medium':'IST'}}
              </div>
            </div>

            <div *ngSwitchCase="'Transaction Date'">
              {{ item[head['fieldName']] + 'Z' | date:'medium':'IST' }}
            </div>

            <div *ngSwitchCase="'Gross Premium'" style="float: right">
              {{item[head['fieldName']] | indCurrency}}
            </div>

            <div *ngSwitchCase="'Sum Insured'" style="float: right;">
            {{item[head['fieldName']] | indCurrency }}
            </div>

            <div *ngSwitchCase="'Amount'" style="float: right">
              {{item[head['fieldName']] | indCurrency}}
            </div>

            <div *ngSwitchCase="'Addition'" style="float: right">
              {{item[head['fieldName']] | indCurrency}}
            </div>

            <div *ngSwitchCase="'Deduction'" style="float: right">
              {{item[head['fieldName']] | indCurrency}}
            </div>

            <div *ngSwitchCase="'Balance'" style="float: right">
              {{item[head['fieldName']] | indCurrency}}
            </div>

            <div *ngSwitchCase="'Base Rate'" style="float: right">
              {{item[head['fieldName']]}}
            </div>
            
            <div *ngSwitchCase="'Dealer Rate'" style="float: right">
              {{item[head['fieldName']]}}
            </div>

            <div *ngSwitchCase="'Base Rate (W2W)'" style="float: right">
              {{item[head['fieldName']]}}
            </div>

            <div *ngSwitchCase="'Dealer Rate (W2W)'" style="float: right">
              {{item[head['fieldName']]}}
            </div>

            <div *ngSwitchCase="'Min. Premium Amt (Ex. GST)'" style="float: right">
              {{item[head['fieldName']] | indCurrency}}
            </div>

            <div *ngSwitchCase="'HRA Rate'" style="float: right">
              {{item[head['fieldName']]}}
            </div>

            <div *ngSwitchCase="'Min. Sum Assured Amt'" style="float: right">
              {{item[head['fieldName']] | indCurrency}}
            </div>

            <!-- table cell for Image -->
            <div *ngSwitchCase="'Offline'">
              {{ item[head['fieldName']] | yesNo }}
            </div>

            <!-- table cell for Image -->
            <div *ngSwitchCase="'Online'">
              {{ item[head['fieldName']] | yesNo }}
            </div>

            <div *ngSwitchCase="'Is Agent Approver?'">
              {{ item[head['fieldName']] | trueyes }}
            </div>

            <div *ngSwitchCase="'Is Policy Approver?'">
              {{ item[head['fieldName']] | trueyes }}
            </div>

            <div *ngSwitchCase="'Is Admin?'">
              {{ item[head['fieldName']] | trueyes }}
            </div>

            <div *ngSwitchCase="'Is Agent?'">
              {{ item[head['fieldName']] | trueyes }}
            </div>

            <div *ngSwitchCase="'Policy Status'">
              {{ item[head['fieldName']] | policystatus }}
            </div>
            
            <div *ngSwitchCase="'Preferred'">
              {{ item[head['fieldName']] | trueyes }}
            </div>

            <div *ngSwitchCase="'Is HRA?'">
              {{ item[head['fieldName']] | trueyes }}
            </div>

            
            <div *ngSwitchCase="'Non Preferred'">
              {{ item[head['fieldName']] | trueyes }}
            </div>

            
            <div *ngSwitchCase="'Sanctioned'">
              {{ item[head['fieldName']] | trueyes }}
            </div>

            <!-- <div *ngSwitchCase="'Decimal Places'" style="float: right">
              {{item[head['fieldName']]}}
            </div> -->

            <!-- table cell for default text fields -->
            <div *ngSwitchDefault>
              <ng-container [ngSwitch]="masterName">

                <!-- <ng-container *ngSwitchCase="'Country'">
                  <span [routerLink]="['view', item[rowKey]]" class="hover:cursor-pointer">{{ item[head['fieldName']]
                    }}</span>
                </ng-container>

                <ng-container *ngSwitchCase="'Currency'">
                  <span [routerLink]="['view', item.code]" class="hover:cursor-pointer">{{ item[head['fieldName']]
                    }}</span>
                </ng-container> -->

                <ng-container *ngSwitchCase="'Popup'">

                  <span >{{ item[head['fieldName']]
                    }}</span>
                </ng-container>

                <ng-container *ngSwitchCase="'Policy'">
                  <span matTooltip="View" [routerLink]="['view', item['TransitTypeName'], item[rowKey]]" class="hover:cursor-pointer hover:text-primary font-medium">{{ item[head['fieldName']]
                  }}</span>
                </ng-container>

                <ng-container *ngSwitchCase="'PendingPolicy'">
                  <span matTooltip="View" [routerLink]="['view', item['TransitTypeName'], item[rowKey]]" class="hover:cursor-pointer hover:text-primary font-medium">{{ item[head['fieldName']]
                  }}</span>
                </ng-container>
                
                
                <ng-container *ngSwitchCase="'Recharge'">
                  <span class="hover:cursor-pointer hover:text-primary font-medium">{{ item[head['fieldName']]
                    }}</span>
                </ng-container>

                <ng-container *ngSwitchDefault>
                  
                  <span *ngIf='!isViewDisabled' matTooltip="View" [routerLink]="['view', item[rowKey]]" class="hover:cursor-pointer hover:text-primary font-medium">{{ item[head['fieldName']]
                    }}</span>
                    <span *ngIf="isViewDisabled">{{ item[head['fieldName']]
                    }}</span>
                </ng-container>

              </ng-container>
            </div>

          </ng-container>

        </td>
      </tr>
      <tr *ngIf="masterName == 'agentLedger'">
        <td class="h-min py-1" *ngFor="let head of headArray">
          <ng-container [ngSwitch]="head.head">
            <div *ngSwitchCase="'Addition'" style="float: right">
              <b>{{addsum | indCurrency}}</b>
            </div>
            <div *ngSwitchCase="'Deduction'" style="float: right">
              <b>{{dedsum | indCurrency}}</b>
            </div>
          </ng-container>
        </td>
      </tr>
      <tr *ngIf="masterName == 'agentLedger'">
        <td class="h-min py-1" *ngFor="let head of headArray">
          <ng-container [ngSwitch]="head.head">
            <div *ngSwitchCase="'Particulars'">
              Closing Balance
            </div>
            <div *ngSwitchCase="'Deduction'" style="float: right">
              {{pagedData['ClosingBalance'] | indCurrency}}
            </div>
          </ng-container>
        </td>
      </tr>
      <tr *ngIf="masterName == 'ccAvenue'">
        <td class="h-min py-1" *ngFor="let head of headArray">
          <ng-container [ngSwitch]="head.head">
            <div *ngSwitchCase="'Amount'" style="float: right">
              <b>{{addsum | indCurrency}}</b>
            </div>
          </ng-container>
        </td>
      </tr>
    </tbody>

    <tbody *ngIf="pagedData && !pagedData['Data'] && !pagedData['AgentLedgerDetails']">
      <tr *ngIf="masterName == 'agentLedger'">
        <td class="h-min py-1" *ngFor="let head of headArray">
          <ng-container [ngSwitch]="head.head">
            <div *ngSwitchCase="'Particulars'">
              Opening Balance
            </div>
            <div *ngSwitchCase="'Addition'" style="float: right">
              {{pagedData['OpeningBalance'] | indCurrency}}
            </div>
          </ng-container>
        </td>
      </tr>
      <tr *ngFor="let item of pagedData,let i=index">
        <td class="h-min py-1" *ngFor="let head of headArray">

          <ng-container [ngSwitch]="head.head">

            <div *ngSwitchCase="'Particulars'">
              <span *ngIf="item['PaymentTransactionId']" class="text-primary" (click)="downloadinvoice(item['PaymentTransactionId'])">
                {{item[head['fieldName']]}}
              </span>
              <span *ngIf="!item['PaymentTransactionId']">
                {{item[head['fieldName']]}}
              </span>
            </div>

            <!-- table cell for Actions -->
            <div *ngSwitchCase="'Actions'" class="flex gap-x-4 justify-start items-center w-full">
              <ng-container [ngSwitch]="masterName">

                <ng-container *ngSwitchCase="'Role'">
                  <a class="fa fa-cog hover:text-primary-900 hover:cursor-pointer" matTooltip="Role Permission" [routerLink]="['role-permission', item[rowKey]]"></a>
                  <a *ngIf="!isViewDisabled" class="fa fa-eye hover:text-primary-900 hover:cursor-pointer" matTooltip="View" [routerLink]="['view', item[rowKey]]"></a>
                  <a *ngIf="!isEditDisabled && !(item['Name'] == 'Agent Role')" matTooltip="Edit" class="fa fa-edit hover:text-accent-900 hover:cursor-pointer" [routerLink]="['edit', item[rowKey]]"></a>
                  <a *ngIf="!isDeleteDisabled && !(item['Name'] == 'Agent Role')" matTooltip="Delete" class="fa fa-trash hover:text-warn-500 hover:cursor-pointer" (click)="deleteButton(item[rowKey])"></a>
                </ng-container>
                <ng-container *ngSwitchCase="'Recharge'">
                  <!-- <a *ngIf="item['Status']==90" class="fa fa-refresh hover:text-primary-900 hover:cursor-pointer" matTooltip="Check Transaction Status" (click)="RechargeCheck(item[rowKey])"></a> -->
                </ng-container>
                <ng-container *ngSwitchCase="'E-KYC Approval'">
                  <i class="fa fa-eye hover:text-primary-900 hover:cursor-pointer" matTooltip="View" [routerLink]="['view', item[rowKey]]"></i>
                </ng-container>
                {{item['Policy Status']}}
                <ng-container *ngSwitchCase="'Policy'">
                  <a class="fa fa-eye hover:text-primary-900 hover:cursor-pointer" matTooltip="View" [routerLink]="['view', item['TransitTypeName'], item[rowKey]]"></a>
                  <a class="fa fa-edit hover:text-accent-900 hover:cursor-pointer" matTooltip="Edit" *ngIf="item['Status'] == 90" [routerLink]="['edit', item['TransitTypeName'], item[rowKey]]"></a>
                  <a class="fa fa-file-invoice hover:text-primary-900 hover:cursor-pointer" matTooltip="Download Invoice" *ngIf="item['Status'] == 100" (click)="DownloadInvoice(item[rowKey])"></a>
                  <a class="fa fa-download hover:text-primary-900 hover:cursor-pointer" matTooltip="Download Policy" (click)="DownloadPolicy(item[rowKey])"></a>
                </ng-container>

                <ng-container *ngSwitchCase="'PendingPolicy'">
                  <a class="fa fa-eye hover:text-primary-900 hover:cursor-pointer" matTooltip="View" [routerLink]="['view', item['TransitTypeName'], item[rowKey]]"></a>
                </ng-container>

                <ng-container *ngSwitchCase="'Claim'">
                  <a class="fa fa-recycle hover:text-primary-900 hover:cursor-pointer" *ngIf="item['PolicyProgress'] == 'Under Review'" matTooltip="Status Update" (click)="openDialog('Claim Remarks', 'Claim Remarks', item[rowKey])"></a>
                  <a *ngIf="!isInfoDisabled" class="fa fa-info-circle hover:text-blue-500 hover:cursor-pointer" matTooltip="Info" (click)="infoHandler(item[rowKey])"></a>
                </ng-container>

                <ng-container *ngSwitchCase="'Agent'">
                  <a *ngIf="!isViewDisabled" class="fa fa-eye hover:text-primary-900 hover:cursor-pointer" matTooltip="View" [routerLink]="['view', item[rowKey]]"></a>
                  <a *ngIf="!isEditDisabled" class="fa fa-edit hover:text-accent-900 hover:cursor-pointer" matTooltip="Edit" [routerLink]="['edit', item[rowKey]]"></a>
                  <a *ngIf="item['Status'] == 1 && (item['ApprovalRejectStatusName'] == 'Approved' || item['ApprovalRejectStatusName'] == 'System Approved')" class="fa fa-ban hover:text-warn-500 hover:cursor-pointer" matTooltip="Deactive" (click)="deactivate(item[rowKey])"></a>
                  <a *ngIf="item['Status'] == 0 && (item['ApprovalRejectStatusName'] == 'Approved' || item['ApprovalRejectStatusName'] == 'System Approved')" class="fa fa-check hover:text-warn-500 hover:cursor-pointer" matTooltip="Active" (click)="activate(item[rowKey])"></a>
                </ng-container>

                <ng-container *ngSwitchCase="'SettlingAgent'">
                  <a *ngIf="!isViewDisabled" class="fa fa-eye hover:text-primary-900 hover:cursor-pointer" matTooltip="View"
                    [routerLink]="['view', item[rowKey]]"></a>
                  <a *ngIf="!isEditDisabled" class="fa fa-edit hover:text-accent-900 hover:cursor-pointer" matTooltip="Edit"
                    [routerLink]="['edit', item[rowKey]]"></a>
                  <a *ngIf="!isDeleteDisabled" class="fa fa-trash hover:text-warn-500 hover:cursor-pointer" matTooltip="Delete"
                    (click)="deleteButton(item['SettlingAgentDetailId'])"></a>
                </ng-container>

                <ng-container *ngSwitchDefault>
                  <a *ngIf="!isViewDisabled" class="fa fa-eye hover:text-primary-900 hover:cursor-pointer" matTooltip="View"
                    [routerLink]="['view', item[rowKey]]"></a>
                  <a *ngIf="!isEditDisabled" class="fa fa-edit hover:text-accent-900 hover:cursor-pointer" matTooltip="Edit"
                    [routerLink]="['edit', item[rowKey]]"></a>
                  <a *ngIf="!isInfoDisabled" class="fa fa-info-circle hover:text-blue-500 hover:cursor-pointer" matTooltip="Info"
                    (click)="infoHandler(item[rowKey])"></a>
                  <a *ngIf="!isDeleteDisabled" class="fa fa-trash hover:text-warn-500 hover:cursor-pointer" matTooltip="Delete"
                    (click)="deleteButton(item[rowKey])"></a>
                </ng-container>

              </ng-container>
            </div>

            <!-- table cell for Image -->
            <div *ngSwitchCase="'Image'">
              <img class="w-max max-h-7" [src]="item[head['fieldName']]" alt="">
            </div>
            <!-- table cell for Radio Button -->
            <div *ngSwitchCase="'Select'">
              <mat-radio-button [value]="i" (click)="valueChange(i)"> </mat-radio-button>
            </div>


            <!-- table cell checkbox -->
            <div *ngSwitchCase="'Select Multiple'">
              <mat-checkbox class="example-margin" (click)="multiSelect($event, i)"></mat-checkbox>
            </div>

            <!-- table cell for Image -->
            <div *ngSwitchCase="'Is Active?'">
              {{ item[head['fieldName']] | active }}
            </div>

            
            <div *ngSwitchCase="'Date'" class="w-30">
              {{ item[head['fieldName']]}}
            </div>

            <div *ngSwitchCase="'Invoice Date'" class="w-30">
            {{ item[head['fieldName']]}}
            </div>

            <div *ngSwitchCase="'Creation Date'">
              {{ item[head['fieldName']] + 'Z' | date:'medium':'IST' }}
            </div>

            <div *ngSwitchCase="'Claim Date'">
              {{ item[head['fieldName']] + 'Z' | date:'medium':'IST' }}
            </div>

            <div *ngSwitchCase="'Issued Date'" class="w-25">
              <div *ngIf="item[head['fieldName']]">
                {{item[head['fieldName']]}}
              </div>
            </div>

            <div *ngSwitchCase="'Transaction Date'">
              {{ item[head['fieldName']] + 'Z' | date:'medium':'IST' }}
            </div>

            <div *ngSwitchCase="'Total Premium'" style="float: right">
              {{item[head['fieldName']] | indCurrency}}
            </div>

            <div *ngSwitchCase="'CGST'" style="float: right">
              {{item[head['fieldName']] | indCurrency}}
            </div>

            <div *ngSwitchCase="'SGST'" style="float: right">
              {{item[head['fieldName']] | indCurrency}}
            </div>

            <div *ngSwitchCase="'IGST'" style="float: right">
              {{item[head['fieldName']] | indCurrency}}
            </div>

            <div *ngSwitchCase="'GST Amt.'" style="float: right">
              {{item[head['fieldName']] | indCurrency}}
            </div>

            <div *ngSwitchCase="'Net Premium'" style="float: right">
              {{item[head['fieldName']] | indCurrency}}
            </div>

            <div *ngSwitchCase="'Customer Base Premium'" style="float: right">
              {{item[head['fieldName']] | indCurrency}}
            </div>

            <div *ngSwitchCase="'Customer Total Premium'" style="float: right">
              {{item[head['fieldName']] | indCurrency}}
            </div>

            <div *ngSwitchCase="'Net Premium Amt'" style="float: right">
              {{item[head['fieldName']] | indCurrency}}
            </div>

            <div *ngSwitchCase="'GST Amt'" style="float: right">
              {{item[head['fieldName']] | indCurrency}}
            </div>

            <div *ngSwitchCase="'Round Off'" style="float: right">
              {{item[head['fieldName']] | indCurrency}}
            </div>

            <div *ngSwitchCase="'Total Premium Amt (A)'" style="float: right">
              {{item[head['fieldName']] | indCurrency}}
            </div>

            <div *ngSwitchCase="'Sum Insured Amt'" style="float: right">
              {{item[head['fieldName']] | indCurrency}}
            </div>

            <div *ngSwitchCase="'Customer Base Premium Amt'" style="float: right">
              {{item[head['fieldName']] | indCurrency}}
            </div>


            <div *ngSwitchCase="'Customer Total Premium Amt'" style="float: right">
              {{item[head['fieldName']] | indCurrency}}
            </div>
            
            <div *ngSwitchCase="'Threshold Amt'" style="float: right">
              {{item[head['fieldName']] | indCurrency}}
            </div>
            
            <div *ngSwitchCase="'Policy Rate'" style="float: right">
              {{item[head['fieldName']] | indCurrency}}
            </div>
            
            <div *ngSwitchCase="'Policy Premium Amt'" style="float: right">
              {{item[head['fieldName']] | indCurrency}}
            </div>
            
            <div *ngSwitchCase="'Policy Min Premium Amt'" style="float: right">
              {{item[head['fieldName']] | indCurrency}}
            </div>
            
            <div *ngSwitchCase="'Partner Rate'" style="float: right">
              {{item[head['fieldName']] | indCurrency}}
            </div>
            
            <div *ngSwitchCase="'Partner Min Premium Amt'" style="float: right">
              {{item[head['fieldName']] | indCurrency}}
            </div>

            <div *ngSwitchCase="'Total Premium Amt'" style="float: right">
              {{item[head['fieldName']] | indCurrency}}
            </div>

            <div *ngSwitchCase="'Invoice Amt FC'" style="float: right">
              {{item[head['fieldName']] | indCurrency}}
            </div>

            <div *ngSwitchCase="'Extra Percentage'" style="float: right">
              {{item[head['fieldName']] | indCurrency}}
            </div>

            <div *ngSwitchCase="'Exchange Rate'" style="float: right">
              {{item[head['fieldName']] | indCurrency}}
            </div>

            <div *ngSwitchCase="'Freight Amt'" style="float: right">
              {{item[head['fieldName']] | indCurrency}}
            </div>

            <div *ngSwitchCase="'Duty Amt'" style="float: right">
              {{item[head['fieldName']] | indCurrency}}
            </div>

            <div *ngSwitchCase="'GST Percentage'" style="float: right">
              {{item[head['fieldName']] | indCurrency}}
            </div>

            <div *ngSwitchCase="'IGST Amt'" style="float: right">
              {{item[head['fieldName']] | indCurrency}}
            </div>

            <div *ngSwitchCase="'SGST Amt'" style="float: right">
              {{item[head['fieldName']] | indCurrency}}
            </div>

            <div *ngSwitchCase="'CGST Amt'" style="float: right">
              {{item[head['fieldName']] | indCurrency}}
            </div>

            <div *ngSwitchCase="'Round off Amt'" style="float: right">
              {{item[head['fieldName']] | indCurrency}}
            </div>

            <div *ngSwitchCase="'Customer CGST Amt'" style="float: right">
              {{item[head['fieldName']] | indCurrency}}
            </div>

            <div *ngSwitchCase="'Customer SGST Amt'" style="float: right">
              {{item[head['fieldName']] | indCurrency}}
            </div>
            <div *ngSwitchCase="'Customer IGST Amt'" style="float: right">
              {{item[head['fieldName']] | indCurrency}}
            </div>
            <div *ngSwitchCase="'Customer GST Amt'" style="float: right">
              {{item[head['fieldName']] | indCurrency}}
            </div>
            <div *ngSwitchCase="'ODC Length'" style="float: right">
              {{item[head['fieldName']] | indCurrency}}
            </div>
            <div *ngSwitchCase="'ODC Breadth'" style="float: right">
              {{item[head['fieldName']] | indCurrency}}
            </div>
            <div *ngSwitchCase="'ODC Height'" style="float: right">
              {{item[head['fieldName']] | indCurrency}}
            </div>
            <div *ngSwitchCase="'Cargo Weight'" style="float: right">
              {{item[head['fieldName']] | indCurrency}}
            </div>

            <div *ngSwitchCase="'Customer Total Premium Amt (B)'" style="float: right">
              {{item[head['fieldName']] | indCurrency}}
            </div>

            <div *ngSwitchCase="'Agent Payout Gross Amt C=(B-A)'" style="float: right">
              {{item[head['fieldName']] | indCurrency}}
            </div>

            <div *ngSwitchCase="'GST Deducted From Agent Payout (D)'" style="float: right">
              {{item[head['fieldName']] | indCurrency}}
            </div>

            <div *ngSwitchCase="'Agent Net Payable Amt E=(C-D)'" style="float: right">
              {{item[head['fieldName']] | indCurrency}}
            </div>

            <div *ngSwitchCase="'Net Premium Amt as per TL Rate (B)'" style="float: right">
              {{item[head['fieldName']] | indCurrency}}
            </div>
            
            <div *ngSwitchCase="'TL Gross Payout Amt C=(A-B)'" style="float: right">
              {{item[head['fieldName']] | indCurrency}}
            </div>

            <div *ngSwitchCase="'GST Deducted From TL Payout (D)'" style="float: right">
              {{item[head['fieldName']] | indCurrency}}
            </div>

            <div *ngSwitchCase="'TL Net Payable Amt E=(C-D)'" style="float: right">
              {{item[head['fieldName']] | indCurrency}}
            </div>

            <div *ngSwitchCase="'R/Off'" style="float: right">
              {{item[head['fieldName']] | indCurrency}}
            </div>

            <div *ngSwitchCase="'Total Sum Insured'" style="float: right;">
            {{item[head['fieldName']] | indCurrency }}
            </div>

            <div *ngSwitchCase="'Amount'" style="float: right">
              {{item[head['fieldName']] | indCurrency}}
            </div>

            <div *ngSwitchCase="'Addition'" style="float: right">
              {{item[head['fieldName']] | indCurrency}}
            </div>

            <div *ngSwitchCase="'Deduction'" style="float: right">
              {{item[head['fieldName']] | indCurrency}}
            </div>

            <div *ngSwitchCase="'Balance'" style="float: right">
              {{item[head['fieldName']] | indCurrency}}
            </div>

            <div *ngSwitchCase="'Base Rate'" style="float: right">
              {{item[head['fieldName']]}}
            </div>
            
            <div *ngSwitchCase="'Dealer Rate'" style="float: right">
              {{item[head['fieldName']]}}
            </div>

            <div *ngSwitchCase="'Base Rate (W2W)'" style="float: right">
              {{item[head['fieldName']]}}
            </div>

            <div *ngSwitchCase="'Dealer Rate (W2W)'" style="float: right">
              {{item[head['fieldName']]}}
            </div>

            <div *ngSwitchCase="'Min. Premium Amt (Ex. GST)'" style="float: right">
              {{item[head['fieldName']] | indCurrency}}
            </div>

            <div *ngSwitchCase="'HRA Rate'" style="float: right">
              {{item[head['fieldName']]}}
            </div>

            <div *ngSwitchCase="'Min. Sum Assured Amt'" style="float: right">
              {{item[head['fieldName']] | indCurrency}}
            </div>

            <!-- table cell for Image -->
            <div *ngSwitchCase="'Offline'">
              {{ item[head['fieldName']] | yesNo }}
            </div>

            <!-- table cell for Image -->
            <div *ngSwitchCase="'Online'">
              {{ item[head['fieldName']] | yesNo }}
            </div>

            <div *ngSwitchCase="'Is Agent Approver?'">
              {{ item[head['fieldName']] | trueyes }}
            </div>

            <div *ngSwitchCase="'Is Policy Approver?'">
              {{ item[head['fieldName']] | trueyes }}
            </div>

            <div *ngSwitchCase="'Is Admin?'">
              {{ item[head['fieldName']] | trueyes }}
            </div>

            <div *ngSwitchCase="'Is Agent?'">
              {{ item[head['fieldName']] | trueyes }}
            </div>

            <div *ngSwitchCase="'Policy Status'">
              {{ item[head['fieldName']] | policystatus }}
            </div>
            
            <div *ngSwitchCase="'Preferred'">
              {{ item[head['fieldName']] | trueyes }}
            </div>

            <div *ngSwitchCase="'Is HRA?'">
              {{ item[head['fieldName']] | trueyes }}
            </div>

            
            <div *ngSwitchCase="'Non Preferred'">
              {{ item[head['fieldName']] | trueyes }}
            </div>

            
            <div *ngSwitchCase="'Sanctioned'">
              {{ item[head['fieldName']] | trueyes }}
            </div>

            <div *ngSwitchCase="'Settlement Amount'" style="float: right">
              {{ item[head['fieldName']] | indCurrency }}
            </div>

            <div *ngSwitchCase="'Final Bill Amount'" style="float: right">
              {{ item[head['fieldName']] | indCurrency }}
            </div>

            <!-- <div *ngSwitchCase="'Decimal Places'" style="float: right">
              {{item[head['fieldName']]}}
            </div> -->

            <!-- table cell for default text fields -->
            <div *ngSwitchDefault>
              <ng-container [ngSwitch]="masterName">

                <!-- <ng-container *ngSwitchCase="'Country'">
                  <span [routerLink]="['view', item[rowKey]]" class="hover:cursor-pointer">{{ item[head['fieldName']]
                    }}</span>
                </ng-container>

                <ng-container *ngSwitchCase="'Currency'">
                  <span [routerLink]="['view', item.code]" class="hover:cursor-pointer">{{ item[head['fieldName']]
                    }}</span>
                </ng-container> -->

                <ng-container *ngSwitchCase="'Popup'">

                  <span >{{ item[head['fieldName']]
                    }}</span>
                </ng-container>

                <ng-container *ngSwitchCase="'Policy'">
                  <span matTooltip="View" [routerLink]="['view', item['TransitTypeName'], item[rowKey]]" class="hover:cursor-pointer hover:text-primary font-medium">{{ item[head['fieldName']]
                  }}</span>
                </ng-container>

                <ng-container *ngSwitchCase="'PendingPolicy'">
                  <span matTooltip="View" [routerLink]="['view', item['TransitTypeName'], item[rowKey]]" class="hover:cursor-pointer hover:text-primary font-medium">{{ item[head['fieldName']]
                  }}</span>
                </ng-container>
                
                
                <ng-container *ngSwitchCase="'Recharge'">
                  <span class="hover:cursor-pointer hover:text-primary font-medium">{{ item[head['fieldName']]
                    }}</span>
                </ng-container>

                <ng-container *ngSwitchCase="'certificateRegister'">
                  <a (click)="DownloadPolicy(item[rowKey])" [routerLink]="['view', item['TransitType'], item[rowKey]]" class="hover:cursor-pointer hover:text-primary font-medium">{{ item[head['fieldName']]
                    }}</a>
                </ng-container>

                <ng-container *ngSwitchDefault>
                  
                  <span *ngIf='!isViewDisabled' matTooltip="View" [routerLink]="['view', item[rowKey]]" class="hover:cursor-pointer hover:text-primary font-medium">{{ item[head['fieldName']]
                    }}</span>
                    <span *ngIf="isViewDisabled">{{ item[head['fieldName']]
                    }}</span>
                </ng-container>

              </ng-container>
            </div>

          </ng-container>

        </td>
      </tr>
      <tr *ngIf="masterName == 'certificateRegister'">
        <td class="h-min py-1" *ngFor="let head of headArray">
          <ng-container [ngSwitch]="head.head">
            <div *ngSwitchCase="'Billing Party GSTIN'">
              <b>Total Amount (₹)</b>
            </div>
            <div *ngSwitchCase="'Total Sum Insured'" style="float: right">
              <b>{{sumtotal | indCurrency}}</b>
            </div>
            <div *ngSwitchCase="'Net Premium'" style="float: right">
              <b>{{nettotal | indCurrency}}</b>
            </div>
            <div *ngSwitchCase="'CGST'" style="float: right">
              <b>{{cgsttotal | indCurrency}}</b>
            </div>
            <div *ngSwitchCase="'SGST'" style="float: right">
              <b>{{sgsttotal | indCurrency}}</b>
            </div>
            <div *ngSwitchCase="'IGST'" style="float: right">
              <b>{{igsttotal | indCurrency}}</b>
            </div>
            <div *ngSwitchCase="'GST Amt.'" style="float: right">
              <b>{{gsttotal | indCurrency}}</b>
            </div>
            <div *ngSwitchCase="'R/Off'" style="float: right">
              <b>{{rofftotal | indCurrency}}</b>
            </div>
            <div *ngSwitchCase="'Total Premium'" style="float: right">
              <b>{{grosstotal | indCurrency}}</b>
            </div>
            <div *ngSwitchCase="'Customer Base Premium'" style="float: right">
              <b>{{customerbasetotal | indCurrency}}</b>
            </div>
            <div *ngSwitchCase="'Customer Total Premium'" style="float: right">
              <b>{{customertotal | indCurrency}}</b>
            </div>
          </ng-container>
        </td>
      </tr>
    </tbody>
    <tfoot *ngIf="pagedData && !pagedData['Data']">
      <tr class="relative" *ngIf="!pagedData.length">
        <td colspan="3" class="hover:text-accent-800">
          No record found
        </td>
      </tr>
      </tfoot>
    <!-- endregion table body -->

    <!-- region table footer -->
    <tfoot *ngIf="pagedData && pagedData['Data']">
      <tr class="relative">
        <td [attr.colspan]="headArray.length + 1">
          <div class="flex gap-y-2.5 flex-col-reverse lg:flex-row lg:justify-between lg:items-center">
            <div class="text-accent-500 flex items-center gap-x-8 lg:justify-between">

              <div class="flex gap-x-2 items-center">
                <span for="limit">Items per page:</span>
                <select id="limit" #limit name="limit"
                  class="px-2 py-1 w-20"
                  (change)="setLimit(limit.value)" [value]="pagefilters.limit">
                  <option value="20">20</option>
                  <option value="50">50</option>
                  <option value="100">100</option>
                  <option value="200">200</option>
                </select>
              </div>

              <div>
                Showing
                {{
                pagedData['Data']['TotalCount']? pagedData['Data']['CurrentPage'] * pagedData['Data']['PageSize'] -
                pagedData['Data']['PageSize'] + 1: 0
                }}
                to
                {{
                pagedData['Data']['TotalCount'] > pagedData['Data']['CurrentPage'] * pagedData['Data']['PageSize']
                ? pagedData['Data']['CurrentPage'] * pagedData['Data']['PageSize']
                : pagedData['Data']['TotalCount']
                }}
                of {{ pagedData['Data']['TotalCount'] }} results
              </div>
            </div>

            <!-- region table navigations -->
            <div class="flex space-x-9 md:space-x-4 md:w-50 lg:justify-center lg:absolute lg:right-0">
              <button mat-raised-button
                class="rounded-lg border w-20 button p-1 text-primary-500 hover:bg-primary hover:text-on-primary disabled:hover:bg-transparent disabled:hover:text-accent-500 disabled:text-accent-500"
                [disabled]="!pagedData['Data']['HasPrevious']" (click)="pagePrevious()">
                Previous
              </button>
              <button mat-raised-button
                class="rounded-lg border w-20 button p-1 text-primary-500 hover:bg-primary hover:text-on-primary disabled:hover:bg-transparent disabled:hover:text-accent-500 disabled:text-accent-500"
                [disabled]="!pagedData['Data']['HasNext']" (click)="pageNext()">
                Next
              </button>
            </div>
            <!-- endregion table navigations -->
          </div>
        </td>
      </tr>
    </tfoot>
    <!-- endregion table footer -->
  </table>
  </div>
</div>
