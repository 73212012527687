import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'indCurrency',
  pure: false
})
export class IndCurrencyPipe implements PipeTransform {

  transform(value: any){
    if (value || value == 0){
      let INDcurrency = (+value).toLocaleString('en-IN');
      return INDcurrency;
    }
    else {
      return null;
    }
  }

}
