import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RemarksComponent } from './remarks.component';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatDialogModule } from '@angular/material/dialog';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { SharedMaterialModule } from 'src/app/shared/shared-material';

@NgModule({
  declarations: [
    RemarksComponent
  ],
  exports: [
    RemarksComponent
  ],
  imports: [
    CommonModule,
    MatIconModule,
    MatButtonModule,
    MatDialogModule,
    ReactiveFormsModule,
    FormsModule,
    SharedMaterialModule
  ]
})
export class RemarksModule { }
