import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AuthLayoutComponent } from './auth-layout.component';
import { RouterModule } from "@angular/router";
import { ProgressBarModule } from "@lib/ui/components/progress-bar/progress-bar.module";


@NgModule({
  declarations: [
    AuthLayoutComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    ProgressBarModule
  ],
  exports: [AuthLayoutComponent]
})
export class AuthLayoutModule { }
