import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ToolbarComponent } from './toolbar.component';
import { MatIconModule } from "@angular/material/icon";
import { MatButtonModule } from "@angular/material/button";
import { FullscreenModule } from '../fullscreen/fullscreen.module';
import { UserModule } from '../user/user.module';
import { BalanceModule } from '../balance/balance.module'
import { MatTooltipModule } from '@angular/material/tooltip';
import { AuthRoutingModule } from 'src/app/features/auth/auth-routing.module';



@NgModule({
  declarations: [
    ToolbarComponent
  ],
  imports: [
    CommonModule,
    MatButtonModule,
    MatIconModule,
    FullscreenModule,
    UserModule,
    BalanceModule,
    MatTooltipModule,
    AuthRoutingModule,
    MatTooltipModule
  ],
  exports: [
    ToolbarComponent
  ]
})
export class ToolbarModule { }
