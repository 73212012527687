import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'yesNo'
})
export class YesNoPipe implements PipeTransform {

  transform(text: number) {
    if (text == 1) return "Yes";
    else return "No";
  }

}
