<!-- Wrapper -->
<div
  class="relative flex flex-0 items-center w-screen h-16 px-4 md:px-6 z-49 shadow dark:shadow-none dark:border-b bg-card dark:bg-transparent print:hidden">
  <div class="flex items-center pr-2 space-x-2">

    <!-- Navigation toggle button - visible only if sidenav is closed -->
    <button *ngIf="this.user" mat-icon-button (click)="toggleSidenav()" matTooltip="Menu">
      <mat-icon [svgIcon]="'heroicons_outline:menu'"></mat-icon>
    </button>
<a *ngIf="this.user" [routerLink]="['/app/home']">
    <div class="flex justify-center">
      <!-- Brand logo -->
      <img alt="logo"
        [src]="['assets/images/logos/log.svg']"
        class="w-60 self-center"
      />
    </div>
</a>

<div *ngIf="!this.user" class="flex justify-center">
  <!-- Brand logo -->
  <img alt="logo"
    [src]="['assets/images/logos/log.svg']"
    class="w-60 self-center"
  />
</div>
    <!-- Navigation appearance toggle button - will move to the sidebar later or settings for user-->
    <!-- <button class="hidden md:inline-flex" mat-icon-button (click)="toggleNavigationAppearance()">
      <mat-icon [svgIcon]="'heroicons_outline:switch-horizontal'"></mat-icon>
    </button> -->
  </div>

  <!-- Toolbar Components -->
  <div class="flex items-center pl-2 ml-auto space-x-0.5 sm:space-x-2">
    <!-- <gnx-search [appearance]="'bar'"></gnx-search>
    <gnx-fullscreen class="hidden md:block"></gnx-fullscreen> -->
    <b>{{this.user?.Name}}</b>
    <gnx-balance *ngIf="this.user"></gnx-balance>
    <gnx-user *ngIf="this.user"></gnx-user>
    <a *ngIf="this.user && !this.user.AgentId" matTooltip="Settings" [routerLink]="'/app/settings'" mat-icon-button><mat-icon>settings</mat-icon></a>
  </div>

</div>
