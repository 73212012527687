import { environment } from '../../../environments/environment';

const API_BASE_URL = environment.apiURL;

export const API_ENDPOINTS = {

  Dashboard: {
    PolicyReport: API_BASE_URL + '/Dashboard/AgentPolicySummaryReport',
    GrossReport: API_BASE_URL + '/Dashboard/AgentPolicyGrossPremiumReport'
  },

  AgentLedger: {
    Base: API_BASE_URL + '/AgentLedgerReport',
    Invoice: API_BASE_URL + '/AgentLedgerReport/PaymentReceipt'
  },

  AgentPayout: {
    Base: API_BASE_URL + '/AgentPayoutReport',
  },

  BranchReport: {
    Base: API_BASE_URL + '/BranchReport',
  },

  BajajReport: {
    Base: API_BASE_URL + '/BajajReport',
  },

  SalesPersonReport: {
    Base: API_BASE_URL + '/SalesPersonReport',
  },

  AgentReport: {
    Base: API_BASE_URL + '/AgentReport',
  },

  ManagementReport: {
    Base: API_BASE_URL + '/ManagementReport',
  },

  CompleteMisReport: {
    Base: API_BASE_URL + '/CompleteMISReport',
  },

  SupportExecutiveReport: {
    Base: API_BASE_URL + '/SupportExecutiveReport',
  },

  TLReport: {
    Base: API_BASE_URL + '/TLReport',
  },

  TLPayout: {
    Base: API_BASE_URL + '/TLPayoutReport',
  },

  Branch: {
    Base: API_BASE_URL + '/Branch',
    List: API_BASE_URL + '/Branch/list'
  },

  PolicyReport: {
    Base: API_BASE_URL + '/PolicyReport',
  },

  ClaimRegister: {
    Base: API_BASE_URL + '/ClaimRegisterReport',
  },

  Clauses: {
    Base: API_BASE_URL + '/ClauseMatrix',
    List: API_BASE_URL + '/ClauseMatrix/list',
  },
  
  ClauseMaster: {
    Base: API_BASE_URL + '/Clause',
    List: API_BASE_URL + '/Clause/list'
  },

  Domain: environment.apiDomain,

  Reports: {
    CCAvenue: API_BASE_URL + '/'
  },

  Recharge: {
    Base: API_BASE_URL + '/PaymentTransaction/initiateTransaction',
    List: API_BASE_URL + '/PaymentTransaction',
    Status: API_BASE_URL + '/PaymentTransaction/orderStatusChecker',
    Customer: API_BASE_URL + '/PaymentTransaction/initiateCustomerPolicyTransaction'
  },

  Attachment: {
    Upload: API_BASE_URL + '/Attachment/Upload',
  },
  Auth: {
    Register: API_BASE_URL + '/auth/register',
    Login: API_BASE_URL + '/Auth/login',
    RefreshToken: API_BASE_URL + 'Auth/refresh-token',
    UserProfile: API_BASE_URL + '/Auth/profile',
    SendOtp: API_BASE_URL + '/Auth/send-OTP',
    resetPassword: API_BASE_URL + '/Auth/forgot-password-change',
    GST: API_BASE_URL + '/Auth/gstIn-validate',
    PAN: API_BASE_URL + '/Auth/Pan-validate'
  },
  Container: {
    Base: API_BASE_URL + '/Container',
    List: API_BASE_URL + '/Container/list',
  },
  Agent: {
    Base: API_BASE_URL + '/Agent',
    List: API_BASE_URL + '/Agent/list',
    Register: API_BASE_URL + '/Agent/Registration',
    StatusUpdate: API_BASE_URL + '/Agent/statusUpdate'
  },

  Claim: {
    Base: API_BASE_URL + '/claim',
    List: API_BASE_URL + '/claim/list',
  },


  Designation: {
    Base: API_BASE_URL + '/Designation',
    List: API_BASE_URL + '/Designation/list',
  },
  SettlingAgent: {
    Base: API_BASE_URL + '/SettlingAgent',
    List: API_BASE_URL + '/SettlingAgent/list',
  },
  Email: {
    Base: API_BASE_URL + '/EmailSetting',
  },
  EmailNotification: {
    Base: API_BASE_URL + '/EmailNotification',
    List: API_BASE_URL + '/EmailNotification/list'
  },
  Whatsapp: {
    Base: API_BASE_URL + '/WhatsAppNotification',
    List: API_BASE_URL + '/WhatsAppNotification/list',
    Send: API_BASE_URL + '/WhatsAppNotification/SendWhatsApp'
  },
  Department: {
    Base: API_BASE_URL + '/Department',
    List: API_BASE_URL + '/Department/list',
  },
  Pincode: {
    list: API_BASE_URL + '/City/pin-code-list',
  },

  City: {
    Base: API_BASE_URL + '/City',
    List: API_BASE_URL + '/City/list',
    Import: API_BASE_URL + '/City/importData',
  },
  CityType: {
    Base: API_BASE_URL + '/CityType',
    List: API_BASE_URL + '/CityType/list',
    Import: API_BASE_URL + '/CityType/importData',
  },
  Country: {
    Base: API_BASE_URL + '/Country',
    List: API_BASE_URL + '/Country/list',
    Import: API_BASE_URL + '/Country/importData',
    DrpList: API_BASE_URL + '/Country/drplist',
  },
  Currency: {
    Base: API_BASE_URL + '/Currency',
    List: API_BASE_URL + '/Currency/list',
    Import: API_BASE_URL + '/Currency/importData',
  },

  Policy: {
    Base: API_BASE_URL + '/Policy',
    List: API_BASE_URL + '/Policy/list',
    Download: API_BASE_URL + '/Policy/policyPrint',
    InvoiceDownload: API_BASE_URL + '/Policy/policyInvoicePrint',
    Update : API_BASE_URL + '/Policy/policyUpdate'
  },

  ImportPolicy: {
    Base: API_BASE_URL + '/ImportPolicy'
  },

  ExportPolicy: {
    Base: API_BASE_URL + '/ExportPolicy'
  },

  InlandPolicy: {
    Base: API_BASE_URL + '/InlandPolicy'
  },

  CountryPolicy: {
    Base: API_BASE_URL + '/ThirdCountryPolicy'
  },
  ContainerPolicy: {
    Base: API_BASE_URL + '/ContainerPolicy'
  },

  Rate: {
    Base: API_BASE_URL + '/Rate',
    List: API_BASE_URL + '/Rate/list',
  },

  TLRate: {
    Base: API_BASE_URL + '/TLRate',
    List: API_BASE_URL + '/TLRate/list',
  },

  AgentRate: {
    Base: API_BASE_URL + '/AgentRate',
    List: API_BASE_URL + '/AgentRate/list',
    GetRate: API_BASE_URL + '/AgentRate/getAgentRates'
  },

  TLRateMapping: {
    Base: API_BASE_URL + '/TLRateMapping',
    List: API_BASE_URL + '/TLRateMapping/list',
    GetRate: API_BASE_URL + '/TLRateMapping/getTLRates'
  },

  Role: {
    Base: API_BASE_URL + '/Role',
    List: API_BASE_URL + '/Role/list',
    Import: API_BASE_URL + '/Role/importData',
  },

  RoleFeature: {
    list: API_BASE_URL + "/Role/RoleFeature/List",
    put: API_BASE_URL + "/Role/RoleFeature"
  },

  TransitType: {
    list: API_BASE_URL + "/TransitType/list",
    base: API_BASE_URL + "/TransitType"
  },

  featureActivity: {
    base: API_BASE_URL + "/featureactivity",
    list: API_BASE_URL + "/Feature/List",
  },

  Dropdownlist: {
    userDrp: API_BASE_URL + "/user/drplist",
    role: API_BASE_URL + "/Role/drplist",
    features: API_BASE_URL + "/Feature/drplist",
    activity: API_BASE_URL + "/Activity/drplist",
  },

  State: {
    Base: API_BASE_URL + '/State',
    List: API_BASE_URL + '/State/list',
    Import: API_BASE_URL + '/State/importData',
    DrpList: API_BASE_URL + '/State/drplist',
  },

  User: {
    Base: API_BASE_URL + '/User',
    List: API_BASE_URL + '/User/list',
    Import: API_BASE_URL + '/User/importData',
  },

  UserSupportExecutive: {
    Base: API_BASE_URL + '/UserSupportExecutive',
    List: API_BASE_URL + '/UserSupportExecutive/list',
  },

  UserTeamLeader: {
    Base: API_BASE_URL + '/UserTeamLeader',
    List: API_BASE_URL + '/UserTeamLeader/list',
  },

  UserSalesPerson: {
    Base: API_BASE_URL + '/UserSalesPerson',
    List: API_BASE_URL + '/UserSalesPerson/list',
  },

  UserType: {
    List: API_BASE_URL + '/UserType/list',
  },

  StaticList: {
    Commodity: API_BASE_URL + '/Commodity/list',
    TermsOfSale: API_BASE_URL + '/TermsOfSale/list',
    TransitMode: API_BASE_URL + '/TransitMode/list',
    Packaging: API_BASE_URL + '/Packaging/list',
    BasisOfValuation: API_BASE_URL + '/BasisOfValuation/list',
    Coverage: API_BASE_URL + '/CoverageType/list',
    CoverageDetail: API_BASE_URL + '/CoverageDetail/list',
    CompanyType: API_BASE_URL + '/CompanyType/list',
    Product: API_BASE_URL + '/Product/list',
    TransitType: API_BASE_URL + '/TransitType/list',
  }
};
