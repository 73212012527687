// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `angular.json`.

export const environment = {
  production: false,
  apiURL: 'https://testmipapi.zipbooks.in/api', //production server
  apiDomain: 'https://testmipapi.zipbooks.in',
  releaseInfo: {
    api: "0.1",
    ui: "1.2.0"
  }


};

export const settlingagentcountryid = 1;

export const siteKey = "6LfUz8ooAAAAAN6Y6L8q17YHNmZVCdXizSnPc1u9";
// Personal site key : 6LfUz8ooAAAAAN6Y6L8q17YHNmZVCdXizSnPc1u9
// Production site key : 6LfCOssoAAAAAFRnEnHv-2jOVAk4kN-xXuHVZpYM
//Change Recaptcha Site Key for production
