import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'trueyes'
})
export class TrueyesPipe implements PipeTransform {

  transform(text: boolean) {
    if (text == true) return "Yes";
    else return "No";
  }

}
