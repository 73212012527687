import { Component, OnDestroy, OnInit } from '@angular/core';
import { IAppAlert } from "@lib/types";
import { gnxAnimations } from "@lib/ui/animations";
import { Subject, takeUntil } from "rxjs";
import { GnxHeaderAlertService } from "./header-alert.service";
import { Alert } from "@models/common";
import { AlertsService } from "@lib/services/error-handling/alerts.service";

@Component({
  selector: 'gnx-header-alert',
  templateUrl: './header-alert.component.html',
  styleUrls: ['./header-alert.component.scss'],
  animations: gnxAnimations
})
export class HeaderAlertComponent implements OnInit, OnDestroy {

  dismissed: boolean = false;
  canDismiss: boolean = true;

  alert: Alert;

  private destroy$: Subject<any> = new Subject<any>();

  constructor(private alertService: AlertsService) { }

  // -----------------------------------------------------------------------------------------------------
  // @ Lifecycle hooks
  // -----------------------------------------------------------------------------------------------------
  /**
   * On init
   */
  ngOnInit(): void {
    // Subscribe to the alert service
    this.alertService.headerAlerts$
      .pipe(takeUntil(this.destroy$))
      .subscribe((val) => {
        if (val && val.length > 0)  {
          this.alert = val[0];
        } else {
          this.alert = null;
        }
      });
  }


  ngOnDestroy(): void {

    // Unsubscribe from all subscriptions
    this.destroy$.next(null);
    this.destroy$.complete();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  dismiss(): void {
    this.alertService.dismissHeaderAlert();
  }
}
