<div *ngIf="alert" class="gnx-header-alert-wrapper"
[@fadeIn]="!alert"
[@fadeOut]="!alert">

  <!-- Message -->
  <p class="gnx-header-alert-message">
    {{alert.Message}}
  </p>

  <!-- Dismiss button -->
  <button *ngIf="alert && alert.CanDismiss" class="gnx-alert-dismiss-button" mat-icon-button (click)="dismiss()">
    <mat-icon [svgIcon]="'heroicons_solid:x'"></mat-icon>
  </button>
</div>
