import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'active'
})
export class ActivePipe implements PipeTransform {

  transform(text: number) {
    if (text == 1) return "Yes";
    else return "No";
  }

}
