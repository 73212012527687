<div class="flex justify-center h-screen items-center">
  <div class="w-fit min-h-fit sm:w-120 sm:pt-8 sm:min-h-max flex flex-col gap-y-10 text-lg bg-white p-8 shadow-2xl rounded-2xl shadow-gray-700">
    <!-- region header -->
    <div class="">
      <div class="flex justify-center py-7">
        <!-- Brand logo -->
        <img alt="logo"
          [src]="['assets/images/logos/log.svg']"
          class="w-60 self-center"
        />
      </div>

      <div class="w-full flex justify-center py-3">
        <!-- Page Title -->
        <h1 class="title text-3xl text-on-primary-50 mt-0 font-semibold">
          Login
        </h1>
      </div>
      <!-- <div>
      // Login Link
      <p class="text-accent-500 text-sm">
        Not Registered Yet?
        <span
          [routerLink]="['../register']"
          class="text-primary-800 font-semibold ml-1.5 text-sm text-end hover:cursor-pointer"
        >
          Register
        </span>
      </p>
    </div> -->
    </div>
    <!-- endregion header -->

    <form [formGroup]="loginForm" class="flex flex-col gap-6 sm:gap-6 w-full -my-4" (ngSubmit)="login()">
      <!-- Username -->
      <div class="relative my-2">
        <div class="icon">
          <mat-icon>person</mat-icon>
        </div>
        <input
          type="text"
          class="peer"
          name="username"
          id="username"
          placeholder=" "
          formControlName="username"
          autocomplete="off"
        />
        <label for="username" class="mandatory">User Name / Email ID</label>

        <small
          *ngIf="
            f['username'].invalid &&
            f['username'].touched &&
            f['username'].hasError('required')
          "
          class="h-6 w-full text-sm text-warn-500 absolute -bottom-6 left-0 leading-[1px]"
        >
          <gnx-alert
            [alert]="alertCreater('This field is required')"
            [showIcon]="false"
          ></gnx-alert>
        </small>
      </div>

      <!-- Password -->
      <div class="relative flex justify-end items-center my-2">
        <div class="icon">
          <mat-icon>lock</mat-icon>
        </div>
        <input
          [type]="hide ? 'password' : 'text'"
          name="password"
          id="password"
          class="peer"
          placeholder=" "
          formControlName="password"
          autocomplete="off"
        />
        <label for="password" class="mandatory">Password</label>
        <button type="button" class="absolute mr-2" mat-icon-button matSuffix (click)="hide = !hide" [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hide">
          <mat-icon>{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
        </button>

        <small
          *ngIf="
            f['password'].invalid &&
            f['password'].touched &&
            f['password'].hasError('required')
          "
          class="h-6 w-full text-sm text-warn-500 absolute -bottom-6 left-0 leading-[1px]"
        >
          <gnx-alert
            [alert]="alertCreater('This field is required')"
            [showIcon]="false"
          ></gnx-alert>
        </small>
      </div>

      <!-- region forgot password and remind me -->
      <div
      class="flex justify-between items-center"
      [ngClass]="{ 'mt-1.5': f['password'].invalid && f['password'].touched }"
    >
      <p
        [routerLink]="['../reset-password']"
        class="text-accent-500 text-md text-end hover:cursor-pointer -mb-1 ml-2"
      >
        Forgot password?
      </p>
    </div>
      <!-- endregion forgot password and remind me -->

      <!-- region actions or buttons -->
      <div class="text-center">
        <!-- submit login button-->
        <button
          mat-raised-button
          class="w-fit bg-sky-600 text-white disabled:shadow-none self-center"
          [disabled]="loginForm.invalid || submitted"
          type="submit"
        >
          Login
        </button>
      </div>
      <!-- endregion actions or buttons -->
    </form>
    <!-- register link -->
    <div class="py-3 text-center">
      <p>
        Don't have an account?
        <span class="text-blue-700"> <a  [routerLink]="['../register']">Register</a></span>
      </p>
    </div>
  </div>
</div>
