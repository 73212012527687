//The application menu will be fetched from API after the user is successfully logged in
//Till it is done, the menu will be served from this config file

import { GnxMenuItem } from '@models/navigation/gnxMenutem.interface';
export const defaultMenu: GnxMenuItem[] = [
  {
    id: 'landing',
    type: 'group',
    location: 'sidenav',
    children: [
      {
        id: 'dashboard',
        title: 'Home',
        type: 'basic',
        icon: 'heroicons_outline:home',
        path: '/app/home',
      },
    ],
  },
  {
    id: 'policylist',
    title: 'Policy Register',
    type:'basic',
    location: 'sidenav',
    icon:'heroicons_outline:clipboard-list',
    path:'/app/insurance/policylist'
  },

  // {
  //   id: 'insurance',
  //   title: 'Buy Insurance',
  //   type: 'collapsible',
  //   location: 'sidenav',
  //   icon: 'mat_outline:add_shopping_cart',
  //   children: [
  //     {title: "Import Policy",type:'basic',icon:'mat_outline:download', location:'sidenav', id:'importpolicy', path:'/app/insurance/importpolicy'},
  //     {title:"Export Policy", type:'basic',icon:'mat_outline:upload', id: "exportpolicy" , location: 'sidenav' , path:'/app/insurance/exportpolicy'},
  //     // {title:"3rd Country Policy",type:'basic',icon:'mat_outline:flag', location:'sidenav', id:"countrypolicy" , path:'/app/insurance/countrypolicy'},
  //     {title:"Inland Policy",type:'basic',icon:'mat_outline:donut_small', location:'sidenav', id:"inlandpolicy", path:'/app/insurance/inlandpolicy'},
  //     {title:"Container Policy",type:'basic',icon:'mat_outline:nfc', location:'sidenav', id:'containerpolicy', path:'/app/insurance/containerpolicy'}
  //   ],
  // },
  
  // {
  //   id:'claimprocess',
  //   title: 'Claim Process',
  //   type:'basic',
  //   location:'sidenav',
  //   icon:'mat_outline:library_books',
  //   path:'/app/claimprocess'
  // },

  // {
  //   id: 'reports',
  //   title: 'Reports',
  //   type: 'collapsible',
  //   location: 'sidenav',
  //   icon: 'heroicons_outline:document-report',
  //   children:[
  //     {title: "Payment Receipt", type: 'basic', icon:'mat_outline:money', location:'sidenav', id:'reportccavenue',path:'/app/reports/paymentreceipt'},
  //     {title: "Agent Ledger", type: 'basic', icon:'heroicons_outline:document', location:'sidenav', id:'reportagentledger',path:'/app/reports/agentledger'}
  //   ]
  // },
  // {
  //   id: 'settings',
  //   title: 'Settings',
  //   type: 'basic',
  //   location: 'sidenav',
  //   icon: 'mat_outline:settings',
  //   path: '/app/settings',
  // },
];
