<div class="p-4 h-full w-full">
    <!-- region main heading -->
    <h1 class="new-head text-xl px-4 py-2 rounded flex justify-between items-center font-semibold">
        Update Invoice

        <!-- region main heading controls-->
        <div class="flex gap-x-3  items-center text-lg font-normal">
            <span class="text-xl font-semibold">Agent Account Balance : ₹{{this.currentBalance | indCurrency}}</span>
            <button mat-raised-button class="new-button" [disabled]="NotificationRemarksForm.invalid" (click)="submitform()">Save</button>
            <button mat-raised-button class="new-button" (click)="backClicked()" (click)="backClicked()">Cancel</button>
        </div>
        <!-- endregion main heading controls-->
    </h1>
<div class="py-5">
    <form [formGroup]="NotificationRemarksForm" class="w-full text-md">
<div class="grid md:grid-cols-2 lg:grid-cols-3 gap-4">

  <div class="relative">
    <div class="icon">
      <mat-icon> local_atm</mat-icon>
    </div>
    <input type="text" class="peer" id="TotalPremiumAmt" disabled [value]="this.NotificationRemarksForm.get('TotalPremiumAmt').value | indCurrency"/>
    <label for="TotalPremiumAmt" class="mandatory">Total Premium </label>
  </div>
    
  <div class="relative">
    <div class="icon rs">
      ₹
    </div>
    <input type="text" class="peer" id="CustomerTotalPremiumAmt" placeholder=" " formControlName="CustomerTotalPremiumAmt" Onlynumber/>
    <label for="CustomerTotalPremiumAmt" class="mandatory">Invoice Amt. (INR)</label>
  </div>
</div>

</form>
</div>
</div>