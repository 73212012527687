<!-- Wrapper -->
<gnx-progress-bar></gnx-progress-bar>
<gnx-header-alert></gnx-header-alert>
<gnx-toolbar></gnx-toolbar>
<div class="app-main">
  <div class="content-wrapper">
    <!-- this div will make router-outlet's child-component's height full   -->
    <div class="h-full">
      <router-outlet></router-outlet>
    </div>
  </div>
</div>