import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ActivePipe } from './active.pipe';
import { YesNoPipe } from './yes-no.pipe';
import { TrueyesPipe } from './trueyes.pipe';
import { IndCurrencyPipe } from './ind-currency.pipe';
import { PolicyStatusPipe } from './policystatus.pipe';

const pipes = [
  ActivePipe,
  YesNoPipe,
  TrueyesPipe,
  IndCurrencyPipe,
  PolicyStatusPipe
];

@NgModule({
  declarations: pipes,
  exports: pipes,
  imports: [
    CommonModule
  ]
})
export class SharedPipesModule { }
