import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AuthLayoutModule } from "./auth-layout/auth-layout.module";
import { PublicLayoutModule } from "./public-layout/public-layout.module";
import { DefaultLayoutModule } from "./default-layout/default-layout.module";
import { LayoutComponent } from './layout.component';
import { ProgressBarModule } from "../components/progress-bar/progress-bar.module";

@NgModule({
  declarations: [
    LayoutComponent
  ],
  imports: [
    CommonModule,
    AuthLayoutModule,
    PublicLayoutModule,
    DefaultLayoutModule,
    ProgressBarModule
  ]
})
export class LayoutsModule { }
