import { Directive, HostListener } from '@angular/core';

@Directive({
  selector: '[NoSpace]'
})
export class NoSpaceDirective {

  constructor() { }

  @HostListener('keydown', ['$event'])
  onKeyDown(event: KeyboardEvent) {
    if (event.keyCode === 32) {
      event.preventDefault();
    }
  }

}
