import { Component } from '@angular/core';

@Component({
  selector: 'gnx-page-under-construction',
  templateUrl: './page-under-construction.component.html',
  styleUrls: ['./page-under-construction.component.scss']
})
export class PageUnderConstructionComponent {

}
