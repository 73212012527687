import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BalanceComponent } from './balance.component';
import { RouterModule } from '@angular/router';
import { MatButtonModule } from '@angular/material/button';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { SharedPipesModule } from 'src/app/shared/pipes/shared-pipes.module';
import { MatTooltipModule } from '@angular/material/tooltip';

@NgModule({
  declarations: [
    BalanceComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    MatButtonModule,
    MatIconModule,
    MatDividerModule,
    MatMenuModule,
    SharedPipesModule,
    MatTooltipModule
  ],
  exports: [
    BalanceComponent
  ]
})
export class BalanceModule { }
