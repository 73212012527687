import { Subject, takeUntil } from 'rxjs';
import { Component, OnDestroy } from '@angular/core';
import { AuthService } from '@services/auth/auth.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'gnx-version-info',
  templateUrl: './version-info.component.html',
  styleUrls: ['./version-info.component.scss']
})
export class VersionInfoComponent implements OnDestroy {

  ApiVersion: string;
  UiVersion: string;
  destroyed$: Subject<boolean> = new Subject()

  constructor(private _authservice: AuthService){
    this._init()
  }

  ngOnDestroy(): void {
    this.destroyed$.next(true)
    this.destroyed$.complete()
  }

  private _init(){
    this._authservice.user$.pipe(takeUntil(this.destroyed$)).subscribe(res => {
      this.ApiVersion = res?.APIVersion;
    })

    this.UiVersion = environment.releaseInfo.ui;
  }
}
