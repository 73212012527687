<div class="p-4 h-full w-full">
    <!-- region main heading -->
    <h1 class="new-head text-xl px-4 py-2 rounded flex justify-between items-center font-semibold">
        Update Policy

        <!-- region main heading controls-->
        <div class="flex gap-x-3  items-center text-lg font-normal">
            <button mat-raised-button class="new-button" [disabled]="NotificationRemarksForm.invalid" (click)="submitform()">Save</button>
            <button mat-raised-button class="new-button" (click)="backClicked()" (click)="backClicked()">Cancel</button>
        </div>
        <!-- endregion main heading controls-->
    </h1>
<div class="py-5">
    <form [formGroup]="NotificationRemarksForm" class="w-full text-md">
<div class="grid md:grid-cols-2 lg:grid-cols-3 gap-4">
    
  <!-- RR No.  -->
  <div class="relative" *ngIf="this.f['TransitTypeId'].value != 4">
    <div class="icon">
      <mat-icon>poll</mat-icon>
    </div>
    <input type="text" class="peer" id="RRNo" formControlName="RRNo" placeholder=" " />
    <label for="RRNo" *ngIf="this.f['PurchaseTransitModeName'].value == 'Sea' || this.f['SalesTransitModeName'].value == 'Sea'">BL No.</label>
    <label for="RRNo" *ngIf="this.f['PurchaseTransitModeName'].value == 'Air' || this.f['SalesTransitModeName'].value == 'Air'">AWB No.</label>
    <label for="RRNo" *ngIf="this.f['PurchaseTransitModeName'].value == 'Rail' || this.f['SalesTransitModeName'].value == 'Rail'">RR No.</label>
    <label for="RRNo" *ngIf="this.f['PurchaseTransitModeName'].value == 'Road' || this.f['SalesTransitModeName'].value == 'Road'">LR No.</label>
    <label for="RRNo"
      *ngIf="this.f['PurchaseTransitModeName'].value == 'Courier' || this.f['PurchaseTransitModeName'].value == 'Post' || this.f['SalesTransitModeName'].value == 'Courier' || this.f['SalesTransitModeName'].value == 'Post'">Receipt
      No.</label>
  </div>

  <div class="relative"  *ngIf="this.f['TransitTypeId'].value != 4">
    <div class="icon">
      <mat-datepicker-toggle class="-ml-2" matIconSuffix [for]="cker"></mat-datepicker-toggle>
      <mat-datepicker #cker></mat-datepicker>
    </div>
    <div class="flex">
      <input matInput type="text" id="RRDate" formControlName="RRDate" placeholder="__/__/____" datemask
        [matDatepicker]="cker" class="peer mb-1" formControlName="RRDate"/>
      <label for="RRDate" *ngIf="this.f['PurchaseTransitModeName'].value == 'Sea' || this.f['SalesTransitModeName'].value == 'Sea'">BL Date</label>
      <label for="RRDate" *ngIf="this.f['PurchaseTransitModeName'].value == 'Air' || this.f['SalesTransitModeName'].value == 'Air'">AWB Date</label>
      <label for="RRDate" *ngIf="this.f['PurchaseTransitModeName'].value == 'Rail' || this.f['SalesTransitModeName'].value == 'Rail'">RR Date</label>
      <label for="RRDate" *ngIf="this.f['PurchaseTransitModeName'].value == 'Road' || this.f['SalesTransitModeName'].value == 'Road'">LR Date</label>
      <label for="RRDate"
        *ngIf="this.f['PurchaseTransitModeName'].value == 'Courier' || this.f['PurchaseTransitModeName'].value == 'Post' || this.f['SalesTransitModeName'].value == 'Courier' || this.f['SalesTransitModeName'].value == 'Post'">Receipt
        Date</label>
    </div>
  </div>

  <!-- Rail Authority Name -->
  <div class="relative"  *ngIf="this.f['TransitTypeId'].value != 4">
    <div class="icon">
      <mat-icon> directions_railway</mat-icon>
    </div>
    <input type="text" class="peer" id="RailAuthorityName" formControlName="RailAuthorityName"
      placeholder=" " />
    <label for="RailAuthorityName" *ngIf="this.f['PurchaseTransitModeName'].value == 'Sea' || this.f['SalesTransitModeName'].value == 'Sea'">Vessel
      Name</label>
    <label for="RailAuthorityName" *ngIf="this.f['PurchaseTransitModeName'].value == 'Air' || this.f['SalesTransitModeName'].value == 'Air' ">Airlines
      Name</label>
    <label for="RailAuthorityName" *ngIf="this.f['PurchaseTransitModeName'].value == 'Rail' || this.f['SalesTransitModeName'].value == 'Rail'">Railway
      Authority Name</label>
    <label for="RailAuthorityName" *ngIf="this.f['PurchaseTransitModeName'].value == 'Road' || this.f['SalesTransitModeName'].value == 'Road'">Transporter
      Name</label>
    <label for="RailAuthorityName" *ngIf="this.f['PurchaseTransitModeName'].value == 'Courier' || this.f['SalesTransitModeName'].value == 'Courier'">Courier
      Name</label>
    <label for="RailAuthorityName" *ngIf="this.f['PurchaseTransitModeName'].value == 'Post' || this.f['SalesTransitModeName'].value == 'Post'">Postal
      Authority Name</label>
  </div>

  <!-- Buyer Consignee  -->
  <div class="relative">
    <div class="icon">
      <mat-icon>person</mat-icon>
    </div>
    <input type="text" class="peer" id="BuyerConsignee" formControlName="BuyerConsignee"
      placeholder=" " />
    <label for="BuyerConsignee">Buyer / Consignee</label>
  </div>

  <!-- Seller / Consignor -->
  <div class="relative">
    <div class="icon">
      <mat-icon>person</mat-icon>
    </div>
    <input type="text" class="peer" id="SellerConsignor" formControlName="SellerConsignor"
      placeholder=" " />
    <label for="SellerConsignor">Seller / Consignor</label>
  </div>

  <!-- Job No  -->
  <div class="relative">
    <div class="icon">
      <mat-icon>poll</mat-icon>
    </div>
    <input type="text" class="peer" id="JobNo" formControlName="JobNo" placeholder=" " />
    <label for="JobNo">Job No.</label>
  </div>

  <!-- Additional Info  -->
  <div class="relative">
    <div class="icon">
      <mat-icon>info</mat-icon>
    </div>
    <input type="text" class="peer" id="AdditionalInfo" formControlName="AdditionalInfo"
      placeholder=" " />
    <label for="AdditionalInfo">Additional Info</label>
  </div>
</div>

</form>
</div>
</div>