<div class="flex justify-center items-center my-4">
  <div *ngIf="submitted == false"
    class="w-fit min-h-fit sm:w-200 sm:pt-8 sm:min-h-max flex flex-col gap-y-10 text-lg bg-white p-8 shadow-2xl rounded-2xl shadow-gray-700">
    <!-- region header -->
    <div class="">
      <div class="flex justify-center py-7">
        <!-- Brand logo -->
        <img alt="logo" [src]="['assets/images/logos/log.svg']" class="w-60 self-center" />
      </div>

      <div class="w-full flex justify-center py-3">
        <!-- Page Title -->
        <h1 class="title text-3xl text-on-primary-50 mt-0 font-semibold">
          Agent Registration
        </h1>
      </div>
    </div>
    <!-- endregion header -->

    <form [formGroup]="registerForm" class="flex flex-col gap-6 sm:gap-6 w-full -my-4" (ngSubmit)="register()">
      <div class="my-2 items-center">
        <span class="pr-2 py-1 font-medium after:content-['*'] after:ml-1 after:text-red-500" for="AgentType">Agent
          Type</span>
        <mat-radio-group formControlName="Type" (change)="onChange($event, 'AgentType')">
          <mat-radio-button value="Individual" name="AgentType"></mat-radio-button>
          <span>Individual</span>
          <mat-radio-button value="Company" name="AgentType"></mat-radio-button>
          <span>Company</span>
        </mat-radio-group>
      </div>
      <!-- Agent Name -->
      <div class="grid grid-cols-3 gap-4" *ngIf="f['Type'].value == 'Individual'">
        <div class="relative my-2 col-span-1">
          <div class="icon">
            <mat-icon>person</mat-icon>
          </div>
          <input type="text" class="peer" name="FirstName" id="FirstName" placeholder=" " formControlName="FirstName"
            autocomplete="off" />
          <label for="FirstName" class="mandatory">First Name</label>
        </div>
        <div class="relative my-2 col-span-1">
          <div class="icon">
            <mat-icon>person</mat-icon>
          </div>
          <input type="text" class="peer" name="MiddleName" id="MiddleName" placeholder=" " formControlName="MiddleName"
            autocomplete="off" />
          <label for="MiddleName">Middle Name</label>
        </div>
        <div class="relative my-2 col-span-1">
          <div class="icon">
            <mat-icon>person</mat-icon>
          </div>
          <input type="text" class="peer" name="LastName" id="LastName" placeholder=" " formControlName="LastName"
            autocomplete="off" />
          <label for="LastName" class="mandatory">Last Name</label>
        </div>
      </div>
      <div class="relative my-2" *ngIf="f['Type'].value == 'Company'">
        <div class="icon">
          <mat-icon>business_center</mat-icon>
        </div>
        <input type="text" class="peer" name="NameOfEntity" id="NameOfEntity" placeholder=" "
          formControlName="NameOfEntity" autocomplete="off" />
        <label for="NameOfEntity" class="mandatory">Name of Entity</label>
      </div>

      <div class="grid gap-4 grid-cols-2">
        <div class="relative my-2">
          <div class="relative col-span-1">
          <div class="icon">
            <mat-icon>email</mat-icon>
          </div>
          <input type="text" class="peer" name="EmailId" id="EmailId" placeholder=" " formControlName="EmailId"
            autocomplete="off" />
          <label for="EmailId" class="mandatory">Email ID</label>

          <small *ngIf="
              f['EmailId'].invalid &&
              f['EmailId'].touched &&
              f['EmailId'].hasError('required')
            " class="h-6 w-full text-sm text-warn-500 absolute -bottom-6 left-0 leading-[1px]">
            <gnx-alert [alert]="alertCreater('Email is required')" [showIcon]="false"></gnx-alert>
          </small>
          <small *ngIf="
            f['EmailId'].invalid &&
            f['EmailId'].touched &&
            f['EmailId'].hasError('email')
          " class="h-6 w-full text-sm text-warn-500 absolute -bottom-6 left-0 leading-[1px]">
            <gnx-alert [alert]="alertCreater('Enter Valid Email')" [showIcon]="false"></gnx-alert>
          </small>
        </div>
        </div>
        </div>
        <div class="grid gap-4 grid-cols-2">

        <!-- Mobile No  -->
        <div class="relative my-2">
          <div class="icon">
            <mat-icon>phone</mat-icon>
          </div>
          <input type="text" class="peer" name="MobileNo" id="MobileNo" placeholder=" " formControlName="MobileNo"
            maxlength="10" minlength="10" Onlynumber autocomplete="off" (blur)="copynumber()" />
          <label for="MobileNo" class="mandatory">Mobile</label>

          <small *ngIf="
              f['MobileNo'].invalid &&
              f['MobileNo'].touched &&
              f['MobileNo'].hasError('required')
            " class="h-6 w-full text-sm text-warn-500 absolute -bottom-6 left-0 leading-[1px]">
            <gnx-alert [alert]="alertCreater('This field is required')" [showIcon]="false"></gnx-alert>
          </small>
        </div>

        <div class="relative my-2">
          <div class="icon">
            <mat-icon>phone</mat-icon>
          </div>
          <input type="text" class="peer" name="WhatsAppNo" id="WhatsAppNo" placeholder=" " formControlName="WhatsAppNo"
            maxlength="10" minlength="10" Onlynumber autocomplete="off" />
          <label for="WhatsAppNo" class="mandatory">Whatsapp</label>

          <small *ngIf="
              f['WhatsAppNo'].invalid &&
              f['WhatsAppNo'].touched &&
              f['WhatsAppNo'].hasError('required')
            " class="h-6 w-full text-sm text-warn-500 absolute -bottom-6 left-0 leading-[1px]">
            <gnx-alert [alert]="alertCreater('This field is required')" [showIcon]="false"></gnx-alert>
          </small>
        </div>
      </div>

      <!-- Password -->
      <div class="grid grid-cols-2 gap-4">
        <div class="relative flex justify-end items-center my-2">
          <div class="icon">
            <mat-icon>lock</mat-icon>
          </div>
          <input [type]="hide ? 'password' : 'text'" name="Password" id="Password" class="peer" placeholder=" "
            formControlName="Password" autocomplete="off" />
          <label for="Password" class="mandatory">Password</label>
          <button type="button" class="absolute mr-2" mat-icon-button matSuffix (click)="hide = !hide"
            [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hide">
            <mat-icon>{{ hide ? "visibility_off" : "visibility" }}</mat-icon>
          </button>

          <small *ngIf="
              f['Password'].invalid &&
              f['Password'].touched &&
              f['Password'].hasError('required')
            " class="h-6 w-full text-sm text-warn-500 absolute -bottom-6 left-0 leading-[1px]">
            <gnx-alert [alert]="alertCreater('This field is required')" [showIcon]="false"></gnx-alert>
          </small>
        </div>

        <div class="relative flex justify-end items-center py-2">
          <div class="icon">
            <mat-icon>lock</mat-icon>
          </div>
          <input [type]="hideconfirm ? 'password' : 'text'" name="confirmPassword" id="confirmPassword" class="peer"
            placeholder=" " formControlName="confirmPassword" autocomplete="off" />
          <label for="confirmPassword" class="mandatory">Confirm Password</label>
          <button type="button" class="absolute mr-2" mat-icon-button matSuffix (click)="hideconfirm = !hideconfirm"
            [attr.aria-label]="'Hide confirm password'" [attr.aria-pressed]="hideconfirm">
            <mat-icon>{{
              hideconfirm ? "visibility_off" : "visibility"
              }}</mat-icon>
          </button>
          <small *ngIf="
              f['confirmPassword'].invalid &&
              f['confirmPassword'].touched &&
              f['confirmPassword'].hasError('required')
            " class="h-6 w-full text-sm text-warn-500 absolute -bottom-6 left-0 leading-[1px]">
            <gnx-alert [alert]="alertCreater('This field is required')" [showIcon]="false"></gnx-alert>
          </small>
          <small *ngIf="registerForm.errors?.mismatch && f['confirmPassword'].touched"
            class="h-6 w-full text-sm text-warn-500 absolute -bottom-6 left-0 leading-[1px]">
            <gnx-alert [alert]="alertCreater('Passwords do not match')" [showIcon]="false"></gnx-alert>
          </small>
        </div>
      </div>

      <div class="text-center py-2">
        <re-captcha class="inline-block" formControlName="recaptchaReactive"
          [siteKey]="sitekey"></re-captcha>
      </div>

      <!--
        Personal site key : 6LfUz8ooAAAAAN6Y6L8q17YHNmZVCdXizSnPc1u9
        Production site key : 6LfCOssoAAAAAFRnEnHv-2jOVAk4kN-xXuHVZpYM
      -->
      <!-- region actions or buttons -->
      <div class="text-center">
        <!-- submit login button-->
        <button mat-raised-button class="w-fit bg-sky-600 text-white disabled:shadow-none self-center"
          [disabled]="registerForm.invalid || submitted" (click)="ekyc()">
          Next
        </button>
      </div>
      <!-- endregion actions or buttons -->
    </form>
    <!-- register link -->
    <div class="py-3 text-center">
      <p>
        Already have an account?
        <span class="text-blue-700">
          <a [routerLink]="['../login']">Login</a></span>
      </p>
    </div>
  </div>
  <div *ngIf="submitted"
    class="w-fit min-h-fit sm:w-200 sm:pt-8 sm:min-h-max flex flex-col gap-y-10 text-lg bg-white p-8 shadow-2xl rounded-2xl shadow-gray-700">
    <!-- region header -->
    <div class="">
      <div class="flex justify-center py-7">
        <!-- Brand logo -->
        <img alt="logo" [src]="['assets/images/logos/log.svg']" class="w-60 self-center" />
      </div>

      <div class="w-full flex justify-center py-3">
        <!-- Page Title -->
        <h1 class="title text-3xl text-on-primary-50 mt-0 font-semibold">
          E-KYC
        </h1>
      </div>
    </div>
    <!-- endregion header -->

    <h1 class="new-head text-lg px-4 py-1.5 rounded flex justify-between items-center font-semibold" *ngIf="this.f['Type'].value == 'Company'">
      Company Details
    </h1>
    <h1 class="new-head text-lg px-4 py-1.5 rounded flex justify-between items-center font-semibold" *ngIf="this.f['Type'].value == 'Individual'">
      Individual Details
    </h1>
    <form [formGroup]="registerForm" class="flex flex-col gap-6 sm:gap-6 w-full -my-4" (ngSubmit)="register()">
      <!-- City Name -->
      <div class="grid grid-cols-3 gap-4">
        <div class="relative my-1 col-span-1">
          <div class="icon">
            <mat-icon>credit_card</mat-icon>
          </div>
          <input type="text" class="peer" name="GSTNo" NoSpace id="GSTNo" placeholder=" " formControlName="GSTNo" maxlength="15"
            autocomplete="off" (focusout)="checkgst()"/>
          <label for="GSTNo">GSTIN</label>
          <small *ngIf="
          f['GSTNo'].invalid &&
          f['GSTNo'].touched &&
          f['GSTNo'].hasError('pattern')
        " class="h-6 w-full text-sm text-warn-500 absolute -bottom-6 left-0 leading-[1px]">
            <gnx-alert [alert]="alertCreater('Enter Valid GST Number')" [showIcon]="false"></gnx-alert>
          </small>
        </div>
        <div class="relative my-1 col-span-1">
          <div class="icon">
            <mat-icon>credit_card</mat-icon>
          </div>
          <input type="text" class="peer" name="PANNo" NoSpace id="PANNo" placeholder=" " maxlength="10" formControlName="PANNo"
            autocomplete="off" (focusout)="checkpan()"/>
          <label for="PANNo" class="mandatory">PAN</label>
          <small *ngIf="
            f['PANNo'].invalid &&
            f['PANNo'].touched &&
            f['PANNo'].hasError('required')
          " class="h-6 w-full text-sm text-warn-500 absolute -bottom-6 left-0 leading-[1px]">
            <gnx-alert [alert]="alertCreater('PAN Number is required')" [showIcon]="false"></gnx-alert>
          </small>
          <small *ngIf="
          f['PANNo'].invalid &&
          f['PANNo'].touched &&
          f['PANNo'].hasError('pattern')
        " class="h-6 w-full text-sm text-warn-500 absolute -bottom-6 left-0 leading-[1px]">
            <gnx-alert [alert]="alertCreater('Enter Valid PAN Number')" [showIcon]="false"></gnx-alert>
          </small>
        </div>

        <div class="relative my-1 col-span-1">
          <div class="icon">
            <mat-icon>business_center</mat-icon>
          </div>
          <input type="text" class="peer" name="LegalName" id="LegalName" placeholder=" " formControlName="LegalName"
            autocomplete="off" />
          <label for="LegalName" class="mandatory">Legal Name</label>
        </div>

       

        
      <!-- </div> -->

      <!-- <div class="grid grid-cols-3 gap-4"> -->
        <div class="relative my-1 col-span-1" *ngIf="registerForm.get('Type').value == 'Company'">
          <div class="icon">
            <mat-icon>category</mat-icon>
          </div>
          <div class="flex">
            <input type="text" class="peer" name="CompanyTypeName" id="CompanyTypeName" placeholder=" "
              formControlName="CompanyTypeName" [matAutocomplete]="CompanySearch" />
            <mat-autocomplete class="peer" #CompanySearch="matAutocomplete" (optionSelected)="CompanySelected($event)">
              <mat-option *ngFor="let Company of Companies$ | async" [value]="Company">
                {{ Company.Companytype }}
              </mat-option>
            </mat-autocomplete>
            <button class="-m-5 z-10" type="button" (click)="openDiolog('Company','Select Company Type')"><mat-icon
                class="-ml-2">search</mat-icon></button>
            <button *ngIf="registerForm.get('CompanyTypeName').value" type="button" class="-m-7 z-10" aria-label="clear"
              matTooltip="Clear" (click)="clear('CompanyTypeName','CompanyTypeId')">
              <mat-icon>clear</mat-icon>
            </button>
            <label for="CompanyTypeName" class="mandatory">Company Type</label>
          </div>
        </div>
      </div>

        <div class="relative my-1">
          <div class="icon">
            <mat-icon>place</mat-icon>
          </div>
          <textarea type="text" class="peer" name="Address" id="Address" placeholder=" " formControlName="Address"
            autocomplete="off"></textarea>
          <label for="Address" class="mandatory">Address</label>
        </div>
        <!-- <div class="relative my-1 col-span-1">
          <div class="icon">
            <mat-icon>business_center</mat-icon>
          </div>
          <input type="text" class="peer" name="CompanyName" id="CompanyName" placeholder=" "
            formControlName="CompanyName" autocomplete="off" />
          <label for="CompanyName" *ngIf="registerForm.get('Type').value == 'Company'" class="mandatory">Company
            Name</label>
          <label for="CompanyName" *ngIf="registerForm.get('Type').value == 'Individual'" class="mandatory">Name</label>
        </div> -->
      <div class="grid grid-cols-3 gap-4">
      <!-- </div> -->
      <!-- Password -->
      <!-- <div class="grid grid-cols-3 gap-4"> -->
        <div class="relative my-1 col-span-1">
          <div class="icon">
            <mat-icon>place</mat-icon>
          </div>
          <div class="flex">
            <input type="text" class="peer" formControlName="CityName" placeholder=" " id="CityName"
              [matAutocomplete]="CitySearch" />
            <mat-autocomplete #CitySearch="matAutocomplete" (optionSelected)="CitySelected($event)">
              <mat-option *ngFor="let City of Cities$ | async" [value]="City">
                {{ City.Name }}
              </mat-option>
            </mat-autocomplete>
            <button class="-m-5 -mr-2 z-10" *ngIf="registerForm.get('CityName').enabled" type="button" (click)="openDiolog('City', 'Select City')">
              <mat-icon class="-ml-2">search</mat-icon>
            </button>
            <button *ngIf="registerForm.get('CityName').value && registerForm.get('CityName').enabled" type="button" class="-m-10 z-10" aria-label="clear"
              matTooltip="Clear" (click)="clear('CityName', 'CityId')">
              <mat-icon>clear</mat-icon>
            </button>
            <label for="CityName" class="mandatory">City</label>
          </div>
        </div>

        <!-- State  -->
        <div class="relative my-1 col-span-1">
          <div class="icon">
            <mat-icon>place</mat-icon>
          </div>
          <div class="flex">
            <input type="text" class="peer" readonly formControlName="StateName" placeholder=" " id="StateName" />
            <label for="StateName" class="mandatory">State</label>
          </div>
        </div>

        <div class="relative my-1 col-span-1">
          <div class="icon">
            <mat-icon>pin_drop</mat-icon>
          </div>
          <div class="flex">
            <input type="text" class="peer" name="Pincode" id="Pincode" Onlynumber maxlength="6" placeholder=" "
              formControlName="Pincode" [matAutocomplete]="PinCodeSearch" />
            <mat-autocomplete class="peer" #PinCodeSearch="matAutocomplete" (optionSelected)="PinCodeSelected($event)">
              <mat-option *ngFor="let pincode of pincodes$ | async" [value]="pincode">
                {{ pincode.PinCode }} {{ pincode.CityName }}
              </mat-option>
            </mat-autocomplete>
            <button class="-m-5 z-10" *ngIf="registerForm.get('Pincode').enabled" type="button" (click)="openDiolog('Pincode','Select Pincode')"><mat-icon
                class="-ml-2">search</mat-icon></button>
            <button *ngIf="registerForm.get('Pincode').value && registerForm.get('Pincode').enabled" type="button" class="-m-7 z-10" aria-label="clear"
              matTooltip="Clear"  (click)="pincodeclear()">
              <mat-icon>clear</mat-icon>
            </button>
            <label for="Pincode" class="mandatory">Pin Code</label>
          </div>
        </div>

        <!-- Country  -->

        <!-- <div class="relative my-1 col-span-1">
          <div class="icon">
            <mat-icon>place</mat-icon>
          </div>
          <div class="flex">
            <input type="text" class="peer" readonly formControlName="CountryName" placeholder=" " id="CountryName" />
            <label for="CountryName" class="mandatory">Country</label>
          </div>
        </div> -->

      </div>



      <h1 class="new-head text-lg px-4 py-1.5 rounded flex justify-between items-center font-semibold">
        KYC Details
      </h1>

      <div class="container">
        <mat-sidenav-container fxFlex="0 1 auto">
          <mat-sidenav-content>
            <table class="w-120 py-5 block md:table rounded-lg  sm:shadow " mat-table #table
              [dataSource]="DocumentDataSource" aria-hidden="true">
              <thead class="block md:table-header-group">
                <tr class="flex border">
                  <!-- Name -->

                  <ng-container matColumnDef="DocumentType">
                    <th mat-header-cell *matHeaderCellDef class="text-left block md:table-cell text-white new-head">
                      Document Type
                    </th>

                    <td class="md:table-cell border" *matCellDef="let element; let i = index" [formGroup]="element">
                      <div class="h-20 mt-3 p-1">
                        <span class="w-36 md:hidden font-bold">Document Type</span>
                        <!-- {{element.DocumentType}} -->
                        <input formControlName="DocumentType" class="p-1 inp" readonly type="text" />
                      </div>
                    </td>
                  </ng-container>

                  <!-- Relation  -->
                  <ng-container matColumnDef="Attachment">
                    <th mat-header-cell *matHeaderCellDef class="text-left block md:table-cell text-white new-head">
                      Attachment
                    </th>

                    <td
                    class="md:table-cell border"
                    *matCellDef="let element; let i = index"
                    [formGroup]="element"
                  >
                    <div class="mt-5 p-1">
                      <span class="w-36 md:hidden font-bold">Attachment</span>
                      <div class="flex flex-row">
                      <div>
                        <input type="file" [id]="'file'+i" hidden class="inp" accept=".jpg, .jpeg, .png, .gif, .bmp, .heic, .zip, .rar, .pdf," #attachment (change)="attachmentUpload(i,attachment.files)"/>
                        <button mat-icon-button type="button" (click)="attachFile(i)">
                          <mat-icon color="primary" class="">attach_file</mat-icon>
                        </button>
                      </div>
                      <div *ngIf="element.value.AttachmentDetail.FileName">
                        <span class="w-36 md:hidden font-bold">Attachment</span>
                        <button mat-icon-button type="button" (click)="downloadFile(i)">
                          <mat-icon color="primary" class="">cloud_download</mat-icon>
                        </button>
                      </div>
                      </div>
                      <span>{{element.value.AttachmentDetail.FileName}}</span>
                    </div>
                  </td>
                  </ng-container>

                  <!-- Action  -->
                  <ng-container matColumnDef="Action">
                    <th mat-header-cell *matHeaderCellDef class="text-left block md:table-cell text-white new-head">
                      Action
                    </th>

                    <td class="md:table-cell px-1 justify-center border" *matCellDef="let element; let i = index"
                      [formGroup]="element">
                      <div class="h-20">
                        <span class="md:hidden mx-1 font-bold">Action</span>
                        <button mat-icon-button type="button" matTooltip="Remove" (click)="deleteAttachment(i)">
                          <mat-icon color="warn" class="pt-2">delete</mat-icon>
                        </button>
                      </div>
                    </td>
                  </ng-container>
                </tr>
              </thead>

              <!-- Header & body  -->
              <tr class="max-md:hidden" mat-header-row *matHeaderRowDef="DisplayedColumns"></tr>
              <tr mat-row *matRowDef="let row; let i = index; columns: DisplayedColumns" [class.hidden]="!checkcompany(row)"></tr>
              <tr *matNoDataRow>
                <td colspan="9999">No data to display</td>
              </tr>
            </table>
          </mat-sidenav-content>
        </mat-sidenav-container>
      </div>
      <div class="mt-2 mx-3 flex justify-end bg-white">
        <h1>Note*: Only .jpg, .jpeg, .png, .gif, .bmp, .heic, .zip, .rar and .pdf Formats are Allowed!
        </h1>
      </div>

      <div class="mt-2 mx-3 flex justify-center text-center bg-white">
        <input type="checkbox" class="mt-1 mx-3" formControlName="TermsAndConditions">I agree to these <span (click)="termsandconditions()" class="mx-1 text-primary-500"> Terms And Conditions</span>
      </div>
      <!-- region actions or buttons -->
      <div class="text-center">

        <button mat-raised-button class="w-fit bg-sky-600 text-white disabled:shadow-none self-center mx-2"
          type="button" (click)="back()">
          Back
        </button>
        <!-- submit login button-->
        <button mat-raised-button class="w-fit bg-sky-600 text-white disabled:shadow-none self-center mx-2"
          [disabled]="registerForm.invalid" type="submit">
          Submit
        </button>
      </div>

      <div class="py-3 text-center">
        <p>
          Already have an account?
          <span class="text-blue-700">
            <a [routerLink]="['../login']">Login</a></span>
        </p>
      </div>
      <!-- endregion actions or buttons -->
    </form>
  </div>
</div>