import { HttpClient } from "@angular/common/http";
import { Injectable } from '@angular/core';
import { Router } from "@angular/router";
import { BehaviorSubject, Observable, of, retry, tap, map, catchError, throwError, switchMap } from "rxjs";
import { LocalStorageService } from "src/app/lib/services/local-storage.service";
import { API_ENDPOINTS } from "@config/api-endpoints.config";
import { httpOptions } from "@config/httpOptions";
import { LoginDto, RegisterDto, IUserProfile } from "@models/dtos/auth";
import { ResponseMessage } from "@models/common";
import { AgentDto } from "@models/dtos/core/agent-dto";
import { OtpDto } from "@models/dtos/auth/OtpDto";
import { ResetPasswordDto } from "@models/dtos/auth/ResetPasswordDto";

const LoginAPI: string = API_ENDPOINTS.Auth.Login;
const RegisterAPI: string = API_ENDPOINTS.Agent.Register;
const sendOtpAPI: string = API_ENDPOINTS.Auth.SendOtp;
const resetPasswordAPI: string = API_ENDPOINTS.Auth.resetPassword;
const UserProfileAPI: string = API_ENDPOINTS.Auth.UserProfile;
@Injectable({
  providedIn: 'root'
})
export class AuthService {
  public _userProfile: BehaviorSubject<IUserProfile>;
  public user$: Observable<IUserProfile>;
  private _token: string;

  // user$: Observable<UserProfile>;
  userProfile$: Observable<IUserProfile>;

  constructor(private http: HttpClient,
    private router: Router,
    private ls: LocalStorageService) {
    this._init();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Property accessors
  // -----------------------------------------------------------------------------------------------------

  public refreshUser() {
    return this.http.get<ResponseMessage>(UserProfileAPI, httpOptions)
    .pipe(tap((res:any) => {
      if (res.Success) {
        let profile = res.Data as IUserProfile;
        this._userProfile.next(profile);
        return res;
      } else {
        this._userProfile.next(null);
        throw new Error("Failed to fetch user profile");
      }
    }));
  }

  public get isLoggedIn$(): Observable<boolean> {
    //If token does not exist, user is not logged in, return false
    if (!this._token) {
      return of(false);
    }

    // if user profile is available, no further action required return true;
    if (this._userProfile.value !== null) {
      return of(true);
    }

    //Re-aquire user profile as it is lost due to page refresh by user
    return this.http.get<ResponseMessage>(UserProfileAPI, httpOptions)
      .pipe(
        retry(2),
        map((res) => {
          if (res.Success) {
            let profile = res.Data as IUserProfile;
            this._userProfile.next(profile);
            return true;
          } else {
            this._userProfile.next(null);
            throw new Error("Failed to fetch user profile");
          }
        }),
        catchError(() => { return of(false) })
      );
  }


  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  login(loginDto: LoginDto): Observable<ResponseMessage> {
    return this.http.post<ResponseMessage>(LoginAPI, loginDto, httpOptions)
      .pipe(tap((res:any) => {
        if (res && res.Success) {
          if (res.Data?.Token) {
            this._token = res.Data.Token;
            // this._userProfile.next(res.Data.userProfile)
            this.ls.setItem("token", res.Data.Token);
            //Redirect to App Home page
            this.router.navigate(['/app/home']);
          }
        } else {
          this.ls.removeItem("token");
          this._token = ''
        }
      }));
  }

  sendotp(otpDto: OtpDto): Observable<ResponseMessage> {
    return this.http.post<ResponseMessage>(sendOtpAPI, otpDto, httpOptions)
    .pipe(tap((res:any) => {}));
  }

  resetpassword(resetPasswordDto: ResetPasswordDto): Observable<ResponseMessage> {
    return this.http.post<ResponseMessage>(resetPasswordAPI, resetPasswordDto, httpOptions)
    .pipe(tap((res:any) => {}));
  }

  public isAuthorized$(key: string): Observable<boolean> {
    if (this._userProfile.value && this._userProfile.value.AuthKeys) {
      return of(this._userProfile.value.AuthKeys.some(x => x.toLowerCase() == key.toLowerCase()));
    } else {
      return this.isLoggedIn$.pipe(
        switchMap((res) => {
          if (res) {
            return of(this._userProfile.value.AuthKeys.some(x => x.toLowerCase() == key.toLowerCase()));
          } else {
            return of(false);
          }
        })
      );
    }
  }

  //Do not allow this, if user already logged in. Either programmatically
  //logout the user or give error message
  register(registerDto: AgentDto): Observable<ResponseMessage> {

    if (this._token || this._userProfile) {
      throwError(() => 'User already signed-in. Signout first before registering a new account');
    }

    return this.http.post<ResponseMessage>(RegisterAPI, registerDto, httpOptions)
      .pipe(tap((res) => {
        if (res && res.Success) {
          if (res.Data?.Token) {
            this._token = res.Data.Token
            this._userProfile.next(res.Data.userProfile)
            this.ls.setItem("token", res.Data.Token);
          }
        }
      }));
  }

  signOut(): void {
    this.ls.removeItem("token");
    this._token = ''
    this._userProfile.next(null);
    this.router.navigate(['']);
    //Redirect to landing page/auth login page
  }


  // -----------------------------------------------------------------------------------------------------
  // @ Private methods
  // -----------------------------------------------------------------------------------------------------

  private _init(): void {
    this._userProfile = new BehaviorSubject(null);
    this.userProfile$ = this._userProfile.asObservable();

    //Dummy code
    // let sampleUser = DummyProfie;
    // this._userProfile.next(sampleUser);
    this.user$ = this._userProfile.asObservable();

    // set token from local storage
    if (this.ls.getItem("token")) {
      this._token = this.ls.getItem("token")
    }
  }
}
