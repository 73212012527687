import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TableListComponent } from './table-list.component';
import { RouterModule } from '@angular/router';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule } from '@angular/forms';
import { SharedPipesModule } from "../../../../shared/pipes/shared-pipes.module";
import { MatRadioModule } from '@angular/material/radio';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatTooltipModule } from '@angular/material/tooltip';
import {ClipboardModule} from '@angular/cdk/clipboard';

@NgModule({
    declarations: [
        TableListComponent
    ],
    exports: [
        TableListComponent
    ],
    providers: [],
    imports: [
        CommonModule,
        RouterModule,
        HttpClientModule,
        FormsModule,
        MatRadioModule,
        SharedPipesModule,
        MatCheckboxModule,
        MatTooltipModule,
        ClipboardModule
    ]
})
export class TableListModule { }
