// #region imports
import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AlertsService } from '@lib/services/error-handling/alerts.service';
import { IAppAlert } from '@lib/types';
import { AuthService } from '@services/auth/auth.service';
import { Subject, takeUntil } from "rxjs";
// #endregion imports

@Component({
  selector: 'gnx-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit, OnDestroy{
  // #region public variables
  submitted: boolean;
  hide = true;

  // FormGroup
  loginForm: FormGroup;

  //Error Message
  error: IAppAlert = {
    message: '',
    type: 'error',
    canDismiss: false,
    appearance: 'outline',
  };
  // #endregion public variables

  private _returnUrl: string;
  private _destroy$: Subject<any>;
  /**
   * #region constructor
   * @param _fb : formbuilder
   * @param _router : router module
   * @param _authservice : authservice
   * @param _alertservice : alertservice for dynamic rendering of errors messages
   */
  constructor(
    private _fb: FormBuilder,
    private _router: Router,
    private _authservice: AuthService,
    private _route: ActivatedRoute,
    private _alertservice: AlertsService
  ) {
    this.submitted = false;
    this._initForm();
    this._destroy$ = new Subject();
  }
  // #endregion constructor

  //#region getters
  get f() {
    return this.loginForm.controls;
  }
  //#endregion getters

  ngOnInit(): void {
    this._returnUrl = this._route.snapshot.queryParams['returnUrl'] || '/app/home';
  }

  ngOnDestroy(): void {
    this._destroy$.next(null);
    this._destroy$.complete();
  }
  /**
   * #region public methods
   */
  public dismissError() {
    this.error.message = '';
  }

  redirectToHome(){
    this._router.navigate(['home'])
  }

  public login() {
    if (this.loginForm.invalid) {
      return;
    }

    this.submitted = true;

    this._authservice
      .login(this.loginForm.value)
      .pipe(takeUntil(this._destroy$))
      .subscribe({
        next: (res:any) => {
          if (res.Success) {
              this._authservice.isLoggedIn$.subscribe((res2) => {
                if(res2){
                  this._router.navigate([this._returnUrl]);
                }
              })
    
            //Redirect
          } else {
            // handle alerts here
            this._alertservice.raiseErrors(res.Alerts, true);
          }
          this.submitted = false;
        },
        error: (error) => {
          this.submitted = false;
        }
      });
  }

  // creates alerts for login form view
  alertCreater(msg: string) {
    let alert: IAppAlert = {
      message: msg,
      type: 'error',
      appearance: 'soft',
      canDismiss: false,
      showIcon: false,
    };
    return alert;
  }
  // #endregion public methods

  /**
   * #region private methods
   */
  //Todo: Remove hard-coded credentials once the app is stable
  private _initForm(): void {
    this.loginForm = this._fb.group({
      username: ['', [Validators.required]],
      password: ['', [Validators.required]],
    });
  }
  // #endregion private methods
}
