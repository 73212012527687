import { Component, Inject } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AlertsService } from '@lib/services/error-handling/alerts.service';
import { HelperService } from '@lib/services/helper.service';
import { CityDto, CityPincodeDto, ICityDto, ICityPincodeDto } from '@models/dtos/core';
import { MatTableDataSource } from '@angular/material/table';
import { API_ENDPOINTS } from '@config/api-endpoints.config';
import { HttpService } from '@lib/services/http/http.service';
import { StatusOptions } from '@config/status.config';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'gnx-remarks',
  templateUrl: './remarks.component.html',
  styleUrls: ['./remarks.component.scss']
})
export class RemarksComponent {

  // #region public variables

  // Strings
  title: string = '';
  Code: string;
  apiEndPoint: string;

  // FormGroup
  RemarksForm: FormGroup;


  editable: boolean;
  // Errors
  errors: unknown;

  stateList;

  // #endregion public variables

  /**
   * #region constructor
   * @param _location : used for back or prev page navigation
   * @param _fb : Formbuilder
   * @param _router: module for routing
   * @param _route: used to get current route
   */
  constructor(
    private _fb: FormBuilder,
    private _dataService:HttpService,
    private _router: Router,
    private _route: ActivatedRoute,
    private _alertservice: AlertsService,
    public _helperservice: HelperService,
    public dialogRef: MatDialogRef<RemarksComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      type: string;
      title: string;
      isMultiple: boolean;
      data?: any;
    }
  ) {
  }


  ngOnInit(): void {
    let data = this._route.snapshot.data;
    this.apiEndPoint = data['apiEndPoint'];
    this.title = data['title'];
    this.editable = true;
    this._init();

  }
  // #endregion constructor

  // #region getters

  get f() {
    return this.RemarksForm.controls;
  }

  // #endregion getters

  /**
   * #region public methods
   */

  // submit or save action
  submitform = () => {
              this.dialogRef.close(this.RemarksForm.get('Remarks').value);
            }

  // previous page navigation button
  public backClicked() {
    this.dialogRef.close();
  }

  // #endregion public methods

  /**
   * #region private methods
   */

  private _init(): FormGroup {
    this.RemarksForm = this._fb.group({
      Remarks: ['', [Validators.required]],
    });
    return this.RemarksForm;
  }
}
