import { Injectable } from '@angular/core';
import { IAppAlert } from "@lib/types";
import { Alert } from "@models/common";
import { BehaviorSubject, EMPTY, Observable, of } from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class GnxHeaderAlertService {

  private headerAlerts: BehaviorSubject<Alert[]> = new BehaviorSubject([]);
  headerAlerts$: Observable<Alert[]>;

  constructor() {
    this.headerAlerts$ = this.headerAlerts.asObservable();
   }

  addHeaderAlert(alert: Alert): void {
    if (alert && alert.Level === 'header') {
      let alerts = this.headerAlerts.value;
      alerts.push(alert);
      this.headerAlerts.next(alerts);
    }
  }

  dismissHeaderAlert(): void {
    //Removes the top level alert
    let alerts = this.headerAlerts.value;
    if (alerts && alerts.length > 0) {
      alerts.splice(0, 1);
      this.headerAlerts.next(alerts);
    }
  }
}
