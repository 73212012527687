import { AfterViewInit, AfterContentChecked, ChangeDetectorRef, Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { ProgressBarService } from '@lib/services/progressBar/progress-bar.service';
import { GnxSidebarMode } from "@lib/types";
import { IAppUIState } from "@lib/ui/store/models/appUiState.interface";
import { uiStateSelector } from "@lib/ui/store/uiState/uiState.selector";
import { select, Store } from "@ngrx/store";
import { Observable, Subject, takeUntil } from "rxjs";

@Component({
  selector: 'gnx-default-layout',
  templateUrl: './default-layout.component.html',
  styleUrls: ['./default-layout.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class DefaultLayoutComponent implements OnInit, OnDestroy {

  private destroy$: Subject<any>;
  private uiState$: Observable<IAppUIState>;

  sidenavRight: boolean;

  constructor(private store: Store, private _progressBarService: ProgressBarService) {
    this.destroy$ = new Subject<any>();
    this.uiState$ = this.store.pipe(select(uiStateSelector));
   }


  ngOnInit(): void {

    this.uiState$
      .pipe(takeUntil(this.destroy$))
      .subscribe((state) => {
        this.sidenavRight = state.sidenavState.position === 'right';
        
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next(null);
    this.destroy$.complete();
  }
}
