import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'policystatus'
})
export class PolicyStatusPipe implements PipeTransform {

  transform(text: string) {
    if (text == "100") return "Issued";
    else if (text == "90") return "Draft"
    else if (text == "92") return "Sent for Approval"
    else if (text == "94") return "Approved"
    else if (text == "99") return "Payment Initiated"
    else return "Rejected";
  }

}
