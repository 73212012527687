import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatIconModule } from "@angular/material/icon";
import { MatButtonModule } from "@angular/material/button";
import { HeaderAlertComponent } from './header-alert.component';



@NgModule({
  declarations: [
    HeaderAlertComponent
  ],
  imports: [
    CommonModule,
    MatIconModule,
    MatButtonModule
  ],
  exports: [
    HeaderAlertComponent
  ]
})
export class HeaderAlertModule { }
