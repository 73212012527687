import { Component, OnInit, OnDestroy } from '@angular/core';
import { GnxMediaWatcherService } from "@lib/services/gnx-media-watcher.service";
import { sidenavModeOver, sidenavModeSide } from "@lib/ui/store/uiState/ui.actions";
import { Store } from "@ngrx/store";
import { Observable, Subject, takeUntil } from "rxjs";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  // encapsulation: ViewEncapsulation.None
})
export class AppComponent implements OnInit, OnDestroy {
  title = 'Marine Insurance App';

  private destroy$: Subject<any>;
  constructor(private mediaWatcher: GnxMediaWatcherService, private store: Store) {
    this.destroy$ = new Subject();
  }

  ngOnInit(): void {
    // Subscribe to media changes
    this.mediaWatcher.onMediaChange$
      .pipe(takeUntil(this.destroy$))
      .subscribe(({ matchingAliases }) => {

        // Check if the screen is small - this will not include md in the array
        if (!matchingAliases.includes('md')) {
          //change the sidenav mode to over
          this.store.dispatch(sidenavModeOver());
        } else {
          this.store.dispatch(sidenavModeSide());
        }
      });
  }

  ngOnDestroy(): void {
    this.destroy$.next(null);
    this.destroy$.complete();
  }
}
