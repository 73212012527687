import { Component } from '@angular/core';

@Component({
  selector: 'gnx-auth-home',
  templateUrl: './auth-home.component.html',
  styleUrls: ['./auth-home.component.scss']
})
export class AuthHomeComponent {

}
