import { OrderBySpecs } from "./orderBySpecs.model";
import { IFilterConditions, FilterConditions } from "./query.filter.interface";

export interface IAdditionalFilterObject {
  key: string;
  filterValues: string[];
}
export interface IQuerySpecs {
  PaginationSpecs: IPaginationSpecs;
  FilterConditions?: IFilterConditions | null;
  OrderBySpecs?: OrderBySpecs[];
  AdditionalFilters?: IAdditionalFilterObject[];
}

export class QuerySpecs implements IQuerySpecs {
  static BuildQuery(arg0: import("./query.filter.interface").IFilterRule[], DefaultPagination: PaginsationSpecs, DefaultSortOrder: OrderBySpecs[], DefaultDisplayColumn: string[], arg4: IAdditionalFilterObject[]): any {
    throw new Error('Method not implemented.');
  }
  public FilterConditions?: IFilterConditions;
  public PaginationSpecs: IPaginationSpecs;
  public OrderBySpecs?: OrderBySpecs[];
  public AdditionalFilters?: IAdditionalFilterObject[];
}

export interface IPaginationSpecs {
  PaginationRequired: boolean;
  Page: number;
  Limit: number;
}

export class PaginsationSpecs implements IPaginationSpecs {
  public PaginationRequired: boolean = false;
  public Page: number = 1;
  public Limit: number = 0;
}

export class PaginationSpecs implements IPaginationSpecs {
  public PaginationRequired: boolean = true;
  public Page: number = 1;
  public Limit: number = 0;
}
