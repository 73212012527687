import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute } from '@angular/router';
import { API_ENDPOINTS } from '@config/api-endpoints.config';
import { popUpColumns } from '@config/popupList.config';
import { DialogService } from '@lib/services/dialog.service';
import { AlertsService } from '@lib/services/error-handling/alerts.service';
import { HttpService } from '@lib/services/http/http.service';
import { IFilterRule, IAdditionalFilterObject } from '@models/common';
import { IUserProfile, UserProfile } from '@models/dtos/auth';
import { ICountryDto } from '@models/dtos/core/CountryDto';
import { AuthService } from '@services/auth/auth.service';
import { BehaviorSubject, Observable, Subject, takeUntil } from 'rxjs';


@Component({
  selector: 'gnx-gen-popup',
  templateUrl: './gen-popup.component.html',
  styleUrls: ['./gen-popup.component.scss']
})
export class GenPopupComponent {
  title: string;
  ispopup:boolean=false;
  api = API_ENDPOINTS.User.Base;
  columnDef: any;
  selecteData:any
  multiSelectData:any
  Rules: any[] = []
  AdditionalFilters: any[] = []
  columnSortOptions:any[]=[]
  // Default page filters
  pagefilters = {
    currentPage: 1,
    limit: 20,
    columnSearchOptions: {

      Field: '',
      Operator : "",
      Value: ""

    },
    columnSortOptions: {
      Field: 'Name',
      Direction: 'asc',
    },
  };


  APIendPoint: any;
  displayedColumns: string[];
  dataSource: MatTableDataSource<any> = new MatTableDataSource<any>();
  destroy$: Subject<any>;
  DataList: BehaviorSubject<any>;
  DataList$: Observable<any>;
  user: UserProfile;




//   // #end region public variables

//   /**
//    * #region constructor
//    * @param _route : used for getting dynamic route or id
//    */

  constructor(
    private _route: ActivatedRoute,
    private _dataService: HttpService,
    private _dialogService: DialogService,
    private _alertservice: AlertsService,
    private _authservice: AuthService,
       public dialogRef: MatDialogRef<GenPopupComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      type: string;
      title: string;
      filterData: IFilterRule[];
      addFilterData: IAdditionalFilterObject[];
      isMultiple: boolean;
      data?: any;
      user?: any
    }
  ) {
    this.title = this.data.title
    this.user = data.user;
    if (this.data.type == 'PIN'){
      this.APIendPoint = API_ENDPOINTS.Pincode.list
    }

    if (this.data.type == 'Size'){
      this.APIendPoint = API_ENDPOINTS.Container.List;
      this.Rules.push({Field:'Id', Operator:'ne', Value: '16'});
      this.Rules.push({Field:'Id', Operator:'ne', Value: '17'});
      this.Rules.push({Field:'Id', Operator:'ne', Value: '18'});
      this.searchColumn({field:'CommodityId', operator:'eq', searchValue: data.data.commodityid, isAdditional: false});
    }

    if (this.data.type == 'Commodity'){
      this.APIendPoint = API_ENDPOINTS.StaticList.Commodity
      if (!this.data.data?.showall) {
        this.Rules.push({Field:'Id', Operator:'ne', Value: '7'});
        this.Rules.push({Field:'Id', Operator:'ne', Value: '8'});
        this.Rules.push({Field:'Id', Operator:'ne', Value: '20'});
      }
      this.columnSortOptions = [{field: 'CommodityCode', direction:'asc'}]
    }

    if (this.data.type == 'ContainerCommodity') {
      this.APIendPoint = API_ENDPOINTS.StaticList.Commodity;
      this.searchColumn({field:'Id', operator:'in', searchValue: ['7','8','20'], isAdditional: false});
    }

    if (this.data.type == 'Company'){
      this.APIendPoint = API_ENDPOINTS.StaticList.CompanyType
    }

    if (this.data.type == 'SalesPerson') {
      this.APIendPoint = API_ENDPOINTS.UserSalesPerson.List;
      if(this.data.data?.branchid) {
        this.searchColumn({field:'BranchId', operator: 'in', searchValue: data.data.branchid, isAdditional: true});
      }
    }

    if (this.data.type == 'SupportExecutive') {
      this.APIendPoint = API_ENDPOINTS.UserSupportExecutive.List;
      if(this.data.data?.branchid){
        this.searchColumn({field:'BranchId', operator: 'in', searchValue: data.data.branchid, isAdditional: true});
      }
    }

    if (this.data.type == 'TeamLeader') {
      this.APIendPoint = API_ENDPOINTS.UserTeamLeader.List;
      if(this.data.data?.branchid) {
        this.searchColumn({field:'BranchId', operator: 'in', searchValue: data.data.branchid, isAdditional: true});
      }
      if(this.data.data?.salespersonid){
        this.searchColumn({field:'ReportingToUserId', operator: 'eq', searchValue: data.data.salespersonid, isAdditional: false});
      }
    }

    if (this.data.type == 'Policy'){
      this.APIendPoint = API_ENDPOINTS.Policy.List;
      this.Rules.push({Field:'Status', Operator: 'eq', Value: 100});
      if(this.user?.Type == 'SupportExecutive'){
        this.Rules.push({Field: 'SupportExecutiveId', Operator: 'eq', Value: this.user.Id})
      }
      else if(this.user?.Type == 'TeamLeader'){
        this.Rules.push({Field: 'TeamLeaderId', Operator: 'eq', Value: this.user.Id})
      }
      else if(this.user?.Type == 'SalesPerson'){
        this.Rules.push({Field: 'SalesPersonId', Operator: 'eq', Value: this.user.Id})
      }
      else if(this.user?.Type == 'Agent'){
        this.Rules.push({Field: 'AgentId', Operator: 'eq', Value: this.user.Id})
      }
      if(data.data?.teamleaderid) {
        this.Rules.push({Field: 'TeamLeaderId', Operator: 'eq', Value: data.data.teamleaderid})
      }
      if(data.data?.salespersonid) {
        this.Rules.push({Field: 'SalesPersonId', Operator: 'eq', Value: data.data.salespersonid})
      }
      if(this.data.data?.branchid) {
        this.Rules.push({Field: 'BranchId', Operator: 'eq', Value: data.data.branchid})

      }
      if(this.data.data?.agentid) {
        this.Rules.push({Field: 'AgentId', Operator: 'eq', Value: data.data.agentid})
      }
      if(this.data.data?.supportexecutiveid) {
        this.Rules.push({Field: 'SupportExecutiveId', Operator: 'eq', Value: data.data.supportexecutiveid})
      }
    }

    if (this.data.type == 'TermsOfSale'){
      this.APIendPoint = API_ENDPOINTS.StaticList.TermsOfSale;
      this.searchColumn({field:'TransitTypeId', operator:'eq', searchValue: data.data.transittypeid, isAdditional:false});
    }

    if (this.data.type == 'TransitMode'){
      this.APIendPoint = API_ENDPOINTS.StaticList.TransitMode;
      if (this.data.data?.iscontainer) {
      this.searchColumn({field:'Id', operator:'in', searchValue: ['3','4'], isAdditional: false});
      }
      if (this.data.data?.odc) {
      this.searchColumn({field:'Id', operator:'in', searchValue: ['1','3','4'], isAdditional: false});
      }
    }


    if (this.data.type == 'TransitType'){
      this.APIendPoint = API_ENDPOINTS.StaticList.TransitType;
      this.searchColumn({field:'Id', operator:'ne', searchValue: '5', isAdditional: false});
    }

    if (this.data.type == 'Product'){
      this.APIendPoint = API_ENDPOINTS.StaticList.Product;
    }

    if (this.data.type == 'Packaging'){
      this.APIendPoint = API_ENDPOINTS.StaticList.Packaging;
      this.searchColumn({field:'TransitMode', operator:'eq', searchValue: data.data.transitmode, isAdditional:false});
    }

    if (this.data.type == 'BasisOfValuation'){
      this.APIendPoint = API_ENDPOINTS.StaticList.BasisOfValuation;
      this.Rules.push({Field:'TransitTypeId', Operator:'eq', Value: data.data.transittypeid})
      this.Rules.push({Field:'CommodityId', Operator:'eq', Value: data.data.commodityid})
    }

    if (this.data.type == 'Coverage'){
        this.APIendPoint = API_ENDPOINTS.StaticList.CoverageDetail;
        if (this.data.data?.commodityid) {
          this.searchColumn({field:'CommodityIdFilter', operator: 'eq', searchValue: data.data.commodityid.toString(), isAdditional: true});
        }
        // if (this.data.data?.packagingid) {
        //   this.searchColumn({field:'PackingIdFilter', operator: 'eq', searchValue: data.data.packagingid.toString(), isAdditional: true});
        // }
        if (this.data.data?.transittypeid) {
          this.searchColumn({field:'TransitTypeIdFilter', operator: 'eq', searchValue: data.data.transittypeid.toString(), isAdditional: true});
        }
        if (this.data.data?.transitmode) {
          this.searchColumn({field:'TransitModeIdFilter', operator: 'eq', searchValue: data.data.transitmode.toString(), isAdditional: true});
        }
        if (this.data.data?.termsofsaleid) {
          this.searchColumn({field:'TermsOfSaleIdFilter', operator: 'eq', searchValue: data.data.termsofsaleid.toString(), isAdditional: true});
        }
    }

    if (this.data.type == 'SettlingAgent'){
      this.APIendPoint = API_ENDPOINTS.SettlingAgent.List;
      this.Rules.push({Field: 'CountryId', Operator:'eq', Value: data.data.settlingagentcountry})
    }

    if (this.data.type == 'Agent'){
      this.Rules.push({Field: 'ApprovalRejectStatus', Operator: 'in', Value: ['100', '99']})
      if(this.user?.Type == 'SupportExecutive'){
        this.Rules.push({Field: 'SupportExecutiveId', Operator: 'eq', Value: this.user.Id})
      }
      else if(this.user?.Type == 'TeamLeader'){
        this.Rules.push({Field: 'TeamLeaderId', Operator: 'eq', Value: this.user.Id})
      }
      else if(this.user?.Type == 'SalesPerson'){
        this.Rules.push({Field: 'SalesPersonId', Operator: 'eq', Value: this.user.Id})
      }
      if(data.data?.teamleaderid) {
        this.Rules.push({Field: 'TeamLeaderId', Operator: 'eq', Value: data.data.teamleaderid})
      }
      if(data.data?.salespersonid) {
        this.Rules.push({Field: 'SalesPersonId', Operator: 'eq', Value: data.data.salespersonid})
      }
      if(this.data.data?.branchid) {
        this.Rules.push({Field: 'BranchId', Operator: 'eq', Value: data.data.branchid})
      }
      if(this.data.data?.supportexecutiveid) {
        this.Rules.push({Field: 'SupportExecutiveId', Operator: 'eq', Value: data.data.supportexecutiveid})
      }
      this.APIendPoint = API_ENDPOINTS.Agent.List;

    }

    if (this.data.type == 'Branch' || this.data.type == 'Branches'){
      this.APIendPoint = API_ENDPOINTS.Branch.List;
      if(this.data.data?.branches && this.data.data?.branches.length) {
        this.Rules.push({Field: 'Id', Operator: 'in', Value: data.data.branches});
      }

    }

    if (this.data.type == 'Email') {
      this.APIendPoint = API_ENDPOINTS.EmailNotification.List;
    }

    if (this.data.type == 'WhatsApp') {
      this.APIendPoint = API_ENDPOINTS.Whatsapp.List;
    }

    if (this.data.type == 'Currency'){
      this.APIendPoint = API_ENDPOINTS.Currency.List
    }

    if (this.data.type == 'Pincode' || this.data.type.endsWith('Pincode')){
      this.APIendPoint = API_ENDPOINTS.Pincode.list
    }

    if (this.data.type == 'Country' || this.data.type.endsWith('Country')){
      this.APIendPoint = API_ENDPOINTS.Country.List;
      if(this.data.data?.transittypeid){
        if(this.data.data?.transittypeid == 1 || this.data.data?.transittypeid == 2) {
          this.Rules.push({Field: 'Id', Operator:'ne', Value:1})
        }
      }


    }
    if (this.data.type == 'State'){
      this.APIendPoint = API_ENDPOINTS.State.List


    }
    if (this.data.type == 'City' || this.data.type.endsWith('City')){
      this.APIendPoint = API_ENDPOINTS.City.List


    }
    if (this.data.type == 'Role') {
      this.APIendPoint = API_ENDPOINTS.Role.List
    }
    if (this.data.type == 'Department') {
      this.APIendPoint = API_ENDPOINTS.Department.List


    }
    if (this.data.type == 'Designation') {
      this.APIendPoint = API_ENDPOINTS.Designation.List


    }
    if (this.data.type == 'User') {
      this.APIendPoint = API_ENDPOINTS.User.List


    }
    if (this.data.type == 'Department') {
      this.APIendPoint = API_ENDPOINTS.Department.List


    }
    if (this.data.type == 'VerticalHeadName') {
      this.APIendPoint = API_ENDPOINTS.User.List
    }

    if(this.data.type.endsWith('City')) {
      this.columnDef = popUpColumns['City'];
    }
    else if (this.data.type.endsWith('Pincode')) {
      this.columnDef = popUpColumns['Pincode'];
    }
    else if(this.data.type.endsWith('Country')) {
      this.columnDef = popUpColumns['Country'];
    }
    else if(this.data.type.endsWith('Commodity')){
      this.columnDef = popUpColumns['Commodity'];
    }
    else{
      this.columnDef = popUpColumns[this.data.type];
    }
    this.destroy$ = new Subject();

    this.DataList = new BehaviorSubject(null);
    this.DataList$ = this.DataList.asObservable();
  }

  // #endregion constructor

  ngOnInit(): void {
    this._init();
  }

  ngOnDestroy(): void {
    // Resets the filters.
    this.destroy$.next(null);
    this.destroy$.complete();
  }

  // #region public methods

  public cancle(){
      this.dialogRef.close();
  }

  public submit(){
    if (this.selecteData) {
      this.dialogRef.close(this.selecteData);
    }
    if (this.multiSelectData) {
      this.dialogRef.close(this.multiSelectData);
    }
  }
  Direction = 'asc'

  sortColumn(column: string) {
    this.columnSortOptions = []
    this.pagefilters.columnSortOptions.Field = column
    if (this.pagefilters.columnSortOptions.Direction == 'asc') {
      this.pagefilters.columnSortOptions.Direction = 'desc';
      // this._loadLists();
    } else {
      this.Direction = 'asc'
      this.pagefilters.columnSortOptions.Direction = 'asc';
      // this._loadLists();
    }
    this.columnSortOptions.push(this.pagefilters.columnSortOptions)
    this.pagefilters.currentPage = 1;
    this._loadLists();

  }

  searchColumn(value: {
    field: string;
    searchValue: any;
    operator: string;
    isAdditional: boolean;
  }) {
    if(value.isAdditional) {
      let additionalFilters: IAdditionalFilterObject = {
        key: value.field,
        filterValues: [value.searchValue],
      };

      let i = this.AdditionalFilters.findIndex(
        (f) => f.key === additionalFilters.key
      );

      if (i >= 0) {
        this.AdditionalFilters[i] = additionalFilters;
      } else {
        this.AdditionalFilters.push(additionalFilters);
      }
    }
    else {
      this.pagefilters.columnSearchOptions.Field = value.field
      this.pagefilters.columnSearchOptions.Operator = "contains"
      if (value.operator) {
        this.pagefilters.columnSearchOptions.Operator = value.operator
      }
      this.pagefilters.columnSearchOptions.Value = value.searchValue
      let index = this.Rules.findIndex((el) => (el.Field == this.pagefilters.columnSearchOptions.Field))
      if (index!=-1) 
      {
        this.Rules[index] = JSON.parse(JSON.stringify(this.pagefilters.columnSearchOptions))
      }
      else{
        this.Rules.push(JSON.parse(JSON.stringify(this.pagefilters.columnSearchOptions)))
      }
    }
    this.pagefilters.currentPage = 1;
    this._loadLists();
  }

  selectedData(data){
    // this.selecteData=data
    this.dialogRef.close(data);
}

multiSelectedData(data){
  this.multiSelectData = data;
}

saveall() {
  this.dialogRef.close(this.DataList.value.Data.Items);
}

  setLimit(value) {
    this.pagefilters.limit = value;
this.pagefilters.currentPage = 1;
    this._loadLists();
  }
  // pagination for next page
  nextPage() {
    this.pagefilters.currentPage = this.pagefilters.currentPage + 1;
    this._loadLists();
  }

  // pagination for prev page
  previousPage() {
    this.pagefilters.currentPage = this.pagefilters.currentPage - 1;
    this._loadLists();
  }

  // #endregion public methods

  // #region private methods

  private _init() {

    this._loadLists();
  }

  private _loadLists() {
    let listRequestBody = {
      PaginationSpecs: {
        PaginationRequired: true,
        Page: this.pagefilters.currentPage,
        Limit: this.pagefilters.limit,
      },
      FilterConditions: {
        Condition: 'and',
        Rules: this.Rules,
      },
      OrderBySpecs: this.columnSortOptions,
      AdditionalFilters: this.AdditionalFilters,
      DisplayColumns: [],
    };

    this._dataService
      .getData(listRequestBody, this.APIendPoint)
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        (res) => {
          if (res.Success) {
            this.DataList.next(res);
          }
          else{
            this._alertservice.raiseErrors(res.Alerts);
          }
        },
        (err) => {
          this._alertservice.raiseErrors(err);
        }
      );
  }
  // #endregion private methods
}
