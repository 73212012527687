<div class="w-fit min-h-fit sm:w-120 sm:pt-8 sm:min-h-max flex flex-col gap-y-10 text-lg bg-white p-8 shadow-2xl rounded-2xl shadow-gray-700">

  <div class="header">
    <div class="flex justify-center py-7">
      <!-- Brand logo -->
      <img alt="Logo" [src]="['assets/images/logos/log.svg']" class="w-60 self-center" />
    </div>
    <div class="flex w-full justify-center gap-y-1 -mb-3">
      <!-- Page Title -->
      <h1 class="title text-3xl text-on-primary-50 mt-0 font-semibold ">Forgot Password</h1>
      <!-- Login Link -->
      <!-- <p class="text-accent-500 text-sm">
        <span [routerLink]="['../login']" class="text-primary-800 font-semibold text-sm text-end hover:cursor-pointer">
          Sign In
        </span>
      </p> -->
    </div>

    <!-- REDIRECT TO LANDING PAGE -->
    <!-- <button mat-icon-button aria-label="Go to Home"
      class="absolute hidden sm:flex justify-center items-center top-0 right-0 rounded-full mb-0 bg-transparent text-accent-700 disabled:opacity-70 disabled:shadow-none"
      [disabled]="submitted" [routerLink]="['../login']">
      <i class="fa fa-multiply text-md"></i>
    </button> -->
  </div>

  <form [formGroup]="ForgotPasswordForm" class="flex flex-col gap-6 sm:gap-6 w-full" (ngSubmit)="sendotp()">
    <div class="relative my-2">
      <div class="icon">
        <mat-icon>person</mat-icon>
      </div>
      <input
        type="text"
        class="peer"
        name="Username"
        id="Username"
        placeholder=" "
        formControlName="Username"
        autocomplete="off"
      />
      <label for="Username" class="mandatory">User Name / Email ID</label>
      <small
          *ngIf="
            f['Username'].invalid &&
            f['Username'].touched &&
            f['Username'].hasError('required')
          "
          class="h-6 w-full text-sm text-warn-500 absolute -bottom-6 left-0 leading-[1px]"
        >
          <gnx-alert
            [alert]="alertCreater('Enter Your User Name / Email ID')"
            [showIcon]="false"
          ></gnx-alert>
        </small>
    </div>
      <div class="relative my-2" *ngIf="this.submitted">
        <div class="icon">
          <mat-icon>enhanced_encryption</mat-icon>
        </div>
        <input
          type="text"
          class="peer"
          name="OTP"
          id="OTP"
          placeholder=" "
          formControlName="OTP"
          autocomplete="off"
          Onlynumber
        />
        <label for="OTP" class="mandatory">OTP</label>
        <small
        *ngIf="
          f['OTP'].invalid &&
          f['OTP'].touched &&
          f['OTP'].hasError('required')
        "
        class="h-6 w-full text-sm text-warn-500 absolute -bottom-6 left-0 leading-[1px]"
      >
        <gnx-alert
          [alert]="alertCreater('Enter Your OTP')"
          [showIcon]="false"
        ></gnx-alert>
      </small>
      </div>
      <div class="relative flex justify-end items-center my-2" *ngIf="this.submitted">
        <div class="icon">
          <mat-icon>lock</mat-icon>
        </div>
        <input
          [type]="hide ? 'password' : 'text'"
          name="Password"
          id="Password"
          class="peer"
          placeholder=" "
          formControlName="Password"
          autocomplete="off"
        />
        <label for="Password" class="mandatory">New Password</label>
        <button type="button" class="absolute mr-2" mat-icon-button matSuffix (click)="hide = !hide" [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hide">
          <mat-icon>{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
        </button>
        <small
        *ngIf="
          f['Password'].invalid &&
          f['Password'].touched &&
          f['Password'].hasError('required')
        "
        class="h-6 w-full text-sm text-warn-500 absolute -bottom-6 left-0 leading-[1px]"
      >
        <gnx-alert
          [alert]="alertCreater('Enter Your Password')"
          [showIcon]="false"
        ></gnx-alert>
      </small>
      </div>
    <button mat-raised-button
    class="w-fit bg-sky-600 text-white disabled:shadow-none self-center"
    [disabled]="ForgotPasswordForm.invalid" type="submit"><span *ngIf="!this.submitted">Send Link</span><span *ngIf="this.submitted">Reset Password</span>
  </button>
    <div class="text-center -mt-1">
      <p class="text-primary-800 font-semibold text-sm hover:cursor-pointer" [routerLink]="['../login']">Back to Login</p>
    </div>
  </form>
</div>
