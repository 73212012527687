import { ChangeDetectorRef, Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AlertsService } from '@lib/services/error-handling/alerts.service';
import { IAppAlert } from '@lib/types';
import { AuthService } from '@services/auth/auth.service';
import { Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'gnx-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent {

  submitted: boolean;
  ForgotPasswordForm: FormGroup;
  private _destroy$: Subject<any>;

  hide = true;
  constructor(private _fb: FormBuilder,
    private _router: Router,
    private _authservice: AuthService,
    private _alertservice: AlertsService,
    public cdr: ChangeDetectorRef
    ) {
    this.submitted = false;
    this._initForm();
    this._destroy$ = new Subject();
  }

  get f() {
    return this.ForgotPasswordForm.controls
  }

    //Error Message
    error: IAppAlert = {
      message: '',
      type: 'error',
      canDismiss: false,
      appearance: 'outline',
    };

  redirectToHome(){
    this._router.navigate(['home'])
  }

    // creates alerts for login form view
    alertCreater(msg: string) {
      let alert: IAppAlert = {
        message: msg,
        type: 'error',
        appearance: 'soft',
        canDismiss: false,
        showIcon: false,
      };
      return alert;
    }

  sendotp() {
    if (this.ForgotPasswordForm.invalid) {
      return;
    }

    if(this.submitted) {
      this._authservice
      .resetpassword(this.ForgotPasswordForm.value)
      .pipe(takeUntil(this._destroy$))
      .subscribe({
        next: (res:any) => {
          if (res.Success) {
            this._alertservice.raiseSuccessAlert(res.Message);
            this._router.navigate(['/auth/login']);
          } else {
            this._alertservice.raiseErrors(res.Alerts, true);
          }
        },
        error: (error) => {
          this._alertservice.raiseErrors(error);
        }
      });
    }
    else {
      this._authservice
      .sendotp(this.ForgotPasswordForm.value)
      .pipe(takeUntil(this._destroy$))
      .subscribe({
        next: (res:any) => {
          if (res.Success) {
            this._alertservice.raiseSuccessAlert(res.Message);
            //Redirect
            this.f['Password'].setValidators([Validators.required]);
            this.f['OTP'].setValidators([Validators.required]);
            this.submitted = true;
            this.cdr.detectChanges();
          } else {
            // handle alerts here
            this._alertservice.raiseErrors(res.Alerts, true);
          }
        },
        error: (error) => {
          this._alertservice.raiseErrors(error);
        }
      });
    }
  }

  private _initForm(): void {
    this.ForgotPasswordForm = this._fb.group({
      Username: ['', [Validators.required]],
      Password: [''],
      OTP: [''],
    });
  }
  
}
