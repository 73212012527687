import { debounceTime, Subject, takeUntil, distinctUntilChanged } from 'rxjs';
// #region imports
import {
  Component,
  Input,
  Output,
  EventEmitter,
  OnChanges,
  SimpleChanges,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { API_ENDPOINTS } from '@config/api-endpoints.config';
import { HttpService } from '@lib/services/http/http.service';
import { AlertsService } from '@lib/services/error-handling/alerts.service';
import { DialogService } from '@lib/services/dialog.service';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { NotificationRemarksComponent } from 'src/app/features/main/masters/admin-masters/notifications/notificationremarks/notificationremarks.component';
import { Router } from '@angular/router';
import { NotificationremarksPolicyComponent } from 'src/app/features/main/masters/admin-masters/notifications/notificationremarks-policy/notificationremarks-policy.component';
import { AuthService } from '@services/auth/auth.service';
import { IUserProfile } from '@models/dtos/auth';
import { InvoiceUpdateComponent } from 'src/app/features/main/masters/admin-masters/notifications/invoiceupdate/invoiceupdate.component';
// #endregion imports

@Component({
  selector: 'gnx-table-list',
  templateUrl: './table-list.component.html',
  styleUrls: ['./table-list.component.scss'],
})
export class TableListComponent implements OnChanges, OnDestroy {
  // header and column defination
  @Input() public headArray;
  @Input() public listDataObservable;
  @Input() public pagefilters;
  // extend functionality for particular master
  @Input() public masterName;

  @Input() public rowKey = 'Id';

  @Input() public isViewDisabled = false;
  @Input() public isEditDisabled = false;
  @Input() public isDeleteDisabled = false;
  @Input() public isSortDisabled = false;
  @Input() public isInfoDisabled = true;

  @Input() public addsum;
  @Input() public dedsum;

  @Input() public grosstotal;
  @Input() public rofftotal;
  @Input() public gsttotal;
  @Input() public nettotal;
  @Input() public sumtotal;
  @Input() public igsttotal;
  @Input() public sgsttotal;
  @Input() public cgsttotal;
  @Input() public customertotal;
  @Input() public customerbasetotal;


  @Output() onDelete = new EventEmitter<any>();
  @Output() onSearch = new EventEmitter<any>();
  @Output() onSort = new EventEmitter<any>();
  @Output() onNext = new EventEmitter<any>();
  @Output() onPrev = new EventEmitter<any>();
  @Output() onLimitChange = new EventEmitter<any>();
  @Output() onSelectedData = new EventEmitter<any>();
  @Output() onMultiSelectedData = new EventEmitter<any>();
  @Output() onInfo = new EventEmitter<unknown>();

  destroyed$: Subject<boolean> = new Subject();
  destroy$: Subject<any>;
  searchTerm$: Subject<string> = new Subject();
  pagedData;
  selectedData: any;
  multiSelectedData: any[] = [];
  user: IUserProfile;


  // #region public variables

  // #endregion public variables

  /**
   * #region constructor
   */
  constructor(
    public httpService: HttpService,
    public alertsService: AlertsService,
    private _dialogService: DialogService,
    public dialog: MatDialog,
    private _router: Router,
    private _authservice: AuthService
  ) {
    this.destroy$ = new Subject();
    this._authservice.userProfile$
      .pipe(takeUntil(this.destroy$))
      .subscribe((user: IUserProfile) => {
        if(user){
          this.user = user;
        }
      });
    this.searchTerm$
      .pipe(debounceTime(500), distinctUntilChanged())
      .subscribe((res) => {
        this.ngOnChanges();
      });
  }

  // #endregion constructor

  ngOnChanges(): void {
    if (this.listDataObservable) {
      this.listDataObservable
        .pipe(takeUntil(this.destroyed$))
        .subscribe((res) => {
          this.pagedData = res;
        });
    }
  }

  ngOnDestroy(): void {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }

  /**
   * #region public methods
   */

  deleteButton(id: any) {
    this.onDelete.emit(id);
  }

  infoHandler(key) {
    this.onInfo.emit(key)
  }

  public openDialog(type: string, title: string, index: number) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.width = '80vw';
    // dialogconfig.minwidth = 'fit-content';
    // dialogconfig.minheight = 'fit-content';
    dialogConfig.maxHeight = '80vh';

    const dialogRef = this.dialog.open(NotificationRemarksComponent, dialogConfig);

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
          let api = API_ENDPOINTS.Claim.Base
          this.httpService.getDataById(index, api).subscribe((res) => {
            if(res.Success){
              let claimdata = res.Data;
              claimdata.Remarks = result.Remarks;
              claimdata.PolicyProgress = result.PolicyProgress;
              claimdata.SettlementDate = result.SettlementDate;
              claimdata.SettlementAmount = result.SettlementAmount;
              claimdata.FinalBillAmount = result.FinalBillAmount;
              this.httpService.updateData(claimdata, api).subscribe((res) => {
                if(res.Success){
                  this.alertsService.raiseSuccessAlert(res.Message);
                  this.onSearch.emit({field: 'Remarks',
                    searchValue: '',
                    operator: 'contains',
                    isAdditonal: false});
                }
                else{
                  this.alertsService.raiseErrors(res.Alerts);
                }
              })
            }
            else{
              this.alertsService.raiseErrors(res.Alerts);
            }
          })
        }
    });
  }

  public opendialog(type: string, title: string, index: number) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.width = '80vw';
    // dialogconfig.minwidth = 'fit-content';
    // dialogconfig.minheight = 'fit-content';
    dialogConfig.maxHeight = '80vh';

    let api = API_ENDPOINTS.Policy.Base
    this.httpService.getDataById(index, api).subscribe((res) => {
      if(res.Success){
        let policyDto = res.Data;
        dialogConfig.data = {
          type: type,
          title: title,
          ispopup: true,
          policydto: policyDto
        };

        if(type=='Invoice Update') {
          let DialogRef = this.dialog.open(InvoiceUpdateComponent, dialogConfig);
          DialogRef.afterClosed().subscribe((result) => {
            if (result) {
              dialogConfig.data.policydto.CustomerTotalPremiumAmt = (+result.CustomerTotalPremiumAmt);
                let api_update = API_ENDPOINTS.Policy.Update + '/true';
                this.httpService.updateData(dialogConfig.data.policydto, api_update).subscribe((res) => {
                  if (res.Success) {
                    this.alertsService.raiseSuccessAlert(res.Message);
                    this.onSearch.emit({field: 'InsuredName',
                    searchValue: '',
                    operator: 'contains',
                    isAdditonal: false});
                  }
                  else {
                    this.alertsService.raiseErrors(res.Alerts);
                  }
                })
            }
          });
        }
        else {
          let DialogRef = this.dialog.open(NotificationremarksPolicyComponent, dialogConfig);
          DialogRef.afterClosed().subscribe((result) => {
            if (result) {
              dialogConfig.data.policydto.AdditionalInfo = result.AdditionalInfo;
              dialogConfig.data.policydto.BuyerConsignee = result.BuyerConsignee;
              dialogConfig.data.policydto.JobNo = result.JobNo;
              dialogConfig.data.policydto.RRDate = result.RRDate;
              dialogConfig.data.policydto.RRNo = result.RRNo;
              dialogConfig.data.policydto.RailAuthorityName = result.RailAuthorityName;
              dialogConfig.data.policydto.SellerConsignor = result.SellerConsignor;
                let api_update = API_ENDPOINTS.Policy.Update
                this.httpService.updateData(dialogConfig.data.policydto, api_update).subscribe((res) => {
                  if (res.Success) {
                    this.alertsService.raiseSuccessAlert(res.Message);
                    this.onSearch.emit({field: 'InsuredName',
                    searchValue: '',
                    operator: 'contains',
                    isAdditonal: false});
                  }
                  else {
                    this.alertsService.raiseErrors(res.Alerts);
                  }
                })
            }
          });
        }
      }
    });

  }
  deactivate(id: any){
    this._dialogService.confirmDialog({
      title: 'Are You Sure?',
      message: "Agent will be deactivated",
      confirmText: 'Yes',
      cancelText: "No"
    }).subscribe(res => {
      if (res) {
        let api = API_ENDPOINTS.Agent.StatusUpdate
            this.httpService.updateData(null, api+'/'+id+'/'+0).subscribe((res) => {
              if(res.Success){
                this.alertsService.raiseSuccessAlert(res.Message);
                this.onSearch.emit({field: 'ApplicationID',
                  searchValue: '',
                  operator: 'contains',
                  isAdditonal: false});
              }
              else{
                this.alertsService.raiseErrors(res.Alerts);
              }
            })
          }
    })
  }

  activate(id: any){
    this._dialogService.confirmDialog({
      title: 'Are You Sure?',
      message: "Agent will be activated",
      confirmText: 'Yes',
      cancelText: "No"
    }).subscribe(res => {
      if (res) {
        let api = API_ENDPOINTS.Agent.Base
        this.httpService.getDataById(id, api).subscribe((res) => {
          if(res.Success){
            let agentdata = res.Data;
            agentdata.Status = 1;
            this.httpService.updateData(agentdata, api).subscribe((res) => {
              if(res.Success){
                this.alertsService.raiseSuccessAlert(res.Message);
                this.onSearch.emit({field: 'ApplicationID',
                  searchValue: '',
                  operator: 'contains',
                  isAdditonal: false});
              }
              else{
                this.alertsService.raiseErrors(res.Alerts);
              }
            })
          }
          else{
            this.alertsService.raiseErrors(res.Alerts);
          }
        })
      }
    })
  }

  RechargeCheck(id: any){
    let api = API_ENDPOINTS.Recharge.Status;
    this.httpService.getDataById(id, api).subscribe((res) => {
      if (res.Success) {
        this.alertsService.raiseSuccessAlert(res.Message);
        this.onSearch.emit({field: 'Id',
        searchValue: '',
        operator: 'contains',
        isAdditonal: false});
      }
      else {
        this.alertsService.raiseErrors(res.Alerts);
      }
    })
  }

  openRecharge(id) {
    localStorage.setItem('PolicyId', id);
    const url = this._router.serializeUrl(
      this._router.createUrlTree(['/app/recharge'])
    );
    window.open(url, '_blank');
  }

  downloadinvoice(id: any){
    let api = API_ENDPOINTS.AgentLedger.Invoice
    this.httpService.Download(id, api).subscribe(
      (blob: any) => {
        if (blob.success == false) {
        } else {
          const a = document.createElement("a");
          const objectUrl = URL.createObjectURL(blob);
          a.href = objectUrl;
          a.download = 'Payment Receipt'+id+'.pdf';
          a.click();
          URL.revokeObjectURL(objectUrl);
        }
      },
      (err) => {
        this.alertsService.raiseErrors(err);
      }
    );
  }

  DownloadPolicy(id: any){
    let api = API_ENDPOINTS.Policy.Download
    this.httpService.Download(id, api).subscribe(
      (blob: any) => {
        if (blob.success == false) {
        } else {
          const a = document.createElement("a");
          const objectUrl = URL.createObjectURL(blob);
          a.href = objectUrl;
          a.download = 'Policy'+id+'.pdf';
          a.click();
          URL.revokeObjectURL(objectUrl);
        }
      },
      (err) => {
        this.alertsService.raiseErrors(err);
      }
    );
  }

  DownloadInvoice(id: any){
    let api = API_ENDPOINTS.Policy.InvoiceDownload
    this.httpService.Download(id, api).subscribe(
      (blob: any) => {
        if (blob.success == false) {
        } else {
          const a = document.createElement("a");
          const objectUrl = URL.createObjectURL(blob);
          a.href = objectUrl;
          a.download = 'Invoice'+id+'.pdf';
          a.click();
          URL.revokeObjectURL(objectUrl);
        }
      },
      (err) => {
        this.alertsService.raiseErrors(err);
      }
    );
  }

  

  sortColumns(
    fieldName: any,
    sortfieldName: any = '',
    isAdditional: boolean = false,
    head: any,
  ) {
    if(head.isSortDisable) {
      return;
    }
    if(!this.isSortDisabled) {
      this.headArray.forEach(element => {
        element.isSort = false;
        if(element == head) {
          element.isSort = true;
        }
      });
      if (isAdditional && isAdditional == true || (sortfieldName != '')) this.onSort.emit(sortfieldName);
      else this.onSort.emit(fieldName);
    }
  }

  columnSearch(field: string, value: string, isAdditional: boolean = false) {
    this.searchTerm$.next(value);
    this.onSearch.emit({
      field: field,
      searchValue: value,
      operator: '',
      isAdditional: isAdditional,
    });
  }
  onSearchChange($event, field: string, isAdditional: boolean = false) {
    if ($event.target.value != '-1') {
      this.searchTerm$.next($event.target.value);
      this.onSearch.emit({
        field: field,
        searchValue: $event.target.value,
        operator: 'eq',
      });
    } else {
      this.searchTerm$.next('');
      this.onSearch.emit({
        field: field,
        searchValue: '',
        operator: 'eq',
        isAdditional: isAdditional,
      });
    }
  }
  setLimit(value) {
    this.onLimitChange.emit(value);
  }

  valueChange(index) {
    this.selectedData = this.pagedData.Data.Items[index];
    this.onSelectedData.emit(this.selectedData);
  }

  multiSelect($event, index: any) {
    if ($event.target.checked) {
      let data = this.pagedData.Data.Items[index];
      this.multiSelectedData.push(data);
    } else {
      this.multiSelectedData.splice(this.multiSelectedData.indexOf(index), 1);
    }
    this.onMultiSelectedData.emit(this.multiSelectedData);
  }

  pagePrevious() {
    this.onPrev.emit();
  }

  pageNext() {
    this.onNext.emit();
  }
  // #endregion public methods
}
