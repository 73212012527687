import { Injectable } from '@angular/core';
import { IFilterConditions, IFilterRule, QuerySpecs, operator } from '@models/common';

@Injectable({
  providedIn: 'root'
})
export class HelperService {

  constructor() { }


  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Generates a random id
   *
   * @param length
   */
  randomId(length: number = 10): string {
    const chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let name = '';

    for (let i = 0; i < 10; i++) {
      name += chars.charAt(Math.floor(Math.random() * chars.length));
    }

    return name;
  }

  omitSpecialChar(event) {
    var k;
    k = event.charCode;  //         k = event.keyCode;  (Both can be used)
    return ((k > 64 && k < 91) || (k > 96 && k < 123));
  }

  includeOnlyNumbers(event) {
    var k;
    k = event.keyCode;  //         k = event.keyCode;  (Both can be used)
    return ((k > 47 && k < 58));
  }

  /**
   * Common Query Specs
   */
  public buildQuerySpecs(searchTerm: string, fieldName: string = "Name", Rules: IFilterRule[] = []): QuerySpecs {
    // build search query
    let specs: QuerySpecs = new QuerySpecs();
    let operator: operator;
    if (searchTerm == "%") {
      searchTerm = "";
      operator = "contains";
    } else if (searchTerm.length < 3 && searchTerm != "%") {
      operator = "contains";
    } else {
      operator = "contains";
    }

    specs.PaginationSpecs = {
      PaginationRequired: false,
      Limit: 100,
      Page: 1,
    };

    specs.OrderBySpecs = [
      {
        field: fieldName,
        direction: "asc",
      },
    ];

    let rules: IFilterRule[] = [
      {
        Field: fieldName,
        Value: searchTerm,
        Operator: operator,
      }
    ];

    if (Rules) {
      rules.push(...Rules)
    }

    let filter: IFilterConditions = {
      Condition: "and",
      Rules: rules,
    };

    specs.FilterConditions = filter;
    specs.AdditionalFilters = [];

    return specs;
  }
}
