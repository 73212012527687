import {
  HttpClient,
  HttpEvent,
  HttpHeaders,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { API_ENDPOINTS } from '@config/api-endpoints.config';
import { httpOptions } from '@config/httpOptions';
import { IAdditionalFilterObject, IQuerySpecs, PagedList, ResponseMessage, gResponseMessage } from '@models/common';
import { IBasisOfValuationDto } from '@models/dtos/config/BasisOfValuationDto';
import { ICommodityDto } from '@models/dtos/config/CommodityDto';
import { ICompanyTypeDto } from '@models/dtos/config/CompanyTypeDto';
import { ICoverageDto } from '@models/dtos/config/CoverageDto';
import { ICurrencyDto } from '@models/dtos/config/CurrencyDto';
import { IPackagingDto } from '@models/dtos/config/PackagingDto';
import { IProductDto } from '@models/dtos/config/ProductDto';
import { ITermsOfSaleDto } from '@models/dtos/config/TermsOfSaleDto';
import { ITransitModeDto } from '@models/dtos/config/TransitModeDto';
import { ITransitTypeDto } from '@models/dtos/config/TransitTypeDto';
import { IBranchDto } from '@models/dtos/core/BranchDto';
import { ICityDto, ICityPincodeDto } from '@models/dtos/core/CityDto';
import { ICountryDto } from '@models/dtos/core/CountryDto';
import { IDepartmentDto } from '@models/dtos/core/DepartmentDto';
import { IDesignationDto } from '@models/dtos/core/DesignationDto';
import { IContainerSizeDto, IPolicyContainerDetailsDto } from '@models/dtos/core/PolicyDto';
import { ISalesPersonDto } from '@models/dtos/core/SalespersonDto';
import { ISettlingAgentDetailsDto, ISettlingAgentDto } from '@models/dtos/core/SettlingAgentDto';
import { IAgentDto } from '@models/dtos/core/agent-dto';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class MasterListService {
  private apiEndpoint: string;

  constructor(private _http: HttpClient) {}

  public getFilteredPincodeList(InputText: string): Observable<gResponseMessage<PagedList<ICityPincodeDto>>> {
    this.apiEndpoint = API_ENDPOINTS.Pincode.list;
    let querySpecs: IQuerySpecs = this._getFilter('PinCode',InputText);
    return this._http.post<gResponseMessage<PagedList<ICityPincodeDto>>>(this.apiEndpoint, querySpecs, httpOptions);
  }
  public getFilteredDesignationList(InputText: string): Observable<gResponseMessage<PagedList<IDesignationDto>>> {
    this.apiEndpoint = API_ENDPOINTS.Designation.List;
    let querySpecs: IQuerySpecs = this._getFilter('Name',InputText);
    return this._http.post<gResponseMessage<PagedList<IDesignationDto>>>(this.apiEndpoint, querySpecs, httpOptions);
  }
  public getFilteredBranchList(InputText: string): Observable<gResponseMessage<PagedList<IBranchDto>>> {
    this.apiEndpoint = API_ENDPOINTS.Branch.List;
    let querySpecs: IQuerySpecs = this._getFilter('Name',InputText);
    return this._http.post<gResponseMessage<PagedList<IBranchDto>>>(this.apiEndpoint, querySpecs, httpOptions);
  }
  public getFilteredDepartmentList(InputText: string): Observable<gResponseMessage<PagedList<IDepartmentDto>>> {
    this.apiEndpoint = API_ENDPOINTS.Department.List;
    let querySpecs: IQuerySpecs = this._getFilter('Name',InputText);
    return this._http.post<gResponseMessage<PagedList<IDepartmentDto>>>(this.apiEndpoint, querySpecs, httpOptions);
  }

  public getFilteredCountryList(InputText: string): Observable<gResponseMessage<PagedList<ICountryDto>>> {
    this.apiEndpoint = API_ENDPOINTS.Country.List;
    let querySpecs: IQuerySpecs = this._getFilter('Name',InputText);
    return this._http.post<gResponseMessage<PagedList<ICountryDto>>>(this.apiEndpoint, querySpecs, httpOptions);
  }

  public getFilteredPolicyCountryList(InputText: string): Observable<gResponseMessage<PagedList<ICountryDto>>> {
    this.apiEndpoint = API_ENDPOINTS.Country.List;
    let querySpecs: IQuerySpecs = this._getFilter('Name',InputText);
    querySpecs.FilterConditions.Rules = querySpecs.FilterConditions.Rules.concat([{Field:'Id', Operator:'ne', Value:'1'}]);
    return this._http.post<gResponseMessage<PagedList<ICountryDto>>>(this.apiEndpoint, querySpecs, httpOptions);
  }

  public getFilteredCityList(InputText: string): Observable<gResponseMessage<PagedList<ICityDto>>> {
    this.apiEndpoint = API_ENDPOINTS.City.List;
    let querySpecs: IQuerySpecs = this._getFilter('Name',InputText);
    return this._http.post<gResponseMessage<PagedList<ICityDto>>>(this.apiEndpoint, querySpecs, httpOptions);
  }

  public getFilteredCommodityList(InputText: string, showall: boolean = false): Observable<gResponseMessage<PagedList<ICommodityDto>>> {
    this.apiEndpoint = API_ENDPOINTS.StaticList.Commodity;
    let querySpecs: IQuerySpecs = this._getFilter('CommodityName',InputText);
    if(!showall) {
      querySpecs.FilterConditions.Rules = querySpecs.FilterConditions.Rules.concat([{Field:'Id', Operator:'ne', Value:'7'}]);
      querySpecs.FilterConditions.Rules = querySpecs.FilterConditions.Rules.concat([{Field:'Id', Operator:'ne', Value:'8'}]);
      querySpecs.FilterConditions.Rules = querySpecs.FilterConditions.Rules.concat([{Field:'Id', Operator:'ne', Value:'20'}]);
    }
    querySpecs.OrderBySpecs = [{field: 'CommodityCode', direction: 'asc'}];
    return this._http.post<gResponseMessage<PagedList<ICommodityDto>>>(this.apiEndpoint, querySpecs, httpOptions);
  }

  public getFilteredTermsOfSaleList(InputText: string, TransitTypeId: string): Observable<gResponseMessage<PagedList<ITermsOfSaleDto>>> {
    this.apiEndpoint = API_ENDPOINTS.StaticList.TermsOfSale;
    let querySpecs: IQuerySpecs = this._getFilter('Termsofsale',InputText);
    querySpecs.FilterConditions.Rules = querySpecs.FilterConditions.Rules.concat([{Field:'TransitTypeId', Operator:'eq', Value:TransitTypeId}]);
    return this._http.post<gResponseMessage<PagedList<ITermsOfSaleDto>>>(this.apiEndpoint, querySpecs, httpOptions);
  }

  public getFilteredTransitModeList(InputText: string): Observable<gResponseMessage<PagedList<ITransitModeDto>>> {
    this.apiEndpoint = API_ENDPOINTS.StaticList.TransitMode;
    let querySpecs: IQuerySpecs = this._getFilter('Transitmode',InputText);
    return this._http.post<gResponseMessage<PagedList<ITransitModeDto>>>(this.apiEndpoint, querySpecs, httpOptions);
  }

  public getFilteredTransitModeOdcList(InputText: string): Observable<gResponseMessage<PagedList<ITransitModeDto>>> {
    this.apiEndpoint = API_ENDPOINTS.StaticList.TransitMode;
    let querySpecs: IQuerySpecs = this._getFilter('Transitmode',InputText);
    querySpecs.FilterConditions.Rules = querySpecs.FilterConditions.Rules.concat([{Field:'Id', Operator:'in', Value:['1','3','4']}]);
    return this._http.post<gResponseMessage<PagedList<ITransitModeDto>>>(this.apiEndpoint, querySpecs, httpOptions);
  }

  public getFilteredTransitModeContainerList(InputText: string): Observable<gResponseMessage<PagedList<ITransitModeDto>>> {
    this.apiEndpoint = API_ENDPOINTS.StaticList.TransitMode;
    let querySpecs: IQuerySpecs = this._getFilter('Transitmode',InputText);
    querySpecs.FilterConditions.Rules = querySpecs.FilterConditions.Rules.concat([{Field:'Id', Operator:'in', Value:['3','4']}]);
    return this._http.post<gResponseMessage<PagedList<ITransitModeDto>>>(this.apiEndpoint, querySpecs, httpOptions);
  }

  public getFilteredPackagingList(InputText: string, transitmode: string): Observable<gResponseMessage<PagedList<IPackagingDto>>> {
    this.apiEndpoint = API_ENDPOINTS.StaticList.Packaging;
    let querySpecs: IQuerySpecs = this._getFilter('PackagingName',InputText);
    querySpecs.FilterConditions.Rules = querySpecs.FilterConditions.Rules.concat([{Field:'TransitMode', Operator:'eq', Value:transitmode}]);
    return this._http.post<gResponseMessage<PagedList<IPackagingDto>>>(this.apiEndpoint, querySpecs, httpOptions);
  }

  public getFilteredBasisOfValuationList(InputText: string, transittypeid: string, commodityid: string): Observable<gResponseMessage<PagedList<IBasisOfValuationDto>>> {
    this.apiEndpoint = API_ENDPOINTS.StaticList.BasisOfValuation;
    let querySpecs: IQuerySpecs = this._getFilter('BasisOfValuationName',InputText);
    querySpecs.FilterConditions.Rules = querySpecs.FilterConditions.Rules.concat([{Field:'TransitTypeId', Operator:'eq', Value:transittypeid}]);
    querySpecs.FilterConditions.Rules = querySpecs.FilterConditions.Rules.concat([{Field:'CommodityId', Operator:'eq', Value:commodityid}]);
    return this._http.post<gResponseMessage<PagedList<IBasisOfValuationDto>>>(this.apiEndpoint, querySpecs, httpOptions);
  }

  public getFilteredCoverageList(InputText: string, transitmode: string): Observable<gResponseMessage<PagedList<ICoverageDto>>> {
    this.apiEndpoint = API_ENDPOINTS.StaticList.Coverage;
    let querySpecs: IQuerySpecs = this._getFilter('Coveragetype',InputText);
    querySpecs.FilterConditions.Rules = querySpecs.FilterConditions.Rules.concat([{Field:'TransitModeId', Operator:'eq', Value:transitmode}]);
    return this._http.post<gResponseMessage<PagedList<ICoverageDto>>>(this.apiEndpoint, querySpecs, httpOptions);
  }

  public getFilteredCoverageDetailList(InputText: string, commodityid: string, transittypeid: string, transitmodeid: string, termsofsaleid?: string): Observable<gResponseMessage<PagedList<ICoverageDto>>> {
    this.apiEndpoint = API_ENDPOINTS.StaticList.CoverageDetail;
    let querySpecs: IQuerySpecs = this._getAgentFilter('CoverageTypeNameFilter',InputText);
    querySpecs.AdditionalFilters.push({key: 'CommodityIdFilter', filterValues: [commodityid]});
    querySpecs.AdditionalFilters.push({key: 'TransitTypeIdFilter', filterValues: [transittypeid]});
    querySpecs.AdditionalFilters.push({key: 'TransitModeIdFilter', filterValues: [transitmodeid]});
    if(termsofsaleid) {
      querySpecs.AdditionalFilters.push({key: 'TermsOfSaleIdFilter', filterValues: [termsofsaleid]});
    }
    return this._http.post<gResponseMessage<PagedList<ICoverageDto>>>(this.apiEndpoint, querySpecs, httpOptions);
  }

  public getFilteredContainerCoverageList(InputText: string, transitmode: string): Observable<gResponseMessage<PagedList<ICoverageDto>>> {
    this.apiEndpoint = API_ENDPOINTS.StaticList.Coverage;
    let querySpecs: IQuerySpecs = this._getFilter('Coveragetype',InputText);
    querySpecs.FilterConditions.Rules = querySpecs.FilterConditions.Rules.concat([{Field:'TransitModeId', Operator:'eq', Value:transitmode}]);
    querySpecs.FilterConditions.Rules = querySpecs.FilterConditions.Rules.concat([{Field:'Id', Operator:'ne', Value:6}]);
    querySpecs.FilterConditions.Rules = querySpecs.FilterConditions.Rules.concat([{Field:'Id', Operator:'ne', Value:9}]);
    return this._http.post<gResponseMessage<PagedList<ICoverageDto>>>(this.apiEndpoint, querySpecs, httpOptions);
  }

  public getFilteredSettingAgentList(InputText: string, settlingagentcountry: any): Observable<gResponseMessage<PagedList<ISettlingAgentDetailsDto>>> {
    this.apiEndpoint = API_ENDPOINTS.SettlingAgent.List;
    let querySpecs: IQuerySpecs = this._getFilter('SettlingAgent.Name',InputText);
    querySpecs.FilterConditions.Rules = querySpecs.FilterConditions.Rules.concat([{Field: 'CountryId', Value: settlingagentcountry, Operator: 'eq'}])
    return this._http.post<gResponseMessage<PagedList<ISettlingAgentDetailsDto>>>(this.apiEndpoint, querySpecs, httpOptions);
  }

  public getFilteredCurrenciesList(InputText: string): Observable<gResponseMessage<PagedList<ICurrencyDto>>> {
    this.apiEndpoint = API_ENDPOINTS.Currency.List;
    let querySpecs: IQuerySpecs = this._getFilter('FormalName',InputText);
    return this._http.post<gResponseMessage<PagedList<ICurrencyDto>>>(this.apiEndpoint, querySpecs, httpOptions);
  }

  public getFilteredCompanyTypeList(InputText: string): Observable<gResponseMessage<PagedList<ICompanyTypeDto>>> {
    this.apiEndpoint = API_ENDPOINTS.StaticList.CompanyType;
    let querySpecs: IQuerySpecs = this._getFilter('Companytype',InputText);
    return this._http.post<gResponseMessage<PagedList<ICompanyTypeDto>>>(this.apiEndpoint, querySpecs, httpOptions);
  }

  public getFilteredAgentsList(InputText: string): Observable<gResponseMessage<PagedList<IAgentDto>>> {
    this.apiEndpoint = API_ENDPOINTS.Agent.List;
    let querySpecs: IQuerySpecs = this._getAgentFilter('AgentName',InputText);
    querySpecs.FilterConditions.Rules = querySpecs.FilterConditions.Rules.concat({Field: 'ApprovalRejectStatus', Operator: 'in', Value: ['100', '99']})
    return this._http.post<gResponseMessage<PagedList<IAgentDto>>>(this.apiEndpoint, querySpecs, httpOptions);
  }

  public getFilteredProductsList(InputText: string): Observable<gResponseMessage<PagedList<IProductDto>>> {
    this.apiEndpoint = API_ENDPOINTS.StaticList.Product;
    let querySpecs: IQuerySpecs = this._getFilter('ProductCode',InputText);
    return this._http.post<gResponseMessage<PagedList<IProductDto>>>(this.apiEndpoint, querySpecs, httpOptions);
  }

  public getFilteredTransitTypesList(InputText: string): Observable<gResponseMessage<PagedList<ITransitTypeDto>>> {
    this.apiEndpoint = API_ENDPOINTS.StaticList.TransitType;
    let querySpecs: IQuerySpecs = this._getFilter('Transittype',InputText);
    querySpecs.FilterConditions.Rules = querySpecs.FilterConditions.Rules.concat([{Field:'Id', Operator:'ne', Value:'5'}]);
    return this._http.post<gResponseMessage<PagedList<ITransitTypeDto>>>(this.apiEndpoint, querySpecs, httpOptions);
  }

  public getFilteredSizesList(InputText: string, commodityid: string): Observable<gResponseMessage<PagedList<IContainerSizeDto>>> {
    this.apiEndpoint = API_ENDPOINTS.Container.List;
    let querySpecs: IQuerySpecs = this._getFilter('ContainerSize',InputText);
    querySpecs.FilterConditions.Rules = querySpecs.FilterConditions.Rules.concat([{Field:'CommodityId', Operator:'eq', Value:commodityid}]);
    querySpecs.FilterConditions.Rules = querySpecs.FilterConditions.Rules.concat([{Field:'Id', Operator:'ne', Value:'16'}]);
    querySpecs.FilterConditions.Rules = querySpecs.FilterConditions.Rules.concat([{Field:'Id', Operator:'ne', Value:'17'}]);
    querySpecs.FilterConditions.Rules = querySpecs.FilterConditions.Rules.concat([{Field:'Id', Operator:'ne', Value:'18'}]);
    return this._http.post<gResponseMessage<PagedList<IContainerSizeDto>>>(this.apiEndpoint, querySpecs, httpOptions);
  }

  public getFilteredContainerCommodityList(InputText: string): Observable<gResponseMessage<PagedList<ICommodityDto>>> {
    this.apiEndpoint = API_ENDPOINTS.StaticList.Commodity;
    let querySpecs: IQuerySpecs = this._getFilter('CommodityName',InputText);
    querySpecs.FilterConditions.Rules = querySpecs.FilterConditions.Rules.concat([{Field:'Id', Operator:'in', Value:['7','8','20']}]);
    return this._http.post<gResponseMessage<PagedList<ICommodityDto>>>(this.apiEndpoint, querySpecs, httpOptions);
  }



  private _getFilter(Key: string,inputtext: string): IQuerySpecs {
    return {
      PaginationSpecs: {
        PaginationRequired: true,
        Page: 1,
        Limit: 100,
      },
      FilterConditions: {
        Condition: "and",
        Rules: [
          {
            Field: Key,
            Value: inputtext,
            Operator: "contains",
          },
        ],
      },
      OrderBySpecs: [],
      AdditionalFilters: [],
    };
  }

  private _getAgentFilter(Key: string,inputtext: string): IQuerySpecs {
    return {
      PaginationSpecs: {
        PaginationRequired: true,
        Page: 1,
        Limit: 100,
      },
      FilterConditions: {
        Condition: "and",
        Rules: [],
      },
      OrderBySpecs: [],
      AdditionalFilters: [{key: Key, filterValues: [inputtext]}],
    };
  }

}
