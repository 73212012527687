import { inject } from "@angular/core";
import { CanMatchFn, Route, Router, UrlSegment, UrlTree } from "@angular/router";
import { AuthService } from "@services/auth/auth.service";
import { Observable, take, switchMap, of } from "rxjs";

export const isAuthenticated: CanMatchFn = (route: Route, segments: UrlSegment[]): | boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> => {
  const authService: AuthService = inject(AuthService);
  const router = inject(Router);
  return authService.isLoggedIn$.pipe(
    take(1),
    switchMap((isAuthenticated) => {
      if (!isAuthenticated) {
        // Redirect to the sign-in page with a redirectUrl param
        const redirectURL = `/${segments.join('/')}`;
        const urlTree = router.parseUrl(`auth/login?redirectURL=${redirectURL}`);
        return of(urlTree);
      }
      return of(true);
    })
  );
}

export const isAnonymous: CanMatchFn = (route: Route, segments: UrlSegment[]): | boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> => {
  const authService: AuthService = inject(AuthService);
  //Allow only when user is not logged In
  return authService.isLoggedIn$.pipe(
    take(1),
    switchMap((isAuthenticated) => of(!isAuthenticated))
  );
}
