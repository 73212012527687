import { Component } from '@angular/core';

@Component({
  selector: 'gnx-public-layout',
  templateUrl: './public-layout.component.html',
  styleUrls: ['./public-layout.component.scss'],
  // encapsulation: ViewEncapsulation.None
})
export class PublicLayoutComponent {

}
