import { PageUnderConstructionModule } from './lib/ui/components/page-under-construction/page-under-construction.module';
import { NgModule } from '@angular/core';
import { ExtraOptions, PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { LayoutComponent } from "@lib/ui/layouts/layout.component";
import { NotFoundComponent } from "./features/common/error-handling/not-found/not-found.component";
import { AuthLayoutComponent } from "./lib/ui/layouts/auth-layout/auth-layout.component";
import { PublicLayoutComponent } from "./lib/ui/layouts/public-layout/public-layout.component";
import { isAuthenticated, isAnonymous } from "./shared/guards/authGuard-functions";
import { PageUnderConstructionComponent } from '@lib/ui/components/page-under-construction/page-under-construction.component';

// Routing Configuration
const routingConfig: ExtraOptions = {
  preloadingStrategy: PreloadAllModules,
  scrollPositionRestoration: 'enabled'
};
const routes: Routes = [
  {
    path: '',
    redirectTo: 'app',
    pathMatch: 'full'
  },
  {
    path: '',
    canMatch: [isAnonymous],
    component: PublicLayoutComponent,
    children: [
      {
        path: '',
        loadChildren: () => import('./features/landing/landing.module').then(m => m.LandingModule)
      }
    ]
  },
  {
    path: 'app',
    canMatch: [isAuthenticated],
    component: LayoutComponent,
    children: [
      {
        path: '',
        loadChildren: () => import('./features/main/home/home.module').then(m => m.HomeModule)
      },
      // {
      //   path: 'sales',
      //   children: [
      //     {
      //       path: 'sales-order',
      //       component: PageUnderConstructionComponent
      //     },
      //     {
      //       path: 'invoice',
      //       component: PageUnderConstructionComponent
      //     },
      //     {
      //       path: 'returns',
      //       component: PageUnderConstructionComponent
      //     },
      //     {
      //       path: 'credit-note',
      //       component: PageUnderConstructionComponent
      //     },
      //   ]
      // },

      // {
      //   path: 'message',
      //   children: [
      //     {
      //       path: 'mobile-notification',
      //       component: PageUnderConstructionComponent
      //     }
      //   ]
      // },
      {
        path: 'masters',
        loadChildren: () => import('./features/main/masters/masters.module').then(m => m.MastersModule)
      },
      {
        path: 'settings',
        loadChildren: () => import('./features/main/settings/settings.module').then(m => m.SettingsModule)
      },
      {
        path: 'insurance',
        loadChildren: () => import('./features/policy/policy/policy.module').then(m => m.PolicyModule)
      },
      {
        path: 'claimprocess',
        loadChildren: () => import('./features/claim-process/claim-process.module').then(m => m.ClaimProcessModule)
      },
      {
        path: 'reports',
        loadChildren: () => import('./features/reports/reports.module').then(m => m.ReportsModule)
      },
      {
        path: 'error',
        loadChildren: () => import('./features/common/error-handling/error-handling.module').then(m => m.ErrorHandlingModule)
      },
      {
        path: '**',
        redirectTo: 'error/under-construction',
        pathMatch: 'full'
      }
    ]
  },
  {
    path: '',
    canMatch: [isAnonymous],
    component: AuthLayoutComponent,
    children: [
      {
        path: 'auth',
        loadChildren: () => import('./features/auth/auth.module').then(m => m.AuthModule)
      }
    ]
  },
  {
    path: 'payment',
    component: PublicLayoutComponent,
    children: [
      {
        path: '',
        loadChildren: () => import('./features/payment/payment.module').then(m => m.PaymentModule)
      }
    ]
  },
  {
    path: 'error',
    component: PublicLayoutComponent,
    children: [
      {
        path: '',
        loadChildren: () => import('./features/common/error-handling/error-handling.module').then(m => m.ErrorHandlingModule)
      }
    ]
  },
  {
    path: '**',
    redirectTo: 'error/404',
    pathMatch: 'full'
  }
];

@NgModule({
  imports:
  [
    RouterModule.forRoot(routes),
    PageUnderConstructionModule
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
