import { expandCollapse } from "./expand-collapse";
import { fadeIn, fadeOut } from './fade';
import { slideInBottom, slideInLeft, slideInRight, slideInTop, slideOutBottom, slideOutLeft, slideOutRight, slideOutTop } from "./slide";
export const gnxAnimations = [
  fadeIn,
  fadeOut,
  slideInBottom,
  slideInTop,
  slideOutTop,
  slideOutBottom,
  slideInLeft,
  slideOutLeft,
  slideInRight,
  slideOutRight,
  expandCollapse
]
