import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { httpOptions } from '@config/httpOptions';
import { IQuerySpecs, ResponseMessage } from '@models/common';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class HttpService {
  private _apiEndpoint: string;
  constructor(private _http: HttpClient) { }



  /**
   * GET
   * @param id
   * @param api
   * @returns
   */
  public getDataById(id: any, api: string): Observable<any> {
    this._apiEndpoint = api + "/" + id;
    return this._http.get<any>(this._apiEndpoint, httpOptions);
  }

  public Download(id: number, api): Observable<Blob> {
    this._apiEndpoint = api + "/" + id;
    return this._http.get(this._apiEndpoint, { responseType: "blob" });
  }


  /**
   * post
   * @param id
   * @param api
   * @returns
   */

  public getDataList(querySpec: any, api: string){
    this._apiEndpoint = api + "/list" ;
    return this._http.post<any>(this._apiEndpoint, querySpec, httpOptions);
  }

  public exportData(querySpec: any, api: string){
    this._apiEndpoint = api + "/ExportData" ;
    return this._http.post<any>(this._apiEndpoint, querySpec, {observe: 'response', responseType: "blob" as "json"});
  }


  createData(body: any , api:string): Observable<ResponseMessage> {
    this._apiEndpoint = api;
    return this._http.post<ResponseMessage>(this._apiEndpoint, body, httpOptions);
  }

  public getData(querySpec: any, api: string) {
    this._apiEndpoint = api ;
    return this._http.post<any>(this._apiEndpoint, querySpec, httpOptions);
  }

  public getEmail(api: string) {
    this._apiEndpoint = api;
    return this._http.get<any>(this._apiEndpoint, httpOptions);
  }

  /**
   * put
   * @param id
   * @param api
   * @returns
   */
  public updateData(data: any, api: string) {
    this._apiEndpoint = api ;
    return this._http.put<any>(this._apiEndpoint  , data, httpOptions);
  }


  /**
   * delete
   * @param id
   * @param api
   * @returns
   */
  deleteData(id:string,api:string): Observable<ResponseMessage> {
    this._apiEndpoint = api + "/" + id;
    return this._http.delete<ResponseMessage>(this._apiEndpoint, httpOptions);
  }



  /**
  * Upload Document
  * @param api : API
  * @param File : Document Upload file
  * @returns
  */
  public uploadDoc(api: string, File: any): Observable<ResponseMessage> {
    let header = new HttpHeaders();
    header.append("Content-Type", "multipart/form-data");
    this._apiEndpoint = api;
    return this._http.post<ResponseMessage>(this._apiEndpoint, File, {
      headers: header,
    });
  }

  public UploadFile(api: string, file: File): Observable<ResponseMessage> {
    const formData = new FormData();
    formData.append("attachmentFile", file, file.name);
    this._apiEndpoint = api;
    return this._http.post<ResponseMessage>(this._apiEndpoint, formData);
  }





}
