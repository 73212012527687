<!-- App level alert -->
<gnx-header-alert></gnx-header-alert>

<!-- App Top Navbar -->
<gnx-toolbar></gnx-toolbar>

<div class="app-main" [ngClass]="{'sidenav-right': sidenavRight}">
  <gnx-sidenav></gnx-sidenav>
  <div class="content-wrapper">
    <!-- this div will make router-outlet's child-component's height full   -->
    <div class="h-full">
      <router-outlet></router-outlet>
    </div>
  </div>
</div>
