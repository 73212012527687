import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpResponse,
} from '@angular/common/http';
import { Observable, tap } from 'rxjs';
import { Alert } from '@models/common/alert.interface';
import { ResponseMessage } from '@models/common';
import { Store } from "@ngrx/store";
import { alertRaise } from "@lib/ui/store/uiState/ui.actions";
import { HelperService } from "@lib/services/helper.service";
import { GnxHeaderAlertService } from "@lib/ui/components/header-alert/header-alert.service";
import { AlertsService } from "@lib/services/error-handling/alerts.service";
import { AuthService } from '@services/auth/auth.service';

@Injectable()
export class ApiResponseInterceptor implements HttpInterceptor {
  private alert: Alert;

  constructor(
    private _helper: HelperService,
    private _store: Store,
    private _headerAlertService: GnxHeaderAlertService,
    private _authService: AuthService,
    private _alertsService: AlertsService
  ) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      tap((evt) => {
        if (evt instanceof HttpResponse) {
          if (evt instanceof HttpResponse) {
            if (evt.body) {
              let resp = evt.body as ResponseMessage;
              // logout user when session expires
              if(resp.ResCode == 1106){
                this._authService.signOut()
              }
              if (resp.Alerts && resp.Alerts.length > 0) {
                if (resp.Alerts[0].Code == 1405){
                  this._authService.signOut()
                }
                else {
                  this._processAlerts(resp.Alerts);
                }
              }
            }
          }
        }
      })
    );
  }

  private _processAlerts(alerts: Alert[]): void {
    let headerAlert: Alert = {
      Message: 'Sample header alert for test purpose',
      CanDismiss: true,
      AutoClose: false,
      Level: 'header',
      Type: 'error'
    };

    if (!alerts || alerts.length == 0) {
      return
    }

    alerts.forEach(alert => {

      //ignore success alerts for timebeing
      if (alert.Type === 'success') {
        return;
      }

      if (!(alert.Type == 'error' || alert.Type == 'warning')) {
        alert.CanDismiss = true;
      }

      if (!alert.Id) {
        alert.Id = this._helper.randomId()
      }
      if (!alert.Level) {
        alert.Level = 'page';
      }

      if (!alert.Type) {
        alert.Type = 'error';
      }

      switch (alert.Type) {
        case 'error':
        case'warning':
          if (alert.AutoClose === null) {
            alert.AutoClose = false;
          }
          if (alert.CanDismiss === null) {
            alert.CanDismiss = true;
          }
          break;
        default:
          if (alert.AutoClose === null) {
            alert.AutoClose = true;
          }
          if (alert.CanDismiss === null) {
            alert.CanDismiss = true;
          }
          break;
      }

      if (alert.Level === 'header') {
        this._alertsService.raiseHeaderAlert(alert);
      } else if(alert.Level === 'page') {
        this._alertsService.raiseAlert(alert);
      }
    });

  }
}