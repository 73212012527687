import { BooleanInput } from '@angular/cdk/coercion';
import { ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { IUserProfile } from '@models/dtos/auth';
import { AuthService } from '@services/auth/auth.service';
import { Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'gnx-balance',
  templateUrl: './balance.component.html',
  styleUrls: ['./balance.component.scss']
})
export class BalanceComponent implements OnInit, OnDestroy {
  /* eslint-disable @typescript-eslint/naming-convention */
  static ngAcceptInputType_showAvatar: BooleanInput;
  /* eslint-enable @typescript-eslint/naming-convention */

  @Input() showAvatar: boolean = true;
  user: IUserProfile;

  private destroy$: Subject<any> = new Subject<any>();

  /**
   * Constructor
   */
  constructor(
    private cdr: ChangeDetectorRef,
    private router: Router,
    private authService: AuthService
  ) {
  }


  // -----------------------------------------------------------------------------------------------------
  // @ Lifecycle hooks
  // -----------------------------------------------------------------------------------------------------

  /**
   * On init
   */
  ngOnInit(): void {
    // Subscribe to user changes
    this.authService.userProfile$
      .pipe(takeUntil(this.destroy$))
      .subscribe((user: IUserProfile) => {
        this.user = user;

        // Mark for check
        this.cdr.markForCheck();
      });
  }

  ngOnDestroy(): void {
    this.destroy$.next(null);
    this.destroy$.complete();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
  * Signout
  *
  */
  signOut(): void {
    this.authService.signOut();
  }

  /**
  * Update the user status
  *
  * @param status
  */
  updateUserStatus(status: string): void {
    // Return if user is not available
    if (!this.user) {
      return;
    }

    // Update the user
    // this.authService.update({
    //   ...this.user,
    //   status
    // }).subscribe();
  }
}
