//Framework
import { ErrorHandler, NgModule, isDevMode } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";

//Material imports
import { MatSnackBarModule } from "@angular/material/snack-bar";

//Store imports
import { StoreModule } from '@ngrx/store';
import { uiStateReducer } from "@lib/ui/store/uiState/uiState.reducer";

//App imports
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { IconsModule } from "./lib/ui/icons/icons.module";
import { ProgressBarInterceptor } from "./lib/services/progressBar/progress-bar-interceptor";
import { LayoutsModule } from "./lib/ui/layouts/layouts.module";
import { TokenInterceptor } from "./shared/interceptors/token.interceptor";
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { HttpErrorInterceptor } from './shared/interceptors/http-error.interceptor';
import { ApiResponseInterceptor } from './shared/interceptors/api-response.interceptor';
import { GlobalErrorHandler } from "@lib/services/error-handling/global-error-handler";
import { DialogModule } from '@lib/ui/components/dialog/dialog.module';
import { MatNativeDateModule } from '@angular/material/core';
import { MatRadioModule } from '@angular/material/radio';
import { SharedMaterialModule } from './shared/shared-material';
import { ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { TableListModule } from '@lib/ui/components/table-list/table-list.module';
import { GenPopupComponent } from './lib/ui/components/gen-popup/gen-popup.component';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import { MatChipsModule } from '@angular/material/chips';
import {MatTableModule} from '@angular/material/table';
import { RemarksModule } from '@lib/ui/components/remarks popup/remarks.module';
import { EmailMobilePopupModule } from '@lib/ui/components/email-mobile-popup/email-mobile-popup.module';

@NgModule({
  declarations: [ 
    AppComponent,
    GenPopupComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,
    MatSnackBarModule,
    DialogModule,
    IconsModule,
    LayoutsModule,
    MatNativeDateModule,
    SharedMaterialModule,
    MatRadioModule,
    StoreModule.forRoot({uiState: uiStateReducer}),
    StoreDevtoolsModule.instrument({ maxAge: 25, logOnly: !isDevMode() }),
    ReactiveFormsModule,
    MatButtonModule,
    TableListModule,
    MatSlideToggleModule,
    MatChipsModule,
    MatTableModule,
    RemarksModule,
    EmailMobilePopupModule
  ],
  providers: [
    {
      provide: ErrorHandler,
      useClass: GlobalErrorHandler
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ProgressBarInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpErrorInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ApiResponseInterceptor,
      multi: true
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
