import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { LocalStorageService } from "src/app/lib/services/local-storage.service";

@Injectable()
export class TokenInterceptor implements HttpInterceptor {

  constructor(private ls: LocalStorageService) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    //Fetch token stored in local storage
    let token = this.ls.getItem("token");

    //IF token found, attach the request, else ignore
    if (token) {
      request = request.clone({
        setHeaders: {
          Authorization: `Bearer ${token}`
        }
      });
    }
    return next.handle(request);
  }
}
