import { Component, Inject } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router, ActivatedRoute } from '@angular/router';
import { StatusOptions } from '@config/status.config';
import { DialogService } from '@lib/services/dialog.service';
import { AlertsService } from '@lib/services/error-handling/alerts.service';
import { HelperService } from '@lib/services/helper.service';
import { HttpService } from '@lib/services/http/http.service';
import { CityDto } from '@models/dtos/core';
import { NotificationRemarksComponent } from '../notificationremarks/notificationremarks.component';
import { API_ENDPOINTS } from '@config/api-endpoints.config';

@Component({
  selector: 'gnx-invoiceupdate',
  templateUrl: './invoiceupdate.component.html',
  styleUrls: ['./invoiceupdate.component.scss']
})
export class InvoiceUpdateComponent {


  // #region public variables

  // Strings
  title: string = '';
  Code: string;
  statusOption = StatusOptions

  // FormGroup
  NotificationRemarksForm: FormGroup;
  cityFrom: CityDto
  addCityForm: any
  currentBalance: any;
  oldPremium: any;
  editable: boolean;
  // Errors
  errors: unknown;

  stateList;

  // #endregion public variables

  /**
   * #region constructor
   * @param _location : used for back or prev page navigation
   * @param _fb : Formbuilder
   * @param _router: module for routing
   * @param _route: used to get current route
   */
  constructor(
    private _fb: FormBuilder,
    private _dataService:HttpService,
    private _router: Router,
    private _route: ActivatedRoute,
    private _alertservice: AlertsService,
    public _helperservice: HelperService,
    public dialogRef: MatDialogRef<NotificationRemarksComponent>,
    private _dialogService: DialogService,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      type: string;
      title: string;
      isMultiple: boolean;
      data?: any;
      policydto: any;
    }
  ) {
  }


  ngOnInit(): void {
    this._init();
    this.NotificationRemarksForm.patchValue(this.data.policydto)
    this.editable = true;
    this.oldPremium = this.NotificationRemarksForm.get('CustomerTotalPremiumAmt').value;
    this._dataService.getDataById(this.NotificationRemarksForm.get('AgentId').value, API_ENDPOINTS.Agent.Base).subscribe((res) => {
      this.currentBalance = res.Data.CurrentBalance;
    })

  }
  // #endregion constructor

  // #region getters

  get f() {
    return this.NotificationRemarksForm.controls
  }

  // #endregion getters

  /**
   * #region public methods
   */

  // submit or save action
  submitform = () => {
    if((this.currentBalance - (this.NotificationRemarksForm.get('CustomerTotalPremiumAmt').value - this.oldPremium)) < 0) {
      this._alertservice.raiseErrorAlert('Invoice Difference Amount exceeds Wallet Balance.');
    }
    else if ((this.NotificationRemarksForm.get('CustomerTotalPremiumAmt').value < this.NotificationRemarksForm.get('TotalPremiumAmt').value)) {
      this._alertservice.raiseErrorAlert('Invoice Amount can not be less than Total Premium.');
    }
    else {
      this._dialogService.confirmDialog({
        title: 'Are You Sure?',
        message: "You won't be able to revert this",
        confirmText: 'Yes',
        cancelText: "No"
      }).subscribe(res => {
        if (res) {
        this.dialogRef.close(this.NotificationRemarksForm.value);
      }
    });
    }
  };

  // previous page navigation button
  public backClicked() {
    this.dialogRef.close();
  }

  // #endregion public methods
  /**
   * #region private methods
   */

  private _init(): FormGroup {
    this.NotificationRemarksForm = this._fb.group({
      TotalPremiumAmt: [null],
      CustomerTotalPremiumAmt: [null],
      AgentId: [null],
      RRNo: [''],
      RRDate: [''],
      RailAuthorityName: [''],
      BuyerConsignee: [''],
      SellerConsignor: [''],
      JobNo: [''],
      AdditionalInfo: [''],
      PurchaseTransitModeId: [null],
      PurchaseTransitModeName: [''],
      SalesTransitModeId: [null],
      SalesTransitModeName: [''],
      TransitTypeId: [null],
    });

    return this.NotificationRemarksForm;
  }

}

