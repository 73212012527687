// #region imports
import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  AbstractControl,
  FormArray,
  FormBuilder,
  FormGroup,
  Validators,
} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { IAppAlert } from '@lib/types';
import { AuthService } from '@services/auth/auth.service';
import { Observable, Subject, of, switchMap, takeUntil } from 'rxjs';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { GenPopupComponent } from '@lib/ui/components/gen-popup/gen-popup.component';
import {
  AttachmentDetailDto,
  AttachmentDetailsDto,
  IAttachmentDetailDto,
  IAttachmentDetailsDto,
} from '@models/dtos/core/agent-dto';
import { MatTableDataSource } from '@angular/material/table';
import { HttpService } from '@lib/services/http/http.service';
import { API_ENDPOINTS } from '@config/api-endpoints.config';
import { AlertsService } from '@lib/services/error-handling/alerts.service';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { ICityDto, ICityPincodeDto } from '@models/dtos/core';
import { MasterListService } from '@lib/services/master-list.service';
import { ICompanyTypeDto } from '@models/dtos/config/CompanyTypeDto';
import { Alert } from '@models/common';
import { siteKey } from 'src/environments/environment';
import { DialogService } from '@lib/services/dialog.service';
// #endregion imports

@Component({
  selector: 'gnx-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss'],
})
export class RegisterComponent implements OnInit, OnDestroy {
  // #region public variables
  submitted: boolean;
  hide = true;
  hideconfirm = true;
  Cities$: Observable<ICityDto[]>;
  pincodes$: Observable<ICityPincodeDto[]>;
  Companies$: Observable<ICompanyTypeDto[]>;
  destroy$: Subject<any>;
  UploadFileAPI = API_ENDPOINTS.Attachment.Upload;
  GSTNoAPI = API_ENDPOINTS.Auth.GST;
  PANNoAPI = API_ENDPOINTS.Auth.PAN;
  lastgst: string = '';
  lastpan: string = '';
  sitekey: string = siteKey;
  // CityName = new FormControl();

  DocumentDataSource: MatTableDataSource<AbstractControl>;
  DisplayedColumns: string[] = ['DocumentType', 'Attachment', 'Action'];
  // FormGroup
  registerForm: FormGroup;

  //Error Message
  alerts: Alert[] = [];
  error: IAppAlert = {
    message: '',
    type: 'error',
    canDismiss: false,
    appearance: 'outline',
  };
  // #endregion public variables

  private _returnUrl: string;
  private _destroy$: Subject<any>;
  /**
   * #region constructor
   * @param _fb : formbuilder
   * @param _router : router module
   * @param _authservice : authservice
   * @param _alertservice : alertservice for dynamic rendering of errors messages
   */
  constructor(
    private _fb: FormBuilder,
    private _router: Router,
    private _authservice: AuthService,
    private _route: ActivatedRoute,
    private _dataService: HttpService,
    private _alertsService: AlertsService,
    private _MasterListService: MasterListService,
    public dialog: MatDialog,
    private _dialogService: DialogService
  ) {
    this.submitted = false;

    this._initForm();
    this.DocumentDataSource = new MatTableDataSource([]); 
    this.DocumentDataSource.data = this.af.controls;
    this._destroy$ = new Subject();
  }
  // #endregion constructor

  onChange(event, type: string) {
    if (type == 'AgentType') {
      if (event.value == 'Company') {
        this.f['FirstName'].setValue('');
        this.f['MiddleName'].setValue('');
        this.f['LastName'].setValue('');
      } else {
        this.f['NameOfEntity'].setValue('');
      }
    }
  }

  //#region getters
  get f() {
    return this.registerForm.controls;
  }
  get af() {
    return this.registerForm.controls['AgentDocumentDetails'] as FormArray;
  }
  //#endregion getters

  ngOnInit(): void {
    this._returnUrl =
      this._route.snapshot.queryParams['returnUrl'] || '/app/home';
      this._onFormChanges();
  }

  ngOnDestroy(): void {
    this._destroy$.next(null);
    this._destroy$.complete();
  }
  /**
   * #region public methods
   */
  public dismissError() {
    this.error.message = '';
  }

  public attachFile(index) {
    document.getElementById('file'+index).click();
  }

  public deleteAttachment(index) {
    if(this.af.value[index]){
      this.af.value[index].AttachmentDetail.Deleted = true;
      this.af.value[index].AttachmentDetail.FileName = "";
      (document.getElementById('file'+index) as HTMLInputElement).value = null;
      this.DocumentDataSource.data = this.af.controls;
    }
  }

  public downloadFile(index) {
    let filename = "";
    let filepath = "";
    if (this.af.value[index]) {
      filename = this.af.controls[index].get('AttachmentDetail').get('FileName').value;
      filepath = this.af.controls[index].get('AttachmentDetail').get('StorageFilePath').value;
    }
    let link = document.createElement('a');
    link.setAttribute('type', 'hidden');
    link.href = API_ENDPOINTS.Domain + '/' + filepath;
    link.download = filename;
    link.target = '_blank';
    link.click();
    link.remove();
  }

  public checkgst() {
    if(this.f['GSTNo'].value && this.f['GSTNo'].valid && this.f['GSTNo'].value != this.lastgst) {
      this._dataService.getDataById(this.f['GSTNo'].value, this.GSTNoAPI).subscribe((res) => {
        if(res.Success) {
          this.lastgst = this.f['GSTNo'].value;
          this.registerForm.patchValue({
            PANNo: res.Data.pan_number,
            LegalName: res.Data.legal_name,
            Address: res.Data.address,
            Pincode: res.Data.Pincode,
            CityId: res.Data.CityId,
            CityName: res.Data.CityName,
            StateId: res.Data.StateId,
            StateName: res.Data.StateName,
            CountryId: res.Data.CountryId,
            CountryName: res.Data.CountryName
          })
          this._alertsService.raiseSuccessAlert(res.Message);
          this.f['PANNo'].disable();
          // this.f['CompanyName'].disable();
          this.f['LegalName'].disable();
          this.f['Address'].disable();
          this.f['Pincode'].disable();
          this.f['CityName'].disable();
        }
        else{
          this.lastgst = '';
          this.f['PANNo'].setValue('');
          this.f['PANNo'].enable();
          // this.f['CompanyName'].setValue('');
          // this.f['CompanyName'].enable();
          this.f['LegalName'].setValue('');
          this.f['LegalName'].enable();
          this.f['Address'].enable();
          this.f['Address'].setValue('');
          this.f['Pincode'].enable();
          this.f['Pincode'].setValue('');
          this.f['CityName'].enable();
          this.f['CityName'].setValue('');
          this.f['StateName'].setValue('');
          this.f['CountryName'].setValue('');
          this.f['CityId'].setValue(null);
          this.f['StateId'].setValue(null);
          this.f['CountryId'].setValue(null);
          this._alertsService.raiseErrors(res.Alerts);
        }
      });
    }
    else if(this.f['PANNo'].disabled && this.f['GSTNo'].value != this.lastgst){
        this.lastgst = '';
        this.f['PANNo'].setValue('');
        this.f['PANNo'].enable();
        // this.f['CompanyName'].setValue('');
        // this.f['CompanyName'].enable();
        this.f['LegalName'].setValue('');
        this.f['LegalName'].enable();
        this.f['Address'].enable();
        this.f['Address'].setValue('');
        this.f['Pincode'].enable();
        this.f['Pincode'].setValue('');
        this.f['CityName'].enable();
        this.f['CityName'].setValue('');
        this.f['StateName'].setValue('');
        this.f['CountryName'].setValue('');
        this.f['CityId'].setValue(null);
        this.f['StateId'].setValue(null);
        this.f['CountryId'].setValue(null);
    }
  }

  public checkpan() {
    if(this.f['PANNo'].value && this.f['PANNo'].valid && this.f['PANNo'].value != this.lastpan) {
      this._dataService.getDataById(this.f['PANNo'].value, this.PANNoAPI).subscribe((res) => {
        if(res.Success) {
          this.lastpan = this.f['PANNo'].value;
          this.registerForm.patchValue({
            LegalName: res.Data.full_name,
          })
          this._alertsService.raiseSuccessAlert(res.Message);
          this.f['LegalName'].disable();
        }
        else{
          this.lastpan = '';
          this.f['LegalName'].setValue('');
          this.f['LegalName'].enable();
          this._alertsService.raiseErrors(res.Alerts);
        }
      });
    }
    else if(this.f['LegalName'].disabled && this.f['PANNo'].value != this.lastpan){
        this.lastpan = '';
        this.f['LegalName'].setValue('');
        this.f['LegalName'].enable();
    }
  }

  public checkcompany(element) {
    if(this.f['Type'].value == 'Individual') {
      return true;
    }
    else
    {
      if (this.f['Type'].value == 'Company' && ((element.get('DocumentType').value == 'Passport') || (element.get('DocumentType').value == 'License')))
      {
        return false;
      }
      else {
        return true;
      }
    }
  }

  public attachmentUpload(index, files) {
    if (files) {
      let file = files[0];
      if (file) {
        let reader = new FileReader();
        reader.onload = () => {};
        reader.readAsDataURL(file);
        this._dataService
          .UploadFile(this.UploadFileAPI, file)
          .subscribe((res) => {
            if (this.af.value[index]) {
              this.af.controls[index].get('AttachmentDetail').patchValue({
                FileName: res.Data.FileName,
                StorageFileName: res.Data.StorageFileName,
                StorageFilePath: res.Data.StorageFilePath,
                Deleted: false,
              });
              this.DocumentDataSource.data = this.af.controls;
            }
          });
      }
    }
  }

  redirectToHome() {
    this._router.navigate(['home']);
  }

  public register() {
    if (this.registerForm.invalid) {
      return;
    }

    let reqmissing = this.af.value.some(element => {
      return element.DocumentType.endsWith('*') && element.AttachmentDetail.Deleted
    });
    if (reqmissing) {
      this.alerts = [];
      this.alerts.push({
        Message: 'Attach Required Documents',
        CanDismiss: false,
        AutoClose: false,
      });
      this._alertsService.raiseErrors(this.alerts);
      return;
    }
    

    let len: number = this.af.controls.length;
    
    for (let i = len - 1; i >= 0; i--) {
      if (this.af.controls[i].get('DocumentType').value.endsWith('*')) {
        this.af.controls[i].get('DocumentType').patchValue(this.af.controls[i].get('DocumentType').value.slice(0,-2));
      }
    }

    for(let i = len-1; i >= 0; i--) {
      if(this.af.controls[i].get('AttachmentDetail').value.Deleted)
      {
        this.af.removeAt(i);
      }
    }

    // if(!this.f['CompanyTypeId'].value) {
    //   this.f['CompanyTypeId'].setValue('1');
    //   this.f['CompanyTypeName'].setValue('Custom House Agent');
    // }


    // this.f['CompanyName'].setValue(this.f['LegalName'].value);

    this._authservice
      .register(this.registerForm.getRawValue())
      .pipe(takeUntil(this._destroy$))
      .subscribe({
        next: (res: any) => {
          if (res.Success) {
            //Redirect
            this._alertsService.raiseSuccessAlert(
              'Thank you for registering with Aapka Transit. Your documents are received and same is under approval. We will get back to you soon.'
            );
            this._router.navigate(['/auth/login']);
          } else {
            // handle alerts here
            this._alertsService.raiseErrors(res.Alerts, true);
          }
        },
        error: (error) => {
          this._alertsService.raiseErrors(error);
        },
      });
  }

  public ekyc() {
    if (this.registerForm.invalid) {
      return;
    }
    this.submitted = true;
    // this.registerForm.clearValidators();
    // this.registerForm.removeControl('confirmPassword');
    // this.registerForm.removeControl('recaptchaReactive');
    this.f['CountryName'].setValidators(Validators.required);
    this.f['StateName'].setValidators(Validators.required);
    this.f['CityName'].setValidators(Validators.required);
    this.f['PANNo'].setValidators([Validators.required, Validators.pattern(/^([A-Z]){5}([0-9]){4}([A-Z]){1}$/)]);
    this.f['LegalName'].setValidators(Validators.required);
    this.f['Address'].setValidators(Validators.required);
    this.f['Pincode'].setValidators(Validators.required);
    this.f['TermsAndConditions'].setValidators(Validators.requiredTrue);
    if (this.f['Type'].value =='Company') {
      this.f['CompanyTypeName'].setValidators(Validators.required);
    }
  }

  public termsandconditions() {
    let name = ''
    if(this.f['Type'].value == 'Company') {
      name = this.f['NameOfEntity'].value;
    }
    else{
      name = this.f['FirstName'].value;
      if(this.f['MiddleName'].value && this.f['MiddleName'].value != '') {
        name += ' ' + this.f['MiddleName'].value;
      }
      name+= ' ' + this.f['LastName'].value;
    }
    let message = `Agreement for business engagement with Member and his/her/their usage of business services provided through
    online business portal of Finmore (hereinafter referred as 'Company'). This agreement shall be effective from ` + new Date().toLocaleDateString('en-IN') + '.';
    message += `<br/><br/> This Agreement, between the Member user and the Company sets forth the terms and conditions under which the
    Member shall use the following business services provided through online business portal of the Company for Member
    and / or for Member’s clients:<br/><br/>
    <li>CHA (Custom House Agent)</li>
    <li>Transportation & Distribution</li>
    <li>Marine Insurance</li><br/>
    The Company will compensate / reward the Member for sourcing our services for their clients. The compensation
shall be paid to the Member in the form of Advisory Charges / Value Added Services Charges / Consultation Charges /
Customer Handling Service Charges. <br/><br/>
Any tax liability arising out of payment of above-mentioned compensation to the Member shall be borne by the
Member.<br/><br/>
<b>1.1 <u>Term of this Agreement and its Termination</u>:</b><br/><br/>
a) This agreement shall become effective from the date as mentioned above in this agreement and shall remain in
force till expiry or cancellation of the agreement for any reason whatsoever.<br/><br/>
b) The parties can renew or enter into another agreement or may on or prior to the expiry of the term
aforementioned, mutually agree in writing to extend this agreement for a further period/s of such duration as agreed
by the parties subject to renewal of registration.<br/><br/>
c) Notwithstanding anything contained in this agreement to the contrary or notwithstanding any separate written
communication, either party may terminate this agreement at any time by providing one (1) month's prior notice in
writing to the other party during the validity of the agreement.<br/><br/>
<b>1.2 <u>Obligation of Member</u>:</b><br/><br/>
The Member hereby agrees, covenants and undertakes as follows:<br/><br/>
(a) Member will comply with all laws and regulations which relate to this agreement and shall indemnify and hold the
company harmless for its failure to do so.<br/><br/>
b) Member will comply with the company's rules and regulations relating to the soliciting the business. As a material
part of the consideration for the making of this agreement by the company, Member agrees that there will be made
no representations whatsoever with respect to the nature or scope of the benefits of services except through and by
means of the written material either prepared and furnished to MEMBER for that purpose by the company or
approved in writing by the company prior to its use.<br/><br/>
c) Member will conduct itself so as not to affect adversely the business, good standing, and reputation of the
company.<br/><br/>
d) Member shall act solely as an independent person/organization, subject to the guidance of the company. Nothing
herein contained shall be construed to create the relationship of employer and employee between Member and the
Company.<br/><br/>
e) Other Expenses. Member shall have no claim or shall not be entitled to reimbursement for any expenses.<br/><br/>
<b>1.3 <u>Privacy Policy</u>:</b><br/><br/>
a) Member confirms and undertakes that he will not violate privacy covenants and in case of any breach of privacy the
Member shall be solely responsible for losses arising out of the same.<br/><br/>
b) Member shall ensure that there are proper encryption and security measures to prevent any hacking into the
information/data pertaining to transactions contemplated under this agreement. Member shall agree to the
appropriate security norms including but not limited to the information technology (Reasonable Security Practices and
Procedures and Sensitive Personal Data or Information) rules, 2011 as amended from time to time.<br/><br/>
<b>1.4 <u>Confidentiality</u>:</b><br/><br/>
Both parties recognize, accept and agree that all tangible and intangible information obtained or disclosed to each
other and/or its personnel/representatives, including all details, documents, data, records, reports, systems, papers,
notices, statements, business information and practices and trade secrets (all of which are collectively referred to as
“Confidential Information”) shall be treated as confidential and both Parties agree and undertake that the same will be
kept secret and will not be disclosed, save as provided below, in whole or in part to any person/s and/or used and/or
be allowed to be used for any purpose other than as may be necessary for the due performance of obligations
hereunder, except with written authorization from other party.<br/><br/>
<b>1.5 <u>Indemnity</u>:</b><br/><br/>
Member agrees to indemnify and keep indemnified and hold harmless at all times its directors and officers from and
against any and all losses, claims, actions, proceedings, damages (including reasonable legal and lawyer’s fees) which
may be incurred by the company on account of (a) negligence or misconduct on the part of the Member (b) due to
breach any terms and conditions of this agreement (c) for breach of any intellectual property rights of the company, or
of any third party which commences an action or makes a claim against the company and such breach is attributable
to the acts of commission by Insurance company (d) any loss caused to the company due to breach of Confidentiality
by the Member.<br/><br/>
<b>1.6 <u>Arbitration</u>:</b><br/><br/>
a) The provisions of this agreement shall be governed by, and construed in accordance with Indian law.<br/><br/>
b) Any dispute, controversy or claims arising out of or relating to this agreement or the breach, termination or
invalidity thereof, shall be settled by arbitration in accordance with the provisions of the arbitration and conciliation
Act, 1996. Following provisions shall be adhered to for any such arbitral proceedings:
(i) The arbitral tribunal shall be composed of a sole arbitrator mutually appointed by the parties. In the event of non-
agreement each of the parties shall individually appoint an arbitrator and there two arbitrators shall thereafter jointly
appoint a third arbitrator which three arbitrators shall jointly conduct arbitration proceedings.<br/><br/>
(ii) The place of arbitration shall be Ahmedabad and any award whether interim or final, shall be made, and shall be
deemed for all purposes between the Parties to be made, in Ahmedabad.<br/><br/>
(iii) The arbitral procedure shall be conducted in the English Language and any award or awards shall be rendered in
English. The procedural law of the arbitration shall be Indian law.<br/><br/>
(iv) The rights and obligations of the parties under, or pursuant to, this clause, including the arbitration agreement in
this clause, shall be governed by and be subject to Indian law.<br/><br/>
This agreement constitutes the entire agreement between the parties with respect to its matter. In witness whereof,
intending to be legally bound hereby, the parties hereto have executed this Agreement.<br/><br/>
<b>For Finmore Investors Services Pvt. Ltd.</b><br/><br/>
<b>For `+name+`</b>`;
    this._dialogService.confirmDialog({
      title: 'Terms And Conditions for Service Agreement with Member',
      message : message,
      confirmText: 'Ok',
      cancelText: 'Ok',
      noclose: true,
    })
  }

  public back() {
    this.submitted = false;
    this.f['CountryName'].clearValidators();
    this.f['StateName'].clearValidators();
    this.f['CityName'].clearValidators();
    this.f['PANNo'].clearValidators();
    this.f['LegalName'].clearValidators();
    this.f['Address'].clearValidators();
    this.f['Pincode'].clearValidators();
    this.f['CompanyTypeName'].clearValidators();
    this.f['TermsAndConditions'].clearValidators();
    // this._initForm();
    // this.f['FirstName'].setValidators(Validators.required);
    // this.f['LastName'].setValidators(Validators.required);
    // this.f['Type'].setValidators(Validators.required);
    // this.f['EmailId'].setValidators(Validators.required);
    // this.f['MobileNo'].setValidators(Validators.required);
    // this.f['Password'].setValidators(Validators.required);
  }

  public clear(name: string, id: string): void {
    this.f[name].setValue("");
    this.f[id].setValue("");

    if (name == "CityName") {
      this.f["Pincode"].setValue("");
      this.f["StateName"].setValue("");
      this.f["StateId"].setValue(null);
      this.f["CountryId"].setValue(null);
      this.f["CountryName"].setValue("");
    }
  }

  public pincodeclear() {
    this.f["Pincode"].setValue("");
    this.f["CityId"].setValue(null);
    this.f["CityName"].setValue("");
    this.f["StateId"].setValue(null);
    this.f["StateName"].setValue("");
    this.f["CountryId"].setValue(null);
    this.f["CountryName"].setValue("");
  }


  public openDiolog(type: string, title: string) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.width = '80vw';
    // dialogconfig.minwidth = 'fit-content';
    // dialogconfig.minheight = 'fit-content';
    dialogConfig.maxHeight = '80vh';

    dialogConfig.data = {
      type: type,
      title: title,
      ispopup: true,
    };

    const dialogRef = this.dialog.open(GenPopupComponent, dialogConfig);

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        if (type == 'Pincode') {
          this.registerForm.patchValue({
            CityId: result.CityId,
            CityName: result.CityName,
            StateId: result.StateId,
            StateName: result.StateName,
            CountryName: result.CountryName,
            CountryId: result.CountryId,
            Pincode: result.PinCode
          })
        }

        if (type == 'City') {
          this.registerForm.patchValue({
            CityId: result.Id,
            CityName: result.Name,
            StateId: result.StateId,
            StateName: result.StateName,
            CountryName: result.CountryName,
            CountryId: result.CountryId
          });
        }

        if (type == 'Company') {
          this.registerForm.patchValue({
            CompanyTypeId: result.Id,
            CompanyTypeName: result.Companytype,
          });
        }
      }
    });
  }

  CitySelected(event: MatAutocompleteSelectedEvent): void {
    this.registerForm.patchValue({
      CityId:event.option.value.Id,
      CityName: event.option.value.Name,
      StateId: event.option.value.StateId,
      StateName: event.option.value.StateName,
      CountryId: event.option.value.CountryId,
      CountryName: event.option.value.CountryName,
    });
    // this.CityName.patchValue(event.option.value.Name);
  }

  CompanySelected(event: MatAutocompleteSelectedEvent): void {
    this.registerForm.patchValue({
      CompanyTypeId: event.option.value.Id,
      CompanyTypeName: event.option.value.Companytype,
    });
  }

  PinCodeSelected(event: MatAutocompleteSelectedEvent): void {
    this.registerForm.patchValue({
      CityId: event.option.value.CityId,
      StateId: event.option.value.StateId,
      CountryId: event.option.value.CountryId,
      CityName: event.option.value.CityName,
      StateName: event.option.value.StateName,
      CountryName: event.option.value.CountryName,
      Pincode: event.option.value.PinCode,
    });
  }
  
  private _onFormChanges() {

    this.registerForm.get('GSTNo').valueChanges.subscribe((val) => {
      if (val != null && val != "") {
        this.af.controls[0].get('DocumentType').patchValue('GSTIN *');
      }
      else {
        this.af.controls[0].get('DocumentType').patchValue('GSTIN');
      }
    })

    this.registerForm.get('CityName').valueChanges.subscribe((val) => {
      this.Cities$ = this._MasterListService.getFilteredCityList(val).pipe(
        takeUntil(this._destroy$),
        switchMap((res) => {
          if (res.Success) {
            if (res.Data.Items.length) {
              let result = Array.from(
                res.Data.Items.reduce(
                  (m, t) => m.set(t.Name, t),
                  new Map()
                ).values()
              );
              result = result.filter((el) => {
                if (el.Name) {
                  return el;
                }
              });
              return of(result);
            } else {
              return of([]);
            }
          } else {
            return of([]);
          }
        })
      );
    });

    this.registerForm.get('CompanyTypeName').valueChanges.subscribe((val) => {
      this.Companies$ = this._MasterListService.getFilteredCompanyTypeList(val).pipe(
        takeUntil(this._destroy$),
        switchMap((res) => {
          if (res.Success) {
            if (res.Data.Items.length) {
              let result = Array.from(
                res.Data.Items.reduce(
                  (m, t) => m.set(t.Companytype, t),
                  new Map()
                ).values()
              );
              result = result.filter((el) => {
                if (el.Companytype) {
                  return el;
                }
              });
              if (res.Data.Items.length == 1 && this.registerForm.get('CompanyTypeId').value!=res.Data.Items[0].Id) {
                this.registerForm.patchValue({
                  CompanyTypeId: res.Data.Items[0].Id,
                  CompanyTypeName: res.Data.Items[0].Companytype,
                });
              }
              return of(result);
            } else {
              return of([]);
            }
          } else {
            return of([]);
          }
        })
      );
    });

    this.registerForm.get('Pincode').valueChanges.subscribe((val) => {
      this.pincodes$ = this._MasterListService.getFilteredPincodeList(val).pipe(
        takeUntil(this._destroy$),
        switchMap((res) => {
          if (res.Success) {
            if (res.Data.Items.length) {
              let result = Array.from(
                res.Data.Items.reduce(
                  (m, t) => m.set(t.PinCode, t),
                  new Map()
                ).values()
              );
              result = result.filter((el) => {
                if (el.PinCode) {
                  return el;
                }
              });
              return of(result);
            } else {
              return of([]);
            }
          } else {
            return of([]);
          }
        })
      );
    });

  }

  copynumber() {
    if(this.f['WhatsAppNo'].value == '') {
      this.f['WhatsAppNo'].setValue(this.f['MobileNo'].value);
    }
  }


  // creates alerts for login form view
  alertCreater(msg: string) {
    let alert: IAppAlert = {
      message: msg,
      type: 'error',
      appearance: 'soft',
      canDismiss: false,
      showIcon: false,
    };
    return alert;
  }
  // #endregion public methods

  /**
   * #region private methods
   */
  private _initForm(): void {
    this.registerForm = this._fb.group(
      {
        Type: ['Company', [Validators.required]],
        FirstName: [''],
        MiddleName: [''],
        LastName: [''],
        NameOfEntity: [''],
        Password: ['', [Validators.required, Validators.minLength(1), Validators.maxLength(20)]],
        MobileNo: ['', [Validators.required, Validators.minLength(1), Validators.maxLength(10)]],
        WhatsAppNo: ['', [Validators.required, Validators.minLength(1), Validators.maxLength(10)]],
        EmailId: ['', [Validators.required,Validators.email, Validators.minLength(1), Validators.maxLength(128)]],
        recaptchaReactive: ['', [Validators.required]],
        confirmPassword: ['', [Validators.required, Validators.minLength(1), Validators.maxLength(20)]],
        CountryId: [],
        CountryName: [''],
        StateId: [],
        StateName: [''],
        CityId: [],
        CityName: [''],
        PANNo: [''],
        GSTNo: ['', [Validators.pattern('[0-9]{2}[A-Z]{3}[ABCFGHLJPTF]{1}[A-Z]{1}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}')]],
        CompanyName: [''],
        CompanyTypeId: [''],
        CompanyTypeName: [''],
        LegalName: [''],
        Address: [''],
        Pincode: [''],
        TermsAndConditions: [false],
        AgentDocumentDetails: this._buildAttachmentDetailsForm(),
      },
      { validators: [this.passwordMatchValidator, this.nameValidator] }
    );
    this.registerForm.get('StateName').disable();
    this.registerForm.get('CountryName').disable();
  }

  private _buildAttachmentDetailsForm(): FormArray {
    let formArray: FormArray = new FormArray([]);
    let items: IAttachmentDetailsDto[] = [];
    let attachmentDetailDto: AttachmentDetailsDto = new AttachmentDetailsDto();
    attachmentDetailDto.DocumentType = 'GSTIN';
    items.push(attachmentDetailDto);
    formArray.push(this._buildAgentDocumentDetailsForm(attachmentDetailDto));

    attachmentDetailDto = new AttachmentDetailsDto();
    attachmentDetailDto.DocumentType = 'PAN *';
    items.push(attachmentDetailDto);
    formArray.push(this._buildAgentDocumentDetailsForm(attachmentDetailDto));

    attachmentDetailDto = new AttachmentDetailsDto();
    attachmentDetailDto.DocumentType = 'Address Proof *';
    items.push(attachmentDetailDto);
    formArray.push(this._buildAgentDocumentDetailsForm(attachmentDetailDto));

    attachmentDetailDto = new AttachmentDetailsDto();
    attachmentDetailDto.DocumentType = 'License';
    items.push(attachmentDetailDto);
    formArray.push(this._buildAgentDocumentDetailsForm(attachmentDetailDto));

    attachmentDetailDto = new AttachmentDetailsDto();
    attachmentDetailDto.DocumentType = 'Passport';
    items.push(attachmentDetailDto);
    formArray.push(this._buildAgentDocumentDetailsForm(attachmentDetailDto));

    return formArray;
  }

  private _buildAgentDocumentDetailsForm(
    data: IAttachmentDetailsDto
  ): FormGroup {
    let rowForm = this._fb.group({
      Id: [0],
      AgentId: [0],
      DocumentType: [data.DocumentType],
      AttachmentDetail: this._initPolicyMemberDetailsForm(),
      Status: [''],
      StatusName: [''],
    });
    return rowForm;
  }

  private _initPolicyMemberDetailsForm(): FormGroup {
    let item: IAttachmentDetailDto;
    item = new AttachmentDetailDto();
    let Attachment = this._fb.group({
      Id: [0],
      AttachmentId: [0],
      FileName: [''],
      StorageFileName: [''],
      StorageFilePath: [''],
      Description: [''],
      Deleted: [true],
    });

    if (item != null) {
      if (!item) {
        item = new AttachmentDetailDto();
      }

      if (item) {
        Attachment.patchValue(item);
      }
    }
    return Attachment;
  }

  passwordMatchValidator(frm: FormGroup) {
    return frm.controls['Password']?.value ===
      frm.controls['confirmPassword']?.value
      ? null
      : { mismatch: true };
  }
  nameValidator(frm: FormGroup) {
    return (frm.controls['Type'].value === 'Individual' &&
      frm.controls['FirstName'].value &&
      frm.controls['LastName'].value) ||
      (frm.controls['Type'].value === 'Company' &&
        frm.controls['NameOfEntity'].value)
      ? null
      : { required: true };
  }
}
