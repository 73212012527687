export interface IAgentDto {
    Id: number;
    Type: string;
    AgentCode: string;
    ApplicationID: string;
    AgentName: string;
    CurrentBalance: number;
    FirstName: string;
    MiddleName: string;
    LastName: string;
    NameOfEntity: string;
    MobileNo: string;
    EmailId: string;
    Password: string;
    Status: number;
    StatusName: string;
    GSTNo: string;
    PANNo: string;
    CompanyTypeId:number;
    CompanyTypeName: string;
    CompanyName: string;
    LegalName: string;
    Address: string;
    PinCode: string;
    CityName: string;
    CityId: number;
    StateName: string;
    StateId: number;
    CountryName: string;
    CountryId: number;
    StatusYN: string;
    AgentDocumentDetails: IAttachmentDetailsDto[];
    BranchId: number;
    BranchName: string;
    SalesPersonId: number;
    SalesPersonName: string;
    TeamLeaderId: number;
    TeamLeaderName: string;
    SupportExecutiveId: number;
    SupportExecutiveName: string;
    WhatsAppNo: string;

}
export class AgentDto implements IAgentDto {
    Id: number;
    Type: string;
    AgentCode: string;
    ApplicationID: string;
    CurrentBalance: number;
    AgentName: string;
    FirstName: string;
    MiddleName: string;
    LastName: string;
    NameOfEntity: string;
    MobileNo: string;
    EmailId: string;
    Password: string;
    Status: number;
    StatusName: string;
    GSTNo: string;
    PANNo: string;
    CompanyTypeId:number;
    CompanyTypeName: string;
    CompanyName: string;
    LegalName: string;
    Address: string;
    PinCode: string;
    CityName: string;
    CityId: number;
    StateName: string;
    StateId: number;
    CountryName: string;
    CountryId: number;
    StatusYN: string;
    AgentDocumentDetails: IAttachmentDetailsDto[];
    BranchId: number;
    BranchName: string;
    SalesPersonId: number;
    SalesPersonName: string;
    TeamLeaderId: number;
    TeamLeaderName: string;
    SupportExecutiveId: number;
    SupportExecutiveName: string;
    WhatsAppNo: string;
}

export interface IAttachmentDetailsDto {
    Id: number;
    AgentId: number;
    DocumentType: string;
    AttachmentDetail: IAttachmentDetailDto;
    Status: string;
    StatusName: string;
}

export class AttachmentDetailsDto implements IAttachmentDetailsDto{
    Id: number;
    AgentId: number;
    DocumentType: string;
    AttachmentDetail: IAttachmentDetailDto;
    Status: string;
    StatusName: string;
}

export interface IAttachmentDetailDto {
    Id: number;
    AttachmentId: number;
    FileName: string;
    StorageFileName: string;
    StorageFilePath: string;
    Description: string;
    Deleted: boolean;
}


export class AttachmentDetailDto implements IAttachmentDetailDto {
  Id: number;
  AttachmentId: number;
  FileName: string;
  StorageFileName: string;
  StorageFilePath: string;
  Description: string;
  Deleted: boolean;
}
