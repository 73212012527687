<div class="flex justify-between items-center">
    <h2 mat-dialog-title>{{data?.title}}</h2>
    <button *ngIf="!(data?.noclose)" mat-icon-button [mat-dialog-close]="false">
        <mat-icon>close</mat-icon>
    </button>
</div>

<div mat-dialog-content>
    <p [innerHTML]="data?.message"></p>
</div>

<div mat-dialog-actions [align]="'end'">
    <button mat-raised-button *ngIf="!(data?.noclose)" [mat-dialog-close]="false">{{data?.cancelText}}</button>
    <button mat-raised-button color="primary" [mat-dialog-close]="true">{{data?.confirmText}}</button>
</div>