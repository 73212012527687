<div class="p-4 h-full w-full">
    <!-- region main heading -->
    <h1 class="new-head text-xl px-4 py-2 rounded flex justify-between items-center font-semibold">
        {{ title }}

        <!-- region main heading controls-->
        <div class="flex gap-x-3  items-center text-lg font-normal">
            <button mat-raised-button class="new-button" [disabled]="NotificationRemarksForm.invalid" (click)="submitform()">Save</button>
            <button mat-raised-button class="new-button" (click)="backClicked()" (click)="backClicked()">Cancel</button>
        </div>
        <!-- endregion main heading controls-->
    </h1>
    <!-- endregion main heading -->

    <!-- region form -->
    <div class="px-4 py-7 rounded bg-white">
        <form [formGroup]="NotificationRemarksForm" class="sm:w-120 text-md">
            <div class="grid grid-cols-2 gap-4">
                <div class="relative">
                    <div class="icon">
                        <mat-icon>chat</mat-icon>
                    </div>
                    <input type="text" id="Remarks" class="peer" formControlName="Remarks" placeholder=" " />
                    <label for="Remarks" class="mandatory">Remarks</label>
                </div>

                <div class="relative">
                    <div class="icon">
                        <mat-icon>check_circle</mat-icon>
                    </div>
                    <select formControlName="PolicyProgress" name="PolicyProgress" id="PolicyProgress">
                        <option value="Under Review">Under Review</option>
                        <option value="Settled">Settled</option>
                        <option value="Rejected">Rejected</option>
                      </select>
                    <label for="PolicyProgress" class="mandatory">Policy Progress</label>
                </div>

                    <div class="relative" *ngIf="this.NotificationRemarksForm.get('PolicyProgress').value == 'Settled'">
                        <div class="icon">
                            <mat-icon>money</mat-icon>
                        </div>
                        <input type="text" id="FinalBillAmount" class="peer" formControlName="FinalBillAmount" placeholder=" " />
                        <label for="FinalBillAmount">Final Bill Amount</label>
                    </div>

                    <div class="relative" *ngIf="this.NotificationRemarksForm.get('PolicyProgress').value == 'Settled'">
                        <div class="icon">
                            <mat-icon>money</mat-icon>
                        </div>
                        <input type="text" id="Remarks" class="peer" formControlName="SettlementAmount" placeholder=" " />
                        <label for="SettlementAmount">Settlement Amount</label>
                    </div>

                    <div class="relative" *ngIf="this.NotificationRemarksForm.get('PolicyProgress').value == 'Settled' || this.NotificationRemarksForm.get('PolicyProgress').value == 'Rejected'">
                        <div class="icon">
                          <mat-datepicker-toggle class="-ml-2" matIconSuffix [for]="icker"></mat-datepicker-toggle>
                          <mat-datepicker #icker></mat-datepicker>
                        </div>
                        <div class="flex">
                          <input matInput type="text" id="SettlementDate" formControlName="SettlementDate" placeholder="__/__/____"
                            datemask [matDatepicker]="icker" class="peer mb-1"/>
                          <label for="SettlementDate" class="mandatory"> Settlement Date</label>
                        </div>
                      </div>
            </div>
        </form>
    </div>
    <!-- endregion form -->
</div>
