import { FormControl } from "@angular/forms";

export const popUpColumns = {
    Pincode: [
        {
            head: 'Select',
            isSortDisable: true,
            fieldName: 'Id',
            isSearch: 'false',
            searchType: 'radio',
            searchFieldName: '',
        },
        {
            head: 'Pincode',
            fieldName: 'PinCode',
            isSearch: 'true',
            searchType: 'text',
            searchFieldName: 'PinCode',
        },
        {
            head: 'City',
            fieldName: 'CityName',
            isSearch: 'true',
            searchType: 'text',
            searchFieldName: 'City.Name',
        }

    ],
    Policy: [
        {
            head: 'Select',
            isSortDisable: true,
            fieldName: 'Id',
            isSearch: 'false',
            searchType: 'radio',
            searchFieldName: '',
        },
        {
            head: 'Policy Token No.',
            fieldName: 'PolicyTokenNo',
            isSearch: 'true',
            searchType: 'text',
            searchFieldName: 'PolicyTokenNo',
        },
        {
            head: 'Agent Name',
            fieldName: 'AgentName',
            isSearch: 'true',
            searchType: 'text',
            searchFieldName: 'Agent.FullName',
            sortFieldName: 'Agent.FullName',
          },
          {
            head: 'Creation Date',
            fieldName: 'CreatedDate',
            isSearch: 'true',
            searchType: 'none',
            searchFieldName: 'CreatedDate',
            sortFieldName: 'CreatedDate',
          },
        {
            head: 'Invoice No.',
            fieldName: 'InvoiceCumReceiptNo',
            isSearch: 'true',
            searchType: 'text',
            searchFieldName: 'InvoiceCumReceiptNo',
        },
        {
            head: 'Name',
            fieldName: 'InsuredName',
            isSearch: 'true',
            searchType: 'text',
            searchFieldName: 'InsuredName',
        },
        {
            head: 'Certificate No.',
            fieldName: 'CertificateNo',
            isSearch: 'true',
            searchType: 'text',
            searchFieldName: 'CertificateNo',
        },
    ],

    PIN: [
        {
            head: 'Select',
            isSortDisable: true,
            fieldName: 'Id',
            isSearch: 'false',
            searchType: 'radio',
            searchFieldName: '',
        },
        {
            head: 'Pincode',
            fieldName: 'PinCode',
            isSearch: 'true',
            searchType: 'text',
            searchFieldName: 'PinCode',
        },
        {
            head: 'Area',
            fieldName: 'Area',
            isSearch: 'true',
            searchType: 'text',
            searchFieldName: 'Area',
        },

    ],

    Role: [
        {
            head: 'Select Multiple',
            fieldName: 'Id',
            isSearch: 'false',
            searchType: 'radio',
            searchFieldName: '',
            isSortDisable: true,
        },
        {
            head: 'Role',
            fieldName: 'Name',
            isSearch: 'false',
            searchType: 'checkbox',
            searchFieldName: 'Name',
        },
    ],

    Branches: [
        {
            head: 'Select Multiple',
            fieldName: 'Id',
            isSearch: 'false',
            searchType: 'radio',
            searchFieldName: '',
            isSortDisable: true,
        },
        {
            head: 'Branch',
            fieldName: 'Name',
            isSearch: 'false',
            searchType: 'checkbox',
            searchFieldName: 'Name',
        },
    ],

    SalesPerson: [
        {
            head: 'Select',
            isSortDisable: true,
            fieldName: 'Id',
            isSearch: 'false',
            searchType: 'radio',
            searchFieldName: '',
        },
        {
            head: 'Name',
            fieldName: 'Name',
            isSearch: 'true',
            searchType: 'text',
            searchFieldName: 'Name',
        },
    ],

    SupportExecutive: [
        {
            head: 'Select',
            isSortDisable: true,
            fieldName: 'Id',
            isSearch: 'false',
            searchType: 'radio',
            searchFieldName: '',
        },
        {
            head: 'Name',
            fieldName: 'Name',
            isSearch: 'true',
            searchType: 'text',
            searchFieldName: 'Name',
        },
    ],

    TeamLeader: [
        {
            head: 'Select',
            isSortDisable: true,
            fieldName: 'Id',
            isSearch: 'false',
            searchType: 'radio',
            searchFieldName: '',
        },
        {
            head: 'Name',
            fieldName: 'Name',
            isSearch: 'true',
            searchType: 'text',
            searchFieldName: 'Name',
        },
        {
            head: 'Report To',
            fieldName: 'ReportingToUserName',
            isSearch: 'true',
            searchType: 'text',
            searchFieldName: 'ReportToUser.Name',
        },
        {
            head: 'Branch',
            fieldName: 'BranchName',
            isSearch: 'true',
            searchType: 'text',
            searchFieldName: 'BranchName',
            sortFieldName: 'BranchName',
            isAdditional: true,
            isSortDisable: true,
        },
    ],

    Email: [
        {
            head: 'Select Multiple',
            fieldName: 'Id',
            isSearch: 'false',
            searchType: 'radio',
            searchFieldName: '',
            isSortDisable: true,
        },
        {
            head: 'Email Notification',
            fieldName: 'Process',
            isSearch: 'false',
            searchType: 'checkbox',
            searchFieldName: 'Process',
        },
    ],

    WhatsApp: [
        {
            head: 'Select Multiple',
            fieldName: 'Id',
            isSearch: 'false',
            searchType: 'radio',
            searchFieldName: '',
            isSortDisable: true,
        },
        {
            head: 'WhatsApp Notification',
            fieldName: 'Process',
            isSearch: 'false',
            searchType: 'checkbox',
            searchFieldName: 'Process',
        },
    ],


    User: [
        {
            head: 'Select',
            isSortDisable: true,
            fieldName: 'Id',
            isSearch: 'false',
            searchType: 'radio',
            searchFieldName: '',
        },
        {
            head: 'FullName',
            fieldName: 'FullName',
            isSearch: 'true',
            searchType: 'text',
            searchFieldName: 'FullName',
        },


    ],

    Country: [
        {
            head: 'Select',
            isSortDisable: true,
            fieldName: 'Id',
            isSearch: 'false',
            searchType: 'radio',
            searchFieldName: '',
        },
        {
            head: 'Name',
            fieldName: 'Name',
            isSearch: 'true',
            searchType: 'text',
            searchFieldName: 'Name',
        },
    ],

    State: [
        {
            head: 'Select',
            isSortDisable: true,
            fieldName: 'Id',
            isSearch: 'false',
            searchType: 'radio',
            searchFieldName: '',
        },
        {
            head: 'Name',
            fieldName: 'Name',
            isSearch: 'true',
            searchType: 'text',
            searchFieldName: 'Name',
        },
    ],

    City: [
        {
            head: 'Select',
            isSortDisable: true,
            fieldName: 'Id',
            isSearch: 'false',
            searchType: 'radio',
            searchFieldName: '',
        },
        {
            head: 'Name',
            fieldName: 'Name',
            isSearch: 'true',
            searchType: 'text',
            searchFieldName: 'Name',
        },
    ],

    Department: [
        {
            head: 'Select',
            isSortDisable: true,
            fieldName: 'Id',
            isSearch: 'false',
            searchType: 'radio',
            searchFieldName: '',
        },
        {
            head: 'Name',
            fieldName: 'Name',
            isSearch: 'true',
            searchType: 'text',
            searchFieldName: 'Name',
        },
    ],

    Designation: [
        {
            head: 'Select',
            isSortDisable: true,
            fieldName: 'Id',
            isSearch: 'false',
            searchType: 'radio',
            searchFieldName: '',
        },
        {
            head: 'Name',
            fieldName: 'Name',
            isSearch: 'true',
            searchType: 'text',
            searchFieldName: 'Name',
        },
    ],
    Source: [
        {
            head: 'Select',
            isSortDisable: true,
            fieldName: 'Id',
            isSearch: 'false',
            searchType: 'radio',
            searchFieldName: '',
        },
        {
            head: 'Name',
            fieldName: 'Name',
            isSearch: 'true',
            searchType: 'text',
            searchFieldName: 'Name',
        },
    ],
    SubSource: [
        {
            head: 'Select',
            isSortDisable: true,
            fieldName: 'Id',
            isSearch: 'false',
            searchType: 'radio',
            searchFieldName: '',
        },
        {
            head: 'Name',
            fieldName: 'Name',
            isSearch: 'true',
            searchType: 'text',
            searchFieldName: 'Name',
        },
    ],
    Bank: [
        {
            head: 'Select',
            isSortDisable: true,
            fieldName: 'Id',
            isSearch: 'false',
            searchType: 'radio',
            searchFieldName: '',
        },
        {
            head: 'Name',
            fieldName: 'Name',
            isSearch: 'true',
            searchType: 'text',
            searchFieldName: 'Name',
        },
    ],
    Branch: [
        {
            head: 'Select',
            isSortDisable: true,
            fieldName: 'Id',
            isSearch: 'false',
            searchType: 'radio',
            searchFieldName: '',
        },
        {
            head: 'Name',
            fieldName: 'Name',
            isSearch: 'true',
            searchType: 'text',
            searchFieldName: 'Name',
        },
    ],

    RecruitingPerson: [
        {
            head: 'Select',
            isSortDisable: true,
            fieldName: 'Id',
            isSearch: 'false',
            searchType: 'radio',
            searchFieldName: '',
        },
        {
            head: 'Name',
            fieldName: 'FullName',
            isSearch: 'true',
            searchType: 'text',
            searchFieldName: 'FullName',
        },
    ],
    ReferenceName: [
        {
            head: 'Select',
            isSortDisable: true,
            fieldName: 'Id',
            isSearch: 'false',
            searchType: 'radio',
            searchFieldName: '',
        },
        {
            head: 'Name',
            fieldName: 'FullName',
            isSearch: 'true',
            searchType: 'text',
            searchFieldName: 'FullName',
        },
    ],
    CREName: [
        {
            head: 'Select',
            isSortDisable: true,
            fieldName: 'Id',
            isSearch: 'false',
            searchType: 'radio',
            searchFieldName: '',
        },
        {
            head: 'Name',
            fieldName: 'FullName',
            isSearch: 'true',
            searchType: 'text',
            searchFieldName: 'FullName',
        },
    ],
    CRMName: [
        {
            head: 'Select',
            isSortDisable: true,
            fieldName: 'Id',
            isSearch: 'false',
            searchType: 'radio',
            searchFieldName: '',
        },
        {
            head: 'Name',
            fieldName: 'FullName',
            isSearch: 'true',
            searchType: 'text',
            searchFieldName: 'FullName',
        },
    ],
    VerticalHeadName: [
        {
            head: 'Select',
            isSortDisable: true,
            fieldName: 'Id',
            isSearch: 'false',
            searchType: 'radio',
            searchFieldName: '',
        },
        {
            head: 'Name',
            fieldName: 'FullName',
            isSearch: 'true',
            searchType: 'text',
            searchFieldName: 'FullName',
        },
    ],
    Commodity: [
        {
            head: 'Select',
            isSortDisable: true,
            fieldName: 'Id',
            isSearch: 'false',
            searchType: 'radio',
            searchFieldName: '',
        },
        {
            head: 'Commodity Code',
            fieldName: 'CommodityCode',
            isSearch: 'true',
            searchType: 'text',
            searchFieldName: 'CommodityCode',
        },
        {
            head: 'Commodity Name',
            fieldName: 'CommodityName',
            isSearch: 'true',
            searchType: 'text',
            searchFieldName: 'CommodityName',
        },
    ],

    TermsOfSale: [
        {
            head: 'Select',
            isSortDisable: true,
            fieldName: 'Id',
            isSearch: 'false',
            searchType: 'radio',
            searchFieldName: '',
        },
        {
            head: 'Terms Of Sale',
            fieldName: 'Termsofsale',
            isSearch: 'true',
            searchType: 'text',
            searchFieldName: 'Termsofsale',
        },
    ],

    TransitMode: [
        {
            head: 'Select',
            isSortDisable: true,
            fieldName: 'Id',
            isSearch: 'false',
            searchType: 'radio',
            searchFieldName: '',
        },
        {
            head: 'Transit Mode',
            fieldName: 'Transitmode',
            isSearch: 'true',
            searchType: 'text',
            searchFieldName: 'Transitmode',
        },
    ],

    Packaging: [
        {
            head: 'Select',
            isSortDisable: true,
            fieldName: 'Id',
            isSearch: 'false',
            searchType: 'radio',
            searchFieldName: '',
        },
        {
            head: 'Packaging',
            fieldName: 'PackagingName',
            isSearch: 'true',
            searchType: 'text',
            searchFieldName: 'PackagingName',
        },
    ],

    BasisOfValuation: [
        {
            head: 'Select',
            isSortDisable: true,
            fieldName: 'Id',
            isSearch: 'false',
            searchType: 'radio',
            searchFieldName: '',
        },
        {
            head: 'Basis Of Valuation',
            fieldName: 'BasisOfValuationName',
            isSearch: 'true',
            searchType: 'text',
            searchFieldName: 'BasisOfValuationName',
        },
    ],

    Coverage: [
        {
            head: 'Select',
            isSortDisable: true,
            fieldName: 'CoverageTypeId',
            isSearch: 'false',
            searchType: 'radio',
            searchFieldName: '',
        },
        {
            head: 'Coverage',
            fieldName: 'CoverageTypeName',
            isSearch: 'false',
            searchType: 'none',
            isAdditional: true,
            searchFieldName: 'CoverageTypeName',
            sortFieldName: 'CoverageTypeName'
        },
    ],

    SettlingAgent: [
        {
            head: 'Select',
            isSortDisable: true,
            fieldName: 'Id',
            isSearch: 'false',
            searchType: 'radio',
            searchFieldName: '',
        },
        {
            head: 'Settling Agent',
            fieldName: 'Name',
            isSearch: 'true',
            searchType: 'text',
            searchFieldName: 'SettlingAgent.Name',
        },
        {
            head: 'Details',
            fieldName: 'Address',
            isSearch: 'false',
            searchType: 'none',
            searchFieldName: 'Address',
            sortFieldName: 'Address'
        },
        // {
        //     head: 'State',
        //     fieldName: 'StateName',
        //     isSearch: 'true',
        //     searchType: 'text',
        //     searchFieldName: 'City.State.Name',
        // },
        // {
        //     head: 'Country',
        //     fieldName: 'CountryName',
        //     isSearch: 'true',
        //     searchType: 'text',
        //     searchFieldName: 'City.State.Country.Name',
        // },
    ],

    Currency: [
        {
            head: 'Select',
            isSortDisable: true,
            fieldName: 'Id',
            isSearch: 'false',
            searchType: 'radio',
            searchFieldName: '',
        },
        {
            head: 'Symbol',
            fieldName: 'Symbol',
            isSearch: 'true',
            searchType: 'text',
            searchFieldName: 'Symbol',
        },
        {
            head: 'Currency Name',
            fieldName: 'FormalName',
            isSearch: 'true',
            searchType: 'text',
            searchFieldName: 'FormalName',
        },
    ],


    Company: [
        {
            head: 'Select',
            isSortDisable: true,
            fieldName: 'Id',
            isSearch: 'false',
            searchType: 'radio',
            searchFieldName: '',
        },
        {
            head: 'Company Type',
            fieldName: 'Companytype',
            isSearch: 'true',
            searchType: 'text',
            searchFieldName: 'Companytype',
        },
    ],

    Product: [
        {
            head: 'Select',
            isSortDisable: true,
            fieldName: 'Id',
            isSearch: 'false',
            searchType: 'radio',
            searchFieldName: '',
        },
        {
            head: 'Product Code',
            fieldName: 'ProductCode',
            isSearch: 'true',
            searchType: 'text',
            searchFieldName: 'ProductCode'
        },
        {
            head: 'Product Name',
            fieldName: 'ProductName',
            isSearch: 'true',
            searchType: 'text',
            searchFieldName: 'ProductName',
        },
    ],

    Agent: [
        {
            head: 'Select',
            isSortDisable: true,
            fieldName: 'Id',
            isSearch: 'false',
            searchType: 'radio',
            searchFieldName: '',
        },
        {
            head: 'Agent Code',
            fieldName: 'AgentCode',
            isSearch: 'true',
            searchType: 'text',
            sortFieldName: 'AgentCode',
            searchFieldName: 'AgentCode',
        },
        {
            head: 'Name',
            fieldName: 'AgentName',
            isSearch: 'true',
            searchType: 'text',
            isAdditional: true,
            sortFieldName: 'FirstName',
            searchFieldName: 'AgentName',
        },
        {
            head: 'Mobile',
            fieldName: 'MobileNo',
            isSearch: 'true',
            searchType: 'text',
            searchFieldName: 'MobileNo',
        },
        {
            head: 'Email',
            fieldName: 'EmailId',
            isSearch: 'true',
            searchType: 'text',
            searchFieldName: 'EmailId',
        },
        {
            head: 'Team Leader',
            fieldName: 'TeamLeaderName',
            isSearch: 'true',
            searchType: 'text',
            searchFieldName: 'TeamLeader.Name',
        },
        {
            head: 'Sales Person',
            fieldName: 'SalesPersonName',
            isSearch: 'true',
            searchType: 'text',
            searchFieldName: 'SalesPerson.Name',
        },
        {
            head: 'Branch',
            fieldName: 'BranchName',
            isSearch: 'true',
            searchType: 'text',
            searchFieldName: 'Branch.Name',
        },
    ],
    TransitType: [
        {
            head: 'Select',
            isSortDisable: true,
            fieldName: 'Id',
            isSearch: 'false',
            searchType: 'radio',
            searchFieldName: '',
        },
        {
            head: 'Transit Type',
            fieldName: 'Transittype',
            isSearch: 'true',
            searchType: 'text',
            searchFieldName: 'Transittype',
        },
    ],
    Size: [
        {
            head: 'Select',
            isSortDisable: true,
            fieldName: 'Id',
            isSearch: 'false',
            searchType: 'radio',
            searchFieldName: '',
        },
        {
            head: 'Container Size',
            fieldName: 'ContainerSize',
            isSearch: 'true',
            searchType: 'text',
            searchFieldName: 'ContainerSize',
        },
    ]

};
